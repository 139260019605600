import React from "react";
import { Drawer, Spin, Timeline, Button } from "antd";
import { SyncOutlined } from '@ant-design/icons';
import IconFont from '@components/Common/IconFont';
import request from "@utils/request";
import withBreakpoint from '@components/Common/withBreakpoint';
import TrackStatus from '@components/Order/TrackStatus/TrackStatus';
import './TrackDetail.less';

class TrackDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            trackResult: null
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.trackMethod === 'express' && prevProps.record?.expressId !== this.props.record?.expressId) {
            this.setState({ trackResult: null });
        }
        if (this.props.trackMethod === 'track' && prevProps.record?.trackingNumber !== this.props.record?.trackingNumber) {
            this.setState({ trackResult: null });
        }
    }

    handleOpen = () => {
        this.setState({ visible: true });
        if (!this.state.trackResult) {
            this.handleRefresh();
        }
    }

    handleClose = () => {
        this.setState({ visible: false });
    }

    handleRefresh = () => {
        const _this = this;
        if (this.controller) {
            this.controller.abort();
        }
        const controller = new AbortController();
        const { signal } = controller;
        this.controller = controller;
        _this.setState({ loading: true, trackResult: null });
        if (this.props.trackMethod === 'express') {
            request.get('/Track/TrackingSection', {
                signal,
                params: {
                    expressType: this.props.record.expressType,
                    expressId: this.props.record.expressId
                }
            }).then(function (response) {
                if (response) {
                    _this.setState({ loading: false, trackResult: response });
                }
            });
        } else {
            request.get('/Track/TrackingCarrierSection', {
                signal,
                params: {
                    carrier: this.props.record.carrierEName,
                    trackingNumber: this.props.record.trackingNumber
                }
            }).then(function (response) {
                if (response) {
                    _this.setState({ loading: false, trackResult: response });
                }
            });
        }
    }

    render() {
        const { record, trackMethod } = this.props;
        const isTrack = trackMethod === 'track';
        return (<>
            <div style={{ display: 'flex' }}>
                <span style={{ flex: 'none' }}>{this.props.label}</span>
                <span style={{ flex: 'auto', wordBreak: 'break-all' }}>
                    {isTrack && record.canTrackCarrier && <a href='#!' onClick={this.handleOpen}>{record.trackingNumber}</a>}
                    {isTrack && !record.canTrackCarrier && <span>{record.trackingNumber}</span>}
                    {!isTrack && record.canTrackExpress && <a href='#!' onClick={this.handleOpen}>{record.expressId}</a>}
                    {!isTrack && !record.canTrackExpress && <span>{record.expressId}</span>}
                </span>
            </div>
            <Drawer width={this.props.breakpoint.sm === false ? 300 : 450} className="track-detail-drawer" title={<div>{!isTrack ? record?.expressId : record?.trackingNumber}<Button type="link" onClick={this.handleRefresh} icon={<SyncOutlined />}></Button></div>} placement="right" visible={this.state.visible} onClose={this.handleClose}>
                <Spin spinning={this.state.loading}>
                    {this.state.trackResult?.success &&
                        <>
                            <div className="top-container">
                                <TrackStatus className="track-status" height={30} width={30} iconSize={14} status={this.state.trackResult?.result?.trackStatus} />
                                <span>{this.state.trackResult?.result?.trackStatusString}</span>
                            </div>
                            <Timeline>
                                {
                                    this.state.trackResult?.result?.details?.map((detail, index) =>
                                        <Timeline.Item className="tracking-timeline-item" key={index} dot={<IconFont type={index === 0 ? "icon-rhombus-soild" : "icon-rhombus"} className="timeline-icon" />} >
                                            <div>{detail.processLocation ? `${detail.activity}, ${detail.processLocation}` : detail.activity}</div>
                                            <div>{detail.eventTime}</div>
                                        </Timeline.Item>)
                                }
                            </Timeline>
                        </>
                    }
                    {this.state.trackResult?.success === false &&
                        <div>{this.state.trackResult?.errorMsg}</div>
                    }
                </Spin>
            </Drawer>
        </>);
    }
}

export default withBreakpoint(TrackDetail);