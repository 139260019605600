import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import PageLoading from '@components/Common/PageLoading/PageLoading';
import commonConfig from '@common/commonConfig';
import request from "@utils/request";
import { Card, Table, Input, Tooltip, Row, Col } from "antd";
import NewVendorProduct from "@components/Product/NewVendorProduct/NewVendorProduct";
import EditVendorProduct from "@components/Product/EditVendorProduct/EditVendorProduct";
import './vendor-product.less';

const { Search } = Input;

class ProductVendorProduct extends React.Component {
    state = {
        loading: false,
        pageNumber: 1,
        pageSize: commonConfig.pageSize,
        dataSource: null,
        totalCount: 0
    }
    search = React.createRef();

    columns = [
        {
            title: '产品名称',
            key: 'productName',
            dataIndex: 'productName',
            width: 20
        },
        {
            title: '产品描述',
            key: 'description',
            dataIndex: 'description',
            width: 40
        },
        {
            title: '创建时间',
            key: 'createTimeString',
            dataIndex: 'createTimeString',
            width: 20
        },
        {
            title: '操作',
            key: 'action',
            render: (record) => (
                <EditVendorProduct record={record} afterUpdate={(newPdt) => {
                    const newSource = [...this.state.dataSource];
                    const idx = newSource.findIndex(x => x.id === record.id);
                    newSource[idx] = { ...record, ...newPdt };
                    this.setState({ dataSource: newSource });
                }} />
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord !== nextRecord,
            width: 15
        },
    ];

    componentDidMount() {
        this.loadData();
    }

    handlePageChange = (page, pageSize) => {
        this.setState({
            pageNumber: page,
            pageSize: pageSize
        }, this.loadData);
    }

    loadData = () => {
        const _this = this;
        PageLoading.show();
        request.get('/Product/GetVendorProducts', {
            params: {
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize,
                name: this.search.current.input.value
            }
        }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.setState({
                    dataSource: response.result.data,
                    totalCount: response.result.totalRecord
                });
            }
        });
    }

    onSearch = (value) => {
        this.setState({ pageNumber: 1 }, this.loadData);
    }

    render() {
        return (<PageContainer className="vendor-product-page">
            <Card loading={this.state.loading}>
                <Row gutter={[12, 12]}>
                    <Col xs={24} sm={12} md={16}>
                        <NewVendorProduct afterUpdate={() => this.loadData()} />
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Tooltip title="搜索产品名称、产品描述">
                            <Search ref={this.search} placeholder="搜索..." allowClear onSearch={this.onSearch} />
                        </Tooltip>
                    </Col>
                </Row>
                <Table rowKey='id'
                    columns={this.columns}
                    dataSource={this.state.dataSource}
                    tableLayout='fixed'
                    size="middle"
                    scroll={{ x: 950 }}
                    pagination={{
                        pageSize: this.state.pageSize,
                        current: this.state.pageNumber,
                        total: this.state.totalCount,
                        showQuickJumper: true,
                        size: 'default',
                        onChange: this.handlePageChange
                    }} />
            </Card>
        </PageContainer>);
    }
}

export default ProductVendorProduct;