import React from "react";
import request from "@utils/request";
import { Modal, Spin, Alert, Button, Form, Input } from 'antd';
import IconFont from '@components/Common/IconFont';
import './UpdateTracking.less';

class UpdateTracking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            hasError: false
        }
    }

    form = React.createRef();

    handleOk = () => {
        const { record } = this.props;
        this.form.current.validateFields().then(values => {
            const controller = new AbortController();
            const { signal } = controller;
            this.controller = controller;
            this.setState({ loading: true, hasError: false });
            const _this = this;
            const newRecord = {
                ...record,
                transportWay: values.transportWay,
                transportWayCode: values.transportWayCode,
                expressId: values.expressId,
                carrierEName: values.carrierEName,
                carrierWebsite: values.carrierWebsite,
                trackingNumber: values.trackingNumber
            };
            request.post('/Order/UpdateTrackingInfo', {
                signal,
                data: newRecord
            }).then(function (response) {
                _this.setState({ loading: false });
                if (response && response.success) {
                    newRecord.canTrackCarrier = response.result;
                    _this.setState({ visible: false });
                    if (_this.props.afterUpdate) {
                        _this.props.afterUpdate(newRecord);
                    }
                }
                if (response && response.success === false) {
                    _this.setState({ hasError: true });
                }
            });
        }).catch(errorInfo => { });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleOpen = () => {
        if (this.controller) {
            this.controller.abort();
        }
        this.setState({ visible: true, hasError: false }, () => {
            this.form.current.resetFields();
        });
    }

    render() {
        const { record } = this.props;
        return (
            <>
                <Button shape="round" type="primary" onClick={this.handleOpen} icon={<IconFont type="icon-nearme" />}>
                    更新跟踪号
                </Button>
                <Modal title="更新跟踪号"
                    width={800}
                    okButtonProps={{ loading: this.state.loading }}
                    maskClosable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Spin spinning={this.state.loading}>
                        {this.state.hasError && <Alert message="更新跟踪号失败！" style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                        <Form ref={this.form} labelAlign="left" labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} initialValues={{
                            transportWay: record.transportWay,
                            transportWayCode: record.transportWayCode,
                            expressId: record.expressId,
                            carrierEName: record.carrierEName,
                            carrierWebsite: record.carrierWebsite,
                            trackingNumber: record.trackingNumber
                        }}>
                            <Form.Item label="物流商">
                                <div>{record.expressTypeString}</div>
                            </Form.Item>
                            <Form.Item label="运输渠道" name="transportWay" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="渠道代码" name="transportWayCode">
                                <Input />
                            </Form.Item>
                            <Form.Item label="运单号" name="expressId" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="末端派送商" name="carrierEName" extra="英文名称" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="跟踪网址" name="carrierWebsite" rules={[{ type: "url" }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="跟踪号" name="trackingNumber" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        );
    }
};

export default UpdateTracking;