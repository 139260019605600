import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import PageLoading from '@components/Common/PageLoading/PageLoading';
import commonUtil from "@utils/commonUtil";
import request from "@utils/request";
import { Card, Form, Row, Col, Button, message, InputNumber } from "antd";
import './common-setting.less';

class CommonSetting extends React.Component {
    state = {
        loading: false,
    }
    form = React.createRef();

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/Setting/GetCommonSettingConfig').then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                _this.setting = response.result;
                _this.form.current.setFieldsValue({
                    packagePrice: response.result.customConfig.packagePriceSettingInfo.packageUnitPrice,
                    giftPrice: response.result.customConfig.packagePriceSettingInfo.giftPackagingUnitPrice,
                    backupKeepDays: response.result.customConfig.awsSettingInfo.backupKeepDays
                })
            }
            if (response && response.success === false) {
                message.error("系统异常，获取默认设置失败！");
            }
        });

    }

    handleSave = () => {
        this.form.current.validateFields().then(values => {
            PageLoading.show();
            const _this = this;
            _this.setting.customConfig.packagePriceSettingInfo.packageUnitPrice = values.packagePrice;
            _this.setting.customConfig.packagePriceSettingInfo.giftPackagingUnitPrice = values.giftPrice;
            _this.setting.customConfig.awsSettingInfo.backupKeepDays = values.backupKeepDays;
            _this.setting.customConfig.etsyInventorySettingInfo.MinQuantity = values.minQuantity;
            _this.setting.customConfig.etsyInventorySettingInfo.MaxQuantity = values.maxQuantity;
            request.post('/Setting/SaveCommonSetting', {
                data: _this.setting
            }).then(function (response) {
                PageLoading.hide();
                if (response && response.success) {
                    message.success("更新系统设置成功！");
                }
                if (response && response.success === false) {
                    message.error("系统异常，更新系统设置失败！");
                }
            });
        }).catch(errorInfo => { });
    }

    render() {
        return (<PageContainer className="common-setting-page">
            <Form ref={this.form}>
                <Card title="打包价格设置" loading={this.state.loading}>
                    <Row gutter={24}>
                        <Col xs={24} sm={12}>
                            <Form.Item label="打包单价" required style={{ marginBottom: 0 }}>
                                <Form.Item name="packagePrice"
                                    style={{ display: 'inline-block', width: 'calc(100% - 60px)' }}
                                    rules={[{ required: true, message: "请输入打包价格" }, { validator: (_, value) => commonUtil.greaterThanZero(_, value, "") }]}>
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item style={{ display: 'inline-block', marginLeft: 12 }}>元/件</Form.Item>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="包装单价" required style={{ marginBottom: 0 }}>
                                <Form.Item name="giftPrice"
                                    style={{ display: 'inline-block', width: 'calc(100% - 60px)' }}
                                    rules={[{ required: true, message: "请输入打包价格" }, { validator: (_, value) => commonUtil.greaterThanZero(_, value, "") }]}>
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item style={{ display: 'inline-block', marginLeft: 12 }}>元/件</Form.Item>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Card title="ETSY 库存设置" loading={this.state.loading}>
                    <Row gutter={24}>
                        <Col xs={24} sm={12}>
                            <Form.Item label="最小库存数量" required style={{ marginBottom: 0 }}>
                                <Form.Item name="minQuantity"
                                    style={{ display: 'inline-block', width: 'calc(100% - 60px)' }}
                                    rules={[{ required: true, message: "请输入最小库存数量" }, { validator: (_, value) => commonUtil.greaterThanZero(_, value, "") }]}>
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item style={{ display: 'inline-block', marginLeft: 12 }}>个</Form.Item>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="最大库存数量" required style={{ marginBottom: 0 }}>
                                <Form.Item name="maxQuantity"
                                    style={{ display: 'inline-block', width: 'calc(100% - 60px)' }}
                                    rules={[{ required: true, message: "请输入最小库存数量" }, { validator: (_, value) => commonUtil.greaterThanZero(_, value, "") }]}>
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item style={{ display: 'inline-block', marginLeft: 12 }}>个</Form.Item>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Card style={{ marginTop: 24 }} title="Amazon AWS设置" loading={this.state.loading}>
                    <Row gutter={24}>
                        <Col xs={24} sm={12}>
                            <Form.Item label="数据库备份文件保留天数" required style={{ marginBottom: 0 }}>
                                <Form.Item name="backupKeepDays"
                                    style={{ display: 'inline-block', width: 'calc(100% - 50px)' }}
                                    rules={[{ required: true, message: "请输入保留天数" }, { validator: (_, value) => commonUtil.greaterThanZero(_, value, "") }]}>
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item style={{ display: 'inline-block', marginLeft: 12 }}>天</Form.Item>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
            <Button type="primary" onClick={this.handleSave} style={{ marginTop: 24, float: 'right' }}>保存</Button>
        </PageContainer >);
    }
}

export default CommonSetting;