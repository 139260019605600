import React from "react";
import { useState } from "react";
import ResizeObserver from 'rc-resize-observer';
import * as _ from 'lodash';
import classNames from 'classnames';

const TableContainer = (props) => {
    const { className, children } = props;
    const tableContent = React.createRef();
    const [pingLeft, setPingLeft] = useState(false);
    const [pingRight, setPingRight] = useState(false);

    const handleChange = () => {
        if (tableContent && tableContent.current) {
            let newPingLeft = false;
            let newPingRight = false;
            const scrollWidth = tableContent.current.scrollWidth;
            const clientWidth = tableContent.current.clientWidth;
            const scrollLeft = tableContent.current.scrollLeft;
            if (clientWidth < scrollWidth) {
                newPingLeft = true;
                newPingRight = true;
                if (scrollLeft === 0) {
                    newPingLeft = false;
                }
                if (scrollLeft + clientWidth + 1 >= scrollWidth) {
                    newPingRight = false;
                }
            } else {
                newPingLeft = false;
                newPingRight = false;
            }
            if (pingLeft !== newPingLeft) {
                setPingLeft(newPingLeft);
            }
            if (pingRight !== newPingRight) {
                setPingRight(newPingRight);
            }
        }
    }

    const throttleResize = _.throttle(handleChange, 200);

    return (
        <div className={classNames({
            'ant-table': true,
            'ant-table-middle': true,
            'ant-table-ping-left': pingLeft,
            'ant-table-ping-right': pingRight,
        }, className)}>
            <div className="ant-table-container">
                <div ref={tableContent} onScroll={throttleResize} className="ant-table-content">
                    <ResizeObserver onResize={throttleResize}>
                        {children}
                    </ResizeObserver>
                </div>
            </div>
        </div>
    );
}

export default TableContainer;