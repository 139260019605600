import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import PageLoading from '@components/Common/PageLoading/PageLoading';
import commonConfig from '@common/commonConfig';
import request from "@utils/request";
import { Card, Table, Input, Typography, Tooltip, Row, Col } from "antd";
import SignInSpareExpress from "@components/Spare/SignInSpareExpress/SignInSpareExpress";
import './spare-express.less';

const { Search } = Input;
const { Text } = Typography;

class SpareExpress extends React.Component {
    state = {
        loading: false,
        pageNumber: 1,
        pageSize: commonConfig.pageSize,
        dataSource: null,
        totalCount: 0
    }

    search = React.createRef();

    columns = [
        {
            title: '备品名称',
            key: 'spareName',
            dataIndex: 'spareName',
            width: 15
        },
        {
            title: '购入数量',
            key: 'count',
            dataIndex: 'count',
            width: 8
        },
        {
            title: '快递',
            key: 'expressName',
            dataIndex: 'expressName',
            render: (expressName, record) => (
                <div>
                    <div>{expressName}</div>
                    <div>{record.expressNumber}</div>
                </div>
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.expressName !== nextRecord.expressName
                || prevRecord.expressNumber !== nextRecord.expressNumber,
            width: 16
        },
        {
            title: '签收状态',
            key: 'deliverStatus',
            dataIndex: 'deliverStatus',
            render: (deliverStatus, record) => (
                <Text type={deliverStatus === 1 ? "success" : "warning"}>{record.deliverStatusString}</Text>
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.deliverStatus !== nextRecord.deliverStatus
                || prevRecord.deliverStatusString !== nextRecord.deliverStatusString,
            width: 10
        },
        {
            title: '签收时间',
            key: 'receiveTimeString',
            dataIndex: 'receiveTimeString',
            width: 13
        },
        {
            title: '创建时间',
            key: 'createTimeString',
            dataIndex: 'createTimeString',
            width: 13
        },
        {
            title: '备注',
            key: 'comment',
            dataIndex: 'comment',
            width: 15
        },
        {
            title: '操作',
            key: 'action',
            render: (record) => record.deliverStatus === 1 ? "" : (
                <SignInSpareExpress record={record} afterUpdate={(newSpare) => {
                    this.loadData();
                }} />
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord !== nextRecord
                || prevRecord.id !== nextRecord.id
                || prevRecord.deliverStatus !== nextRecord.deliverStatus,
            width: 10
        },
    ];

    componentDidMount() {
        this.loadData();
    }

    handleValuesChange = (values) => {
        this.setState({ pageNumber: 1 }, this.loadData);
    }

    onSearch = (value) => {
        this.setState({ pageNumber: 1 }, this.loadData);
    }

    loadData = () => {
        const _this = this;
        PageLoading.show();
        request.get('/Spare/GetExpressSpareRecords', {
            params: {
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize,
                name: this.search.current.input.value
            }
        }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.setState({
                    dataSource: response.result.data,
                    totalCount: response.result.totalRecord
                });
            }
        });
    }

    handlePageChange = (page, pageSize) => {
        this.setState({
            pageNumber: page,
            pageSize: pageSize
        }, this.loadData);
    }

    render() {
        return (<PageContainer className="spare-express-page">
            <Card loading={this.state.loading}>
                <Row>
                    <Col xs={24} sm={{ span: 12, push: 12 }} md={{ span: 8, push: 16 }}>
                        <Tooltip title="搜索备品名称、快递">
                            <Search ref={this.search} placeholder="搜索..." allowClear onSearch={this.onSearch} style={{ marginBottom: 12 }} />
                        </Tooltip>
                    </Col>
                </Row>
                <Table rowKey='id'
                    columns={this.columns}
                    dataSource={this.state.dataSource}
                    tableLayout='fixed'
                    size="middle"
                    scroll={{ x: 950 }}
                    pagination={{
                        pageSize: this.state.pageSize,
                        current: this.state.pageNumber,
                        total: this.state.totalCount,
                        showQuickJumper: true,
                        size: 'default',
                        onChange: this.handlePageChange
                    }} />
            </Card>
        </PageContainer>);
    }
}

export default SpareExpress;