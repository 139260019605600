import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import PageLoading from '@components/Common/PageLoading/PageLoading';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import withBreakpoint from '@components/Common/withBreakpoint';
import commonConfig from '@common/commonConfig';
import moment from 'moment';
import request from "@utils/request";
import { Card, Form, Space, DatePicker, Popconfirm, InputNumber, Radio, Button, Select, message, Badge, Table, Drawer, Spin, Modal, Alert, Input } from "antd";
import './site-setting.less';

const { Option } = Select;

class SiteSetting extends React.Component {
    state = {
        visible: false,
        detailLoading: false,
        detailTitle: null,
        currentSite: null,
        addLoading: false,
        addVisible: false,
        siteTypes: null,
        shopType: null,
        editSite: null,
        isEdit: false
    }

    form = React.createRef();

    handleSiteClick = (record) => {
        this.setState({
            visible: true,
            detailTitle: record.displayName,
            currentSite: record,
            detailLoading: true
        });
        const _this = this;
        request.get('/Site/GetSiteDetail', {
            params: {
                id: record.id
            }
        }).then(function (response) {
            _this.setState({ detailLoading: false });
            if (response && response.success) {
                _this.setState({ currentSite: response.result });
            }
            if (response && response.success === false) {
                message.error("系统异常，获取店铺详情失败！");
            }
        });
    }

    handleClose = () => {
        this.setState({ visible: false });
    }

    handleDeleteConfirm = (record) => {
        this.setState({ addLoading: true, errorMsg: null });
        const _this = this;
        PageLoading.show();
        request.post(`/Site/DeleteSite/${record.id}`).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.loadAllSites();
                message.success(`删除店铺${record.displayName}成功！`);
            }
            if (response && response.success === false) {
                _this.setState({ errorMsg: "删除店铺失败！" });
            }
        });
    }

    columns = [
        {
            title: '店铺名称',
            key: 'displayName',
            dataIndex: 'displayName',
            width: 20,
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.displayName !== nextRecord.displayName,
            render: (displayName, record) => <a href='#!' onClick={() => this.handleSiteClick(record)}>{displayName}</a>,
        },
        {
            title: '店铺Name/Url',
            key: 'name',
            dataIndex: 'name',
            width: 28
        },
        {
            title: '状态',
            key: 'siteStatus',
            dataIndex: 'siteStatus',
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.siteStatus !== nextRecord.siteStatus,
            render: siteStatus => (siteStatus === 0 ? <Badge status="processing" text="运行中" /> : <Badge status="error" text="停用" />),
            width: 14
        },
        {
            title: '类型',
            key: 'shopTypeString',
            dataIndex: 'shopTypeString',
            width: 16
        },
        {
            title: '同步时间',
            key: 'syncTime',
            dataIndex: 'syncTime',
            width: 22
        },
        {
            title: '操作',
            key: 'action',
            render: (record) => (
                <Space wrap>
                    <Button type="primary" onClick={() => this.handleEditSite(record)} shape="round" icon={<EditOutlined />}>编辑</Button>
                    <Popconfirm
                        title="确定要删除此店铺么？"
                        onConfirm={() => this.handleDeleteConfirm(record)}
                        onCancel={this.handleCancel}
                    >
                        <Button type="default" shape="circle" icon={<DeleteOutlined />}></Button>
                    </Popconfirm>
                </Space>
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord !== nextRecord
                || prevRecord.id !== nextRecord.id,
            width: 20
        }
    ];

    renderCard = () => {
        switch (this.state.currentSite.shopType) {
            case 1: //ETSY
                return <>
                    <tr>
                        <td>IOSS Number</td>
                        <td>{this.state.currentSite.iossNumber}</td>
                    </tr>
                    <tr>
                        <td>Shop Id</td>
                        <td>{this.state.currentSite.shopId}</td>
                    </tr>
                    <tr>
                        <td>App Key</td>
                        <td>{this.state.currentSite.appKey}</td>
                    </tr>
                    <tr>
                        <td>App Secret</td>
                        <td>{this.state.currentSite.appSecret}</td>
                    </tr>
                    <tr>
                        <td>Access Token</td>
                        <td>{this.state.currentSite.accessToken}</td>
                    </tr>
                    <tr>
                        <td>Token Secret</td>
                        <td>{this.state.currentSite.accessTokenSecret}</td>
                    </tr>
                    <tr>
                        <td>Remote Agent</td>
                        <td>{this.state.currentSite.remoteAgent}</td>
                    </tr>
                    <tr>
                        <td>Agent Api Key</td>
                        <td>{this.state.currentSite.agentApiKey}</td>
                    </tr>
                    <tr>
                        <td>Agent Password</td>
                        <td>{this.state.currentSite.agentPassword}</td>
                    </tr>
                </>
            case 2: //Shopify
                return <>
                    <tr>
                        <td>Location Id</td>
                        <td>{this.state.currentSite.locationId}</td>
                    </tr>
                    <tr>
                        <td>App Key</td>
                        <td>{this.state.currentSite.appKey}</td>
                    </tr>
                    <tr>
                        <td>App Secret</td>
                        <td>{this.state.currentSite.appSecret}</td>
                    </tr>
                    <tr>
                        <td>Webhook Secret</td>
                        <td>{this.state.currentSite.webhookSecret}</td>
                    </tr>
                </>;
            case 3: //WordPress
                return <></>;
            case 4: //ManualCreate
                return <></>;
            case 5: //Amazon
                return <>
                    <tr>
                        <td>App Key</td>
                        <td>{this.state.currentSite.appKey}</td>
                    </tr>
                    <tr>
                        <td>App Secret</td>
                        <td>{this.state.currentSite.appSecret}</td>
                    </tr>
                    <tr>
                        <td>Refresh Token</td>
                        <td>{this.state.currentSite.accessToken}</td>
                    </tr>
                    <tr>
                        <td>Access Key Id</td>
                        <td>{this.state.currentSite.accessKeyId}</td>
                    </tr>
                    <tr>
                        <td>Secret Key</td>
                        <td>{this.state.currentSite.secretKey}</td>
                    </tr>
                    <tr>
                        <td>Role ARN</td>
                        <td>{this.state.currentSite.roleArn}</td>
                    </tr>
                </>
            default:
                return '';
        }
    }

    handleAddOpen = () => {
        if (this.controller) {
            this.controller.abort();
        }
        this.setState({ addVisible: true, isEdit: false, errorMsg: null }, () => {
            this.handleShopTypeChange(null, () => {
                this.form.current.resetFields();
            });
        });
    }

    handleAddCancel = () => {
        this.setState({ addVisible: false });
    }

    handleAddOk = () => {
        this.form.current.validateFields().then(values => {
            const controller = new AbortController();
            const { signal } = controller;
            this.controller = controller;
            this.setState({ addLoading: true, errorMsg: null });
            const _this = this;
            if (this.state.isEdit) {
                request.post('/Site/UpdateSite', {
                    signal,
                    data: { id: this.state.editSite.id, ...values, syncTime: values.syncTime.format(commonConfig.dateTimeFormat) }
                }).then(function (response) {
                    _this.setState({ addLoading: false });
                    if (response && response.success) {
                        _this.setState({ addVisible: false });
                        _this.loadAllSites();
                        message.success(`编辑店铺${values.displayName}成功！`);
                    }
                    if (response && response.success === false) {
                        _this.setState({ errorMsg: "更新店铺信息失败！" });
                    }
                });
            } else {
                request.post('/Site/CreateSite', {
                    signal,
                    data: { ...values, syncTime: values.syncTime.format(commonConfig.dateTimeFormat) }
                }).then(function (response) {
                    _this.setState({ addLoading: false });
                    if (response && response.success) {
                        _this.setState({ addVisible: false });
                        _this.loadAllSites();
                        message.success(`添加店铺${values.displayName}成功！`);
                    }
                    if (response && response.success === false) {
                        _this.setState({ errorMsg: "添加店铺失败！" });
                    }
                });
            }
        }).catch(errorInfo => { });
    }

    handleEditSite = (record) => {
        if (this.controller) {
            this.controller.abort();
        }
        this.setState({ addVisible: true, isEdit: true, addLoading: true, editSite: null, errorMsg: null }, () => {
            this.form.current.resetFields();
            const _this = this;
            request.get('/Site/GetSiteDetail', {
                params: {
                    id: record.id
                }
            }).then(function (response) {
                _this.setState({ addLoading: false });
                if (response && response.success) {
                    _this.setState({ editSite: response.result });
                    _this.handleShopTypeChange(response.result.shopType, () => {
                        _this.form.current.setFieldsValue({ ...response.result, syncTime: moment(response.result.syncTime) });
                    });
                }
                if (response && response.success === false) {
                    message.error("系统异常，获取店铺详情失败！");
                }
            });
        });
    }

    loadAllSites = () => {
        const _this = this;
        PageLoading.show();
        request.get('/Site/GetSimpleSites').then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.setState({ dataSource: response.results });
            }
            if (response && response.success === false) {
                message.error("系统异常，获取店铺列表失败！");
            }
        });
    }

    componentDidMount() {
        const _this = this;
        this.loadAllSites();
        request.get('/Site/GetSiteTypes').then(function (response) {
            if (response && response.success) {
                _this.setState({ siteTypes: response.results });
            }
        });
    }

    handleShopTypeChange = (value, callback) => {
        this.setState({ shopType: value }, () => {
            if (callback) {
                callback();
            }
        });
    }

    render() {
        return (<PageContainer className="site-setting-page">
            <Card>
                <Button type="primary" onClick={this.handleAddOpen} icon={<PlusOutlined />} ghost>
                    添加店铺
                </Button>
                <Table rowKey='id'
                    columns={this.columns}
                    style={{ marginTop: 12 }}
                    dataSource={this.state.dataSource}
                    tableLayout='fixed'
                    size="middle"
                    scroll={{ x: 950 }}
                    pagination={false} />
            </Card>
            <Drawer width={this.props.breakpoint.sm === false ? 300 : 450} className="site-setting-drawer" title={<div>{this.state.detailTitle}</div>} placement="right" visible={this.state.visible} onClose={this.handleClose}>
                <Spin spinning={this.state.detailLoading}>
                    {this.state.currentSite && <div>
                        <table className="site-detail-table">
                            <colgroup>
                                <col width={35} />
                                <col width={65} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td>店铺名称</td>
                                    <td>{this.state.currentSite.displayName}</td>
                                </tr>
                                <tr>
                                    <td>店铺Name/Url</td>
                                    <td>{this.state.currentSite.name}</td>
                                </tr>
                                <tr>
                                    <td>状态</td>
                                    <td>{this.state.currentSite.siteStatus === 0 ? <Badge status="processing" text="运行中" /> : <Badge status="error" text="停用" />}</td>
                                </tr>
                                <tr>
                                    <td>同步时间</td>
                                    <td>{this.state.currentSite.syncTime}</td>
                                </tr>
                                <tr>
                                    <td>Order Prefix</td>
                                    <td>{this.state.currentSite.orderPrefix}</td>
                                </tr>
                                <tr>
                                    <td>Increase Number</td>
                                    <td>{this.state.currentSite.increaseNumber}</td>
                                </tr>
                            </tbody>
                        </table>
                        <Card className="shop-type-card" title={this.state.currentSite.shopTypeString} size="small">
                            <table className="site-detail-table">
                                <colgroup>
                                    <col width={35} />
                                    <col width={65} />
                                </colgroup>
                                <tbody>
                                    {this.renderCard()}
                                </tbody>
                            </table>
                        </Card>
                    </div>}
                </Spin>
            </Drawer>
            <Modal title={this.state.isEdit ? "编辑店铺" : "添加店铺"}
                okButtonProps={{ loading: this.state.addLoading }}
                maskClosable={false}
                visible={this.state.addVisible}
                onOk={this.handleAddOk}
                width={800}
                onCancel={this.handleAddCancel}
            >
                <Spin spinning={this.state.addLoading}>
                    {!!this.state.errorMsg && <Alert message={this.state.errorMsg} style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                    <Form ref={this.form} onValuesChange={this.handleValueChange} labelAlign="left" labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} initialValues={{
                        siteStatus: 0,
                        increaseNumber: 1000
                    }}>
                        <Form.Item label="店铺名称" name="displayName" rules={[{ required: true }]}>
                            <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item label="店铺Name/Url" name="name" rules={[{ required: true }]}>
                            <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item label="状态" name="siteStatus" rules={[{ required: true }]}>
                            <Radio.Group>
                                <Radio value={0}><Badge status="processing" text="运行中" /></Radio>
                                <Radio value={1}><Badge status="error" text="停用" /></Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="同步时间" name="syncTime" rules={[{ required: true, message: "请输入中国时间" }]} >
                            <DatePicker showTime format={commonConfig.dateTimeFormat} />
                        </Form.Item>
                        <Form.Item label="Order Prefix" name="orderPrefix" rules={[{ required: true }]}>
                            <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item label="Increase Number" name="increaseNumber" rules={[{ required: true }]}>
                            <InputNumber step={1} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item label="类型" name="shopType" rules={[{ required: true }]}>
                            <Select onChange={(value) => this.handleShopTypeChange(value)}>
                                {this.state.siteTypes?.map(s => <Option key={s.value} value={s.value}>{s.text}</Option>)}
                            </Select>
                        </Form.Item>
                        {this.state.shopType === 1 && <>
                            <Form.Item label="IOSS Number" name="iossNumber">
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="Shop Id" name="shopId" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="App Key" name="appKey" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="App Secret" name="appSecret" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="Access Token" name="accessToken" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="Token Secret" name="accessTokenSecret" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="Remote Agent" name="remoteAgent" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="Agent Api Key" name="agentApiKey" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="Agent Password" name="agentPassword" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                        </>
                        }
                        {this.state.shopType === 2 && <>
                            <Form.Item label="Location Id" name="locationId" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="App Key" name="appKey" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="App Secret" name="appSecret" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="Webhook Secret" name="webhookSecret" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                        </>
                        }
                        {this.state.shopType === 3 && <>
                        </>
                        }
                        {this.state.shopType === 4 && <>
                        </>
                        }
                        {this.state.shopType === 5 && <>
                            <Form.Item label="App Key" name="appKey" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="App Secret" name="appSecret" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="Refresh Token" name="accessToken" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="Access Key Id" name="accessKeyId" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="Secret Key" name="secretKey" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="Role ARN" name="roleArn" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                        </>
                        }
                        {this.state.shopType === 6 && <>
                            <Form.Item label="IOSS Number" name="iossNumber">
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="Shop Id" name="shopId" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="App Key" name="appKey" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="Remote Agent" name="remoteAgent" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="Agent Api Key" name="agentApiKey" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="Agent Password" name="agentPassword" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                        </>
                        }
                    </Form>
                </Spin>
            </Modal>
        </PageContainer >);
    }
}

export default withBreakpoint(SiteSetting);