import React from "react";
import request from "@utils/request";
import { Modal, Spin, Alert, Button, Form, Input, Row, Col, Card, message } from 'antd';
import { FileAddOutlined, DeleteOutlined } from '@ant-design/icons';
import { isAdmin } from "@utils/authority";
import commonUtil from "@utils/commonUtil";
import './ManualCreateOrder.less';

class ManualCreateOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            errorMsg: null,
            lineItems: [{}]
        }
    }

    form = React.createRef();

    handleOk = () => {
        this.form.current.validateFields().then(values => {
            const controller = new AbortController();
            const { signal } = controller;
            this.controller = controller;
            this.setState({ loading: true, errorMsg: null });
            const _this = this;

            const newRecord = { ...values };
            request.post('/Order/CreateManualOrder', {
                signal,
                data: newRecord
            }).then(function (response) {
                _this.setState({ loading: false });
                if (response && response.success) {
                    _this.setState({ visible: false });
                    message.success(`${newRecord.orderNumber} 创建成功！`)
                    if (_this.props.afterUpdate) {
                        _this.props.afterUpdate(newRecord);
                    }
                }
                if (response && response.success === false) {
                    _this.setState({ errorMsg: response.errorMsg });
                }
            });
        }).catch(errorInfo => { });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleOpen = () => {
        if (this.controller) {
            this.controller.abort();
        }
        const _this = this;
        _this.setState({ visible: true, loading: true, lineItems: [{}], errorMsg: null }, () => {
            _this.form.current.resetFields();
            request.get('/Order/GetSiteByType').then(function (response) {
                _this.setState({ loading: false });
                if (response && response.success) {
                    _this.form.current.setFieldsValue({
                        orderNumber: `${response.result.orderPrefix}${response.result.increaseNumber + 1}`,
                        siteId: response.result.id
                    })
                }
                if (response && response.success === false) {
                    _this.setState({ errorMsg: "获取Manual Create站点信息失败！" });
                }
            });
        });
    }

    handleAddProduct = () => {
        const newItems = [...this.state.lineItems, {}];
        let formItems = this.form.current.getFieldsValue().lineItems;
        formItems = formItems ? formItems : [];
        this.form.current.setFieldsValue({ "lineItems": [...formItems, {}] })
        this.setState({ lineItems: newItems });
    }

    handleRemoveProduct = (idx) => {
        const newItems = this.state.lineItems.slice();
        newItems.splice(idx, 1);
        const formItems = this.form.current.getFieldsValue().lineItems;
        formItems.splice(idx, 1);
        this.form.current.setFieldsValue({ "lineItems": formItems })
        this.setState({ lineItems: newItems });
    }

    handleAddOption = (idx) => {
        const newItems = [...this.state.lineItems];
        newItems[idx].options = newItems[idx].options?.length > 0 ? [...newItems[idx].options, {}] : [{}];
        let formItems = this.form.current.getFieldsValue().lineItems;
        formItems[idx].options = formItems[idx].options?.length > 0 ? [...formItems[idx].options, {}] : [{}];
        this.form.current.setFieldsValue({ "lineItems": formItems });
        this.setState({ lineItems: newItems });
    }

    handleRemoveOption = (lineIdx, optionIdx) => {
        const newItems = this.state.lineItems.slice();
        newItems[lineIdx].options.splice(optionIdx, 1);
        const formItems = this.form.current.getFieldsValue().lineItems;
        formItems[lineIdx].options.splice(optionIdx, 1);
        this.form.current.setFieldsValue({ "lineItems": formItems })
        this.setState({ lineItems: newItems });
    }

    render() {
        const { lineItems } = this.state;
        return isAdmin() ? (
            <>
                <Button type="primary" ghost onClick={this.handleOpen} icon={<FileAddOutlined />}>
                    手动建单
                </Button>
                <Modal title="手动建单"
                    wrapClassName="manual-create-order-modal"
                    okButtonProps={{ loading: this.state.loading }}
                    maskClosable={false}
                    width={800}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Spin spinning={this.state.loading}>
                        {!!this.state.errorMsg && <Alert message={this.state.errorMsg} style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                        <Form ref={this.form} labelAlign="left" labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} initialValues={{
                            siteId: "",
                            orderNumber: "",
                            orderId: "",
                            receiverName: "",
                            receiverCountry: "",
                            secondLine: "",
                            firstLine: "",
                            city: "",
                            state: "",
                            zip: "",
                            phoneNumber: "",
                            email: "",
                            company: "",
                            lineItems: [{}]
                        }}>
                            <Form.Item hidden={true} name="siteId">
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="新建单号" name="orderNumber" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="订单Id" name="orderId" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="收件人" name="receiverName" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="收件人国家" name="receiverCountry" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="门牌号/建筑物" name="secondLine">
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="街道名" name="firstLine" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="城市" name="city" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="省/州" name="state" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="收货邮编" name="zip" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="收件人手机" name="phoneNumber">
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="邮箱" name="email" rules={[{ type: "email" }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="公司名称" name="company">
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="客户备注" name="buyerComment">
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Button type="primary" ghost shape="round" onClick={this.handleAddProduct}>添加产品</Button>
                            <Row gutter={[12, 12]} style={{ marginTop: 12 }}>
                                {
                                    lineItems?.map((lineItem, idx) =>
                                        <Col key={idx} xs={24} sm={12} md={8}>
                                            <Card className="product-item-card"
                                                size="small">
                                                <Form.Item label="产品Id"
                                                    labelCol={{ span: 7 }}
                                                    wrapperCol={{ span: 17 }}
                                                    name={['lineItems', idx, 'productId']}
                                                    rules={[{ required: true }]}>
                                                    <Input autoComplete="off" />
                                                </Form.Item>
                                                <Form.Item label="品类Id"
                                                    labelCol={{ span: 7 }}
                                                    wrapperCol={{ span: 17 }}
                                                    name={['lineItems', idx, 'variantId']}
                                                    rules={[{ required: true }]}>
                                                    <Input autoComplete="off" />
                                                </Form.Item>
                                                <Form.Item label="图片"
                                                    labelCol={{ span: 7 }}
                                                    wrapperCol={{ span: 17 }}
                                                    name={['lineItems', idx, 'thumbnail']}
                                                    rules={[{ required: true }]}>
                                                    <Input autoComplete="off" />
                                                </Form.Item>
                                                <Form.Item label="名称"
                                                    labelCol={{ span: 7 }}
                                                    wrapperCol={{ span: 17 }}
                                                    name={['lineItems', idx, 'title']}
                                                    rules={[{ required: true }]}>
                                                    <Input autoComplete="off" />
                                                </Form.Item>
                                                <Form.Item label="样式" labelCol={{ span: 7 }}
                                                    wrapperCol={{ span: 17 }}>
                                                    <Button type="primary" shape="round" size="small" ghost onClick={() => this.handleAddOption(idx)}>添加样式</Button>
                                                </Form.Item>
                                                {
                                                    lineItem.options?.map((o, oidx) =>
                                                        <React.Fragment key={oidx}>
                                                            <Form.Item wrapperCol={{ span: 24 }}>
                                                                <Row gutter={4}>
                                                                    <Col span={10}>
                                                                        <Form.Item
                                                                            name={['lineItems', idx, 'options', oidx, 'name']}
                                                                            noStyle
                                                                            rules={[{ required: true, message: '请输入样式名' }]}
                                                                        >
                                                                            <Input placeholder="样式名" autoComplete="off" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={10}>
                                                                        <Form.Item
                                                                            name={['lineItems', idx, 'options', oidx, 'value']}
                                                                            noStyle
                                                                            rules={[{ required: true, message: '请输入样式值' }]}
                                                                        >
                                                                            <Input placeholder="样式值" autoComplete="off" />
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col span={4}>
                                                                        <Button type="link" danger ghost onClick={() => this.handleRemoveOption(idx, oidx)} icon={<DeleteOutlined />}></Button>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Item>
                                                        </React.Fragment>
                                                    )
                                                }
                                                <Form.Item label="数量"
                                                    labelCol={{ span: 7 }}
                                                    wrapperCol={{ span: 17 }}
                                                    name={['lineItems', idx, 'count']}
                                                    rules={[{ required: true }, { validator: (_, value) => commonUtil.greaterThanZero(_, value, "数量") }]}>
                                                    <Input autoComplete="off" />
                                                </Form.Item>
                                                <Button type="primary" ghost danger style={{ width: "100%" }} onClick={() => this.handleRemoveProduct(idx)} icon={<DeleteOutlined />}>移除</Button>
                                            </Card>
                                        </Col>)
                                }
                            </Row>
                        </Form>
                    </Spin>
                </Modal>
            </>
        ) : '';
    }
};

export default ManualCreateOrder;