import React from "react";
import request from "@utils/request";
import { Modal, Spin, Alert, Input, Button, Form, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';

class EditVariantInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            errorMsg: null
        }
    }

    form = React.createRef();

    handleOk = () => {
        this.form.current.validateFields().then(values => {
            const controller = new AbortController();
            const { signal } = controller;
            this.controller = controller;
            this.setState({ loading: true, errorMsg: null });
            const _this = this;
            const newData = {
                variantInfo: { ...this.props.record, ...values },
                isCreate: false
            };
            request.post('/Product/CreateVariantInfo', {
                signal,
                data: newData
            }).then(function (response) {
                _this.setState({ loading: false });
                if (response && response.success) {
                    _this.setState({ visible: false });
                    message.success(`编辑款式信息${values.cnTitle}成功！`);
                    if (_this.props.afterUpdate) {
                        _this.props.afterUpdate(newData.product);
                    }
                }
                if (response && response.success === false) {
                    _this.setState({ errorMsg: response.errorMsg });
                }
            });
        }).catch(errorInfo => { });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleOpenEdit = () => {
        if (this.controller) {
            this.controller.abort();
        }
        this.setState({ visible: true, errorMsg: null }, () => {
            this.form.current.resetFields();
        });
    }

    render() {
        const { record } = this.props;
        return (
            <>
                <Button type="primary" shape="round" icon={<EditOutlined />}
                    onClick={() => this.handleOpenEdit(record)}>编辑</Button>
                <Modal title="编辑款式信息"
                    okButtonProps={{ loading: this.state.loading }}
                    maskClosable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Spin spinning={this.state.loading}>
                        {!!this.state.errorMsg && <Alert message={this.state.errorMsg} style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                        <Form ref={this.form} labelAlign="right" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} initialValues={{
                            cnTitle: record.cnTitle,
                            vendorCode: record.vendorCode
                        }}>
                            <Form.Item label="款式名称" name="cnTitle" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="商家编号" name="vendorCode">
                                <Input autoComplete="off" />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        );
    }
};

export default EditVariantInfo;