import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import { Card, Button, Row, Col, Space, Select, Switch, Form, Table, Rate, message } from "antd";
import request from "@utils/request";
import commonConfig from '@common/commonConfig';
import PageLoading from '@components/Common/PageLoading/PageLoading';
import EditReview from '@components/Platform/EditReview/EditReview';
import DesignImageContainer from '@components/Order/DesignImageContainer/DesignImageContainer';
import IconFont from '@components/Common/IconFont';
import commonUtil from "@utils/commonUtil";
import './manage-review.less';

const { Option } = Select;

const topSiteSelectProps = {
    xs: 24,
    sm: 10,
    md: 10,
    lg: 10,
    xl: 10,
};

const topFilterSelectProps = {
    xs: 24,
    sm: 14,
    md: 14,
    lg: 14,
    xl: 14,
};

class ManageReview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            sites: null,
            products: [],
            totalCount: 0,
            productPageSize: 250,
            showLoadBtn: true,
            next_page_info: null,
            dataSource: null,
            pageSize: commonConfig.pageSize
        }
    }

    form = React.createRef();

    columns = [
        {
            title: '姓名',
            key: 'first_name',
            dataIndex: 'first_name',
            render: (first_name, record) => (
                <span>{first_name} {record.last_name}</span>
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.first_name !== nextRecord.first_name
                || prevRecord.last_name !== nextRecord.last_name,
            width: 9
        },
        {
            title: '邮箱',
            key: 'email',
            dataIndex: 'email',
            width: 11
        },
        {
            title: '评分',
            key: 'rate',
            dataIndex: 'rate',
            render: (rate) => (
                <Rate className="review-rate" allowClear={false} disabled value={rate} />
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.rate !== nextRecord.rate,
            width: 12
        },
        {
            title: '评价',
            key: 'comment',
            dataIndex: 'comment',
            width: 25
        },
        {
            title: '图片',
            key: 'thumbs',
            dataIndex: 'thumbs',
            render: (thumbs, record) => (
                <DesignImageContainer cols={2} record={{
                    designImageSmallList: thumbs,
                    designImageList: record.images
                }} />
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.thumbs !== nextRecord.thumbs
                || prevRecord.images !== nextRecord.images,
            width: 21
        },
        {
            title: '评价时间',
            key: 'upload_time_string',
            dataIndex: 'upload_time_string',
            width: 10
        },
        {
            title: '操作',
            key: 'approve',
            dataIndex: 'approve',
            render: (approve, record) => (
                <Space wrap>
                    <Switch checked={approve === 1} loading={record.loading} onChange={(checked) => this.handleSwitchChange(checked, record)} />
                    <EditReview record={record} afterUpdate={(newReview) => {
                        const newSource = [...this.state.dataSource];
                        const idx = newSource.findIndex(x => x.id === record.id);
                        newSource[idx] = { ...record, ...newReview };
                        this.setState({ dataSource: newSource });
                    }} />
                </Space>
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord !== nextRecord
                || prevRecord.approve !== nextRecord.approve
                || prevRecord.loading !== nextRecord.loading,
            width: 12
        },
    ];

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/Review/GetShopifySites').then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                _this.setState({
                    sites: response.results,
                }, () => {
                    _this.form.current.setFieldsValue({
                        site: response.results?.length > 0 ? response.results[0].id : null
                    });
                    if (response.results?.length > 0) {
                        _this.load_count();
                    }
                });
            }
        });
    }

    load_count = () => {
        PageLoading.show();
        const _this = this;
        const values = this.form.current.getFieldsValue();
        const { sites } = this.state;
        const siteObj = sites.find(s => s.id === values.site);
        request.post('/Base/SendShopifyRequest', {
            data: {
                site_id: values.site,
                address: "https://" + siteObj.name + "/admin/api/2021-04/products/count.json",
                method: 'GET'
            }
        }).then(function (response) {
            if (!!response?.errors) {
                PageLoading.hide();
                commonUtil.showShopifyError(response);
            } else {
                _this.setState({ totalCount: response.count }, () => _this.load_products());
            }
        });
    }

    load_products = () => {
        PageLoading.show();
        const _this = this;
        const values = this.form.current.getFieldsValue();
        const { products, totalCount, sites, productPageSize } = this.state;
        const siteObj = sites.find(s => s.id === values.site);
        var next_page_info_url = "";
        if (this.next_page_info) {
            next_page_info_url = "&page_info=" + this.next_page_info;
        }
        request.post('/Base/SendShopifyRequest', {
            data: {
                site_id: values.site,
                address: `https://${siteObj.name}/admin/api/2021-04/products.json?fields=id,title&limit=${productPageSize}${next_page_info_url}`,
                method: 'GET'
            }
        }).then(function (response) {
            if (!!response?.errors) {
                commonUtil.showShopifyError(response);
                PageLoading.hide();
            } else {
                var firstload = products.length === 0;
                const newPdts = [...products].concat(response.products);
                _this.setState({ products: newPdts, showLoadBtn: totalCount > newPdts.length });
                _this.next_page_info = response.next_page_info;
                if (firstload) {
                    _this.form.current.setFieldsValue({
                        product: newPdts.length > 0 ? newPdts[0].id : null
                    });
                    _this.loadData();
                } else {
                    PageLoading.hide();
                }
            }
        });
    }

    handleLoadMore = () => {
        this.load_products();
    }

    handleValuesChange = (values) => {
        if (values.site) {
            this.next_page_info = null;
            this.setState({
                products: [],
                totalCount: 0,
                showLoadBtn: true,
                dataSource: null
            }, () => {
                this.form.current.setFieldsValue({
                    product: null
                });
                this.load_count();
            });
        }
        if (values.product) {
            this.loadData();
        }
    }

    loadData = () => {
        PageLoading.show();
        const _this = this;
        const values = this.form.current.getFieldsValue();
        request.get('/Review/GetReviews', {
            params: {
                site_id: values.site,
                product_id: values.product
            }
        }).then(function (response) {
            PageLoading.hide();
            _this.setState({ dataSource: response });
        });
    }

    handleSwitchChange = (checked, record) => {
        const _this = this;
        const { dataSource } = this.state;
        const approve = checked === true ? 1 : 2;
        const newRecord = { ...record, loading: true, approve };
        const idx = dataSource.findIndex(x => x.id === record.id);
        const newSource = [...dataSource];
        newSource[idx] = newRecord;
        this.setState({ dataSource: newSource });
        request.post('/Review/ApproveReview', {
            data: {
                id: record.id,
                approve
            }
        }).then(function (response) {
            const resultSource = [..._this.state.dataSource];
            const resultRecord = { ...record, loading: false };
            if (response && response.success) {
                resultRecord.approve = approve;
                message.success("更改评价状态成功！");
            }
            if (response && response.success === false) {
                message.error("系统异常，更改评价状态失败！");
            }
            resultSource[idx] = resultRecord;
            _this.setState({ dataSource: resultSource });
        });
    }

    render() {
        return (
            <PageContainer className="manage-review-page">
                <Card loading={this.state.loading}>
                    <Form
                        ref={this.form}
                        onValuesChange={this.handleValuesChange}
                    >
                        <Row className="top-select-row" gutter={24}>
                            <Col {...topSiteSelectProps}>
                                <Form.Item name="site" label="平台">
                                    <Select>
                                        {this.state.sites?.map(site => {
                                            return <Option key={site.id} value={site.id}>{site.displayName}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...topFilterSelectProps}>
                                <Form.Item label="商品" style={{ marginBottom: 0 }}>
                                    <Form.Item name="product" style={{ display: 'inline-block', width: 'calc(100% - 100px)' }}>
                                        <Select showSearch filterOption={(input, option) => {
                                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }}>
                                            {this.state.products?.map(pdt => {
                                                return <Option key={pdt.id} value={pdt.id}>{pdt.title}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item style={{ display: 'inline-block' }}>
                                        <span style={{ margin: '0 4px' }}>{`${this.state.products?.length}/${this.state.totalCount}`}</span>
                                        {this.state.showLoadBtn && <Button type="primary" shape="circle" ghost onClick={this.handleLoadMore} icon={<IconFont type="icon-published_with_changes" />}></Button>}
                                    </Form.Item>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Table rowKey='id'
                        columns={this.columns}
                        dataSource={this.state.dataSource}
                        tableLayout='fixed'
                        size="middle"
                        scroll={{ x: 950 }}
                        pagination={{
                            defaultPageSize: this.state.pageSize,
                            showQuickJumper: true,
                            size: 'default'
                        }} />
                </Card>
            </PageContainer>);
    }
}

export default ManageReview;