import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, message, Tabs } from 'antd';
import React from 'react';
import request from '@/utils/request';
import commonConfig from '../../../common/commonConfig';
import { isLoginExpired, setAccountInfo, getAccountInfo } from '../../../utils/authority';
import queryString from 'query-string';
import ProForm, { ProFormText } from '@ant-design/pro-form';
import CryptoJs from 'crypto-js';
import logo from '../../../assets/images/logo.svg';
import wangan from '../../../assets/images/wangan.png';
import './login.less';

const LoginMessage = ({ content }) => (
    <Alert
        style={{
            marginBottom: 16,
        }}
        message={content}
        type="error"
        showIcon
    />
);

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            showError: false,
            loginType: 'account',
            error: ''
        }
    }

    componentDidMount() {
        document.title = "登录";
        if (!!getAccountInfo() && isLoginExpired()) {
            message.warn("登录信息已过期，请重新登录！");
        }
    }

    handleSubmit = (values) => {
        const _this = this;
        this.setState({
            submitting: true,
            showError: false,
            error: ''
        });
        request.post('/Auth/Login', {
            data: {
                name: values.userName,
                password: CryptoJs.HmacSHA256(CryptoJs.MD5(values.password), commonConfig.encryptionKey).toString(CryptoJs.enc.Base64)
            }
        }).then(function (response) {
            _this.setState({
                submitting: false
            });
            if (response && response.success) {
                setAccountInfo(response.result);
                message.success("登录成功！");
                const returnUrl = queryString.parse(_this.props.location.search)?.returnUrl;
                var reg = new RegExp('^(?:[a-z]+:)?//', 'i');
                if (returnUrl && !reg.test(returnUrl)) {
                    _this.props.history.push(returnUrl);
                } else {
                    _this.props.history.push('/home/dashboard');
                }
            }
            if (response && response.success === false) {
                _this.setState({
                    showError: true,
                    error: response.errorMsg
                });
            }
        });
    };

    render() {
        return (
            <div className="login-page">
                <div className="login-header"><div className="login-header-text">{commonConfig.siteName}</div></div>
                <div className="login-content">
                    <div className="login-container">
                        <ProForm
                            initialValues={{
                                autoLogin: false,
                            }}
                            submitter={{
                                searchConfig: {
                                    submitText: '登录',
                                },
                                render: (_, dom) => dom.pop(),
                                submitButtonProps: {
                                    loading: this.state.submitting,
                                    size: 'large',
                                    style: {
                                        width: '100%',
                                        marginTop: '30px'
                                    },
                                },
                            }}
                            onFinish={(values) => {
                                this.handleSubmit(values);
                                return Promise.resolve();
                            }}
                        >
                            <Tabs activeKey={this.state.loginType}>
                                <Tabs.TabPane
                                    key="account"
                                    tab={<span style={{ display: 'flex' }}><img alt="logo" className='tab-logo' src={logo}></img><span>账户密码登录</span></span>}
                                />
                                {/* <Tabs.TabPane
                            key="mobile"
                            tab='手机号登录'
                        /> */}
                            </Tabs>

                            {this.state.showError && this.state.loginType === 'account' && !this.state.submitting && (
                                <LoginMessage
                                    content={this.state.error}
                                />
                            )}
                            {this.state.loginType === 'account' && (
                                <>
                                    <ProFormText
                                        name="userName"
                                        fieldProps={{
                                            size: 'large',
                                            prefix: <UserOutlined className="prefixIcon" />,
                                        }}
                                        placeholder='账号'
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入账号！",
                                            },
                                        ]}
                                    />
                                    <ProFormText.Password
                                        name="password"
                                        fieldProps={{
                                            size: 'large',
                                            prefix: <LockOutlined className="prefixIcon" />,
                                        }}
                                        placeholder='密码'
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入密码！",
                                            },
                                        ]}
                                    />
                                </>
                            )}
                        </ProForm>
                    </div>
                </div>
                <div className="login-footer">
                    <a style={{ display: 'flex', alignItems: 'center' }}
                        target="_blank"
                        rel='noreferrer noopener'
                        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030702004724">
                        <img alt="wang an" src={wangan} />
                        <p>粤公网安备 44030702004724号</p>
                    </a>
                    <a style={{ marginLeft: '20px' }}
                        target="_blank"
                        rel='noreferrer noopener'
                        href="https://beian.miit.gov.cn/"><p>粤ICP备2022042384号-1</p></a>
                </div>
            </div>
        );
    }
};

export default Login;
