import { Spin } from 'antd';
import React from 'react';
import request from '@/utils/request';
import queryString from 'query-string';
import commonConfig from '@common/commonConfig';
import { setAccountInfo } from '../../../utils/authority';

class AutoLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        document.title = commonConfig.navHeader;
        const _this = this;
        const { history, location } = this.props;
        const urlParam = queryString.parse(location.search);
        const unAuthUrl = `/auth/login?returnUrl=${encodeURIComponent(location.pathname + location.search)}`;
        if (urlParam.sid !== undefined) {
            request.post('/Auth/AutoLogon', {
                data: { Sid: urlParam.sid }
            }).then(function (response) {
                _this.setState({
                    loading: false
                });
                if (response && response.success) {
                    setAccountInfo(response.result);
                    const returnUrl = urlParam.returnUrl;
                    var reg = new RegExp('^(?:[a-z]+:)?//', 'i');
                    if (returnUrl && !reg.test(returnUrl)) {
                        history.push(returnUrl);
                    } else {
                        history.push('/home/dashboard');
                    }
                }
                if (response && response.success === false) {
                    history.push(unAuthUrl);
                }
            });
        } else {
            history.push(unAuthUrl);
        }
    }

    render() {
        return (
            <div style={{ padding: 60, textAlign: 'center' }}>
                <Spin size="large" spinning={this.state.loading}></Spin>
            </div>
        );
    }
};

export default AutoLogin;
