import React from "react";
import request from "@utils/request";
import { Modal, Spin, Alert, Input, Button, Form, message } from 'antd';
import IconFont from '@components/Common/IconFont';
import './AnswerQuestion.less';

const { TextArea } = Input;

class AnswerQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            errorMsg: null,
            fileList: null
        }
    }

    form = React.createRef();

    handleOk = () => {
        this.form.current.validateFields().then(values => {
            const { record } = this.props;
            const _this = this;
            this.setState({
                loading: true,
                hasError: false,
            });
            const controller = new AbortController();
            const { signal } = controller;
            this.controller = controller;
            const newRecord = {
                ...record,
                ...values
            };
            request.post('/Review/AnswerQuestion', {
                signal,
                data: newRecord
            }).then(function (response) {
                _this.setState({ loading: false });
                if (response && response.success) {
                    _this.setState({ visible: false });
                    message.success("回答提问成功！");
                    if (_this.props.afterUpdate) {
                        _this.props.afterUpdate(newRecord);
                    }
                }
                if (response && response.success === false) {
                    _this.setState({ hasError: true });
                }
            })
        }).catch(errorInfo => { });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleOpen = () => {
        if (this.controller) {
            this.controller.abort();
        }
        this.setState({
            visible: true,
            errorMsg: null
        }, () => {
            this.form.current.resetFields();
        });
    }

    render() {
        const { answerButtonProps = {
            type: "primary",
            shape: "circle",
            icon: <IconFont type="icon-question-answer-line" />
        }, record } = this.props;
        return (
            <>
                <Button {...answerButtonProps} onClick={this.handleOpen} />
                <Modal title="回答问题"
                    okButtonProps={{ loading: this.state.loading }}
                    wrapClassName="answer-question-modal"
                    maskClosable={false}
                    width={800}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Spin spinning={this.state.loading}>
                        {!!this.state.errorMsg && <Alert message={this.state.errorMsg} style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                        <Form ref={this.form} labelAlign="left" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} initialValues={{
                            answer: record?.answer
                        }}>
                            <Form.Item label="提问">
                                <div>{record?.question}</div>
                            </Form.Item>
                            <Form.Item label="回答" name="answer" rules={[{ required: true }]}>
                                <TextArea rows={4} />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        );
    }
};

export default AnswerQuestion;