import React from "react";
import request from "@utils/request";
import IconFont from '@components/Common/IconFont';
import { Modal, Spin, Input, Alert, Button, Tooltip, Form } from 'antd';

const { TextArea } = Input;

class EditComment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            hasError: false
        }
    }

    form = React.createRef();

    handleOk = () => {
        const controller = new AbortController();
        const { signal } = controller;
        this.controller = controller;
        this.setState({ loading: true, hasError: false });
        const _this = this;
        request.post('/Order/SaveInternalComment', {
            signal,
            data: {
                id: this.props.record.parentId,
                internalComment: this.form.current.getFieldsValue().internalComment
            }
        }).then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                _this.setState({ visible: false });
                if (_this.props.afterUpdate) {
                    _this.props.afterUpdate({
                        id: _this.props.record.id,
                        parentId: _this.props.record.parentId,
                        internalComment: _this.form.current.getFieldsValue().internalComment
                    });
                }
            }
            if (response && response.success === false) {
                _this.setState({ hasError: true });
            }
        });
    };

    handleOpen = () => {
        if (this.controller) {
            this.controller.abort();
        }
        this.setState({ visible: true, hasError: false }, () => {
            this.form.current.setFieldsValue({
                internalComment: this.props.record.internalComment
            });
        });
    }

    handleCancel = () => {
        this.setState({ visible: false });
    };

    render() {
        return (
            <>
                <Tooltip title="备注">
                    <Button type="primary" onClick={this.handleOpen} shape="circle" icon={<IconFont type="icon-comment" />} />
                </Tooltip>
                <Modal title="编辑备注"
                    okButtonProps={{ loading: this.state.loading }}
                    maskClosable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Spin spinning={this.state.loading}>
                        {this.state.hasError && <Alert message="编辑备注失败！" style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                        <Form ref={this.form}>
                            <Form.Item name="internalComment">
                                <TextArea rows={4} />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        );
    }
};

export default EditComment;