import React from 'react';
import { DashboardOutlined, BarChartOutlined } from '@ant-design/icons';
const routerConfig = {
    route:
    {
        path: '/',
        redirect: '/home/dashboard',
        routes: [
            {
                name: '首页',
                icon: <DashboardOutlined />,
                path: '/home/dashboard',
                component: '/home/dashboard/dashboard',
                authority: ['OrderMgr', 'Packer', 'Admin'],
            },
            {
                path: '/order',
                name: '发货物流',
                redirect: '/order/prepare',
                icon: 'icon-shipping-fast',
                routes: [
                    {
                        name: '制作商品',
                        path: '/order/prepare',
                        component: '/order/prepare/prepare',
                        authority: ['OrderMgr'],
                    },
                    {
                        name: '创建物流',
                        path: '/order/create-logistics',
                        component: '/order/create-logistics/create-logistics',
                        authority: ['OrderMgr'],
                    },
                    {
                        name: '预报订单',
                        path: '/order/declare',
                        component: '/order/declare/declare',
                        authority: ['OrderMgr'],
                    },
                    {
                        name: '打包发货',
                        path: '/order/package',
                        component: '/order/package/package',
                        authority: ['OrderMgr', 'Packer'],
                    },
                    {
                        name: '订单管理',
                        path: '/order/manage',
                        component: '/order/manage/manage',
                        authority: ['OrderMgr'],
                    },
                    // {
                    //     name: '可到达地',
                    //     path: '/order/accessible-place',
                    //     component: '/order/accessible-place/accessible-place',
                    // },
                ],
            },
            {
                path: '/logistics',
                name: '物流时效',
                icon: 'icon-timeline',
                redirect: '/logistics/track',
                routes: [
                    {
                        name: '物流轨迹',
                        path: '/logistics/track',
                        component: '/logistics/track/track',
                        authority: ['OrderMgr'],
                    },
                    {
                        name: '物流时效',
                        path: '/logistics/timeliness',
                        component: '/logistics/timeliness/timeliness',
                        authority: ['OrderMgr'],
                    },
                    {
                        name: '地区时效',
                        path: '/logistics/country-timeliness',
                        component: '/logistics/country-timeliness/country-timeliness',
                        authority: ['OrderMgr'],
                    },
                    {
                        name: '在途时效',
                        path: '/logistics/transit-timeliness',
                        component: '/logistics/transit-timeliness/transit-timeliness',
                        authority: ['OrderMgr'],
                    }
                ],
            },
            {
                path: '/platform',
                name: '网站应用',
                icon: 'icon-app',
                redirect: '/platform/timeline',
                routes: [
                    {
                        name: '时间线',
                        path: '/platform/timeline',
                        component: '/platform/platform-timeline/platform-timeline',
                        authority: ['OrderMgr'],
                    },
                    {
                        name: '评价复制',
                        path: '/platform/replicate-review',
                        component: '/platform/replicate-review/replicate-review',
                        authority: ['OrderMgr'],
                    },
                    {
                        name: '评价管理',
                        path: '/platform/manage-review',
                        component: '/platform/manage-review/manage-review',
                        authority: ['OrderMgr'],
                    },
                    {
                        name: '提问管理',
                        path: '/platform/manage-question',
                        component: '/platform/manage-question/manage-question',
                        authority: ['OrderMgr'],
                    },
                    {
                        name: '价格管理',
                        path: '/platform/manage-price',
                        component: '/platform/manage-price/manage-price',
                        authority: ['OrderMgr']
                    },
                ],
            },
            {
                path: '/statistics',
                name: '网站统计',
                icon: <BarChartOutlined />,
                redirect: '/statistics/sales',
                routes: [
                    {
                        name: '销量统计',
                        path: '/statistics/sales',
                        component: '/statistics/statistics-sales/statistics-sales',
                        authority: ['OrderMgr'],
                    },
                    {
                        name: '状态统计',
                        path: '/statistics/action',
                        component: '/statistics/statistics-action/statistics-action',
                        authority: ['OrderMgr'],
                    },
                ],
            },
            {
                path: '/product',
                name: '商品管理',
                icon: 'icon-product',
                redirect: '/product/vendor-product',
                routes: [
                    {
                        name: '卖家产品',
                        path: '/product/vendor-product',
                        component: '/product/vendor-product/vendor-product',
                        authority: ['OrderMgr'],
                    },
                    {
                        name: '匹配设置',
                        path: '/product/product-mapping',
                        component: '/product/product-mapping/product-mapping',
                        authority: ['OrderMgr'],
                    },
                    {
                        name: '款式信息',
                        path: '/product/variant-info',
                        component: '/product/variant-info/variant-info',
                        authority: ['OrderMgr'],
                    },
                    {
                        name: '款式设置',
                        path: '/product/variant-mapping',
                        component: '/product/variant-mapping/variant-mapping',
                        authority: ['OrderMgr'],
                    },
                ],
            },
            {
                path: '/stock',
                name: '库存管理',
                icon: 'icon-inventory',
                redirect: '/stock/manage',
                routes: [
                    {
                        name: '产品库存',
                        path: '/stock/manage',
                        component: '/stock/stock-manage/stock-manage',
                        authority: ['OrderMgr', 'Packer'],
                    },
                    {
                        name: '库存匹配',
                        path: '/stock/mapping',
                        component: '/stock/stock-mapping/stock-mapping',
                        authority: ['OrderMgr', 'Packer'],
                    },
                    {
                        name: '库存详情',
                        path: '/stock/detail',
                        component: '/stock/stock-detail/stock-detail',
                        authority: ['OrderMgr', 'Packer'],
                    },
                    {
                        name: '进货签收',
                        path: '/stock/sign-express',
                        component: '/stock/stock-express/stock-express',
                        authority: ['OrderMgr', 'Packer'],
                    },
                ],
            },
            {
                path: '/spare',
                name: '备品管理',
                icon: 'icon-materialmanagement',
                redirect: '/spare/manage',
                routes: [
                    {
                        name: '备品库存',
                        path: '/spare/manage',
                        component: '/spare/spare-manage/spare-manage',
                        authority: ['OrderMgr', 'Packer'],
                    },
                    {
                        name: '备品详情',
                        path: '/spare/detail',
                        component: '/spare/spare-detail/spare-detail',
                        authority: ['OrderMgr', 'Packer'],
                    },
                    {
                        name: '备品签收',
                        path: '/spare/sign-express',
                        component: '/spare/spare-express/spare-express',
                        authority: ['OrderMgr', 'Packer'],
                    },
                ],
            },
            {
                path: '/settings',
                name: '设置',
                redirect: '/settings/system',
                hideInMenu: true,
                routes: [
                    {
                        name: '系统设置',
                        path: '/settings/system',
                        component: '/settings/common-setting/common-setting',
                        authority: ['Admin'],
                    },
                    {
                        name: '店铺设置',
                        path: '/settings/site',
                        component: '/settings/site-setting/site-setting',
                        authority: ['Admin'],
                    },
                    {
                        name: '物流设置',
                        path: '/settings/logistics',
                        component: '/settings/transport-way/transport-way',
                        authority: ['Admin'],
                    },
                    {
                        name: 'Timer设置',
                        path: '/settings/timer',
                        component: '/settings/timer-setting/timer-setting',
                        authority: ['Admin'],
                    },
                    {
                        name: '用户管理',
                        path: '/settings/user',
                        component: '/settings/user/user',
                        authority: ['Admin'],
                    }
                ],
            },
            {
                path: '/tool',
                name: '工具',
                redirect: '/tool/admin-tool',
                icon: 'icon-tools-line',
                routes: [
                    {
                        name: '系统工具',
                        path: '/tool/system-tool',
                        component: '/tool/system-tool/system-tool',
                    },
                    {
                        name: '管理工具',
                        path: '/tool/admin-tool',
                        component: '/tool/admin-tool/admin-tool',
                        authority: ['Admin'],
                    },
                    {
                        name: '微信工具',
                        path: '/tool/wx-tool',
                        component: '/tool/wx-tool/wx-tool',
                        authority: ['Admin'],
                    },
                    {
                        name: '系统概况',
                        path: '/home/system-info',
                        component: '/home/system-info/system-info',
                        authority: ['Admin'],
                    },
                    {
                        name: '代理状态',
                        path: '/home/agent-info',
                        component: '/home/agent-info/agent-info',
                        authority: ['Admin'],
                    }
                ],
            }
        ],
    }
};

export default routerConfig;