import React from "react";
import { Tabs, Card, Popconfirm, Modal, Spin, Form, Alert, Input, Select, Table, Button, message, Space, Tooltip } from 'antd';
import { CloseOutlined, MenuOutlined, PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { PageContainer } from "@ant-design/pro-layout";
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import request from "@utils/request";
import withBreakpoint from '@components/Common/withBreakpoint';
import PageLoading from '@components/Common/PageLoading/PageLoading';
import { arrayMoveImmutable } from 'array-move';
import commonUtil from "@utils/commonUtil";
import './transport-way.less';

const { TabPane } = Tabs;
const { Option } = Select;

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

class TransportWaySetting extends React.Component {
    state = {
        loading: false,
        expressLoading: false,
        activeKey: null,
        settings: [],
        countryError: null,
        wayError: null,
        visible: false,
        wayVisible: false,
        expressTypes: [],
        ways: [],
        wayEditVisible: false,
        editLoading: false
    };
    allWays = {};
    form = React.createRef();
    wayForm = React.createRef();
    wayEditForm = React.createRef();

    columns = [
        {
            title: '排序',
            key: 'sort',
            dataIndex: 'sort',
            width: '60px',
            className: 'drag-visible sort-col',
            render: () => <DragHandle />,
        },
        {
            title: '物流',
            key: 'expressType',
            dataIndex: 'expressTypeString',
            width: '18%',
            className: 'drag-visible express-type-col',
        },
        {
            title: '渠道',
            key: 'wayName',
            width: '32%',
            dataIndex: 'wayName',
            className: 'drag-visible transport-way-col',
        },
        {
            title: '末端派送商',
            key: 'carrierEnName',
            width: '19%',
            className: 'drag-visible end-delivery-col',
            dataIndex: 'carrierEnName'
        },
        {
            title: '派送商网址',
            key: 'carrierWebsite',
            width: '31%',
            className: 'drag-visible end-web-col',
            dataIndex: 'carrierWebsite'
        },
        {
            title: '操作',
            key: 'action',
            width: '110px',
            className: 'drag-visible action-col',
            render: record => <Space><Tooltip title="编辑"><Button type="link" onClick={() => this.handleEditWay(record)} icon={<EditOutlined />}></Button></Tooltip><Tooltip title="删除"><Button type="link" onClick={() => this.handleRemoveWay(record)} icon={<DeleteOutlined />}></Button></Tooltip></Space>
        }
    ];

    onSortEnd = ({ oldIndex, newIndex }) => {
        const { settings, activeKey } = this.state;
        const newSettings = [...settings];
        const currentTab = newSettings.find(s => s.countryCode === activeKey);
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(currentTab.wayInfos), oldIndex, newIndex).filter(el => !!el);
            currentTab.wayInfos = newData;
            this.setState({ settings: newSettings });
        }
    };

    DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="transport-way-row-dragging"
            onSortEnd={this.onSortEnd}
            {...props}
        />
    );

    DraggableBodyRow = ({ className, style, ...restProps }, dataSource) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex(x => x.wayCode === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });

        Promise.all([request.get('/Setting/GetLogisticsSettingConfig'), request.get('/Express/GetExpressVendersList')]).then((responses) => {
            _this.setState({ loading: false });
            const response = responses[0];
            const expressResponse = responses[1];
            if (response && response.success) {
                const infos = response.result?.customConfig?.logisticsSettingInfos;
                _this.currentSetting = response.result || {};
                _this.setState({
                    settings: infos?.length > 0 ? infos : [],
                    activeKey: infos?.length > 0 ? infos[0].countryCode : null
                });
            }
            if (expressResponse && expressResponse.success) {
                _this.setState({
                    expressTypes: expressResponse.results
                });
            }
        })
    }

    onChange = activeKey => {
        this.setState({ activeKey });
    };

    onEdit = (targetKey, action) => {
        if (action === 'remove') {
            return;
        }
        this[action](targetKey);
    };

    add = () => {
        this.setState({ visible: true, countryError: null }, () => {
            this.form.current.setFieldsValue({
                countryCnName: '',
                countryCode: ''
            });
        });
    };

    handleCountryOk = () => {
        this.setState({ countryError: null });
        this.form.current.validateFields().then(values => {
            const { settings } = this.state;
            const isExist = settings.some(s => s.countryCnName.toLowerCase() === values.countryCnName.toLowerCase()
                || s.countryCode.toLowerCase() === values.countryCode.toLowerCase());
            if (isExist) {
                this.setState({ countryError: "此国家地区名称或简码已存在！" });
                return;
            }
            const activeKey = values.countryCode;
            const newPanes = [...settings];
            newPanes.push({ countryCnName: values.countryCnName, wayInfos: [], countryCode: activeKey });
            this.setState({
                settings: newPanes,
                activeKey,
                visible: false
            });
        }).catch(errorInfo => { });
    }

    handleCountryCancel = () => {
        this.setState({ visible: false });
    }

    handleConfirm = (e, targetKey) => {
        e.stopPropagation();
        this.remove(targetKey);
    }

    handleCancel = (e) => {
        e.stopPropagation();
    }

    remove = targetKey => {
        const { settings, activeKey } = this.state;
        let newActiveKey = activeKey;
        let lastIndex;
        settings.forEach((setting, i) => {
            if (setting.countryCode === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = settings.filter(setting => setting.countryCode !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].countryCode;
            } else {
                newActiveKey = newPanes[0].countryCode;
            }
        }
        this.setState({
            settings: newPanes,
            activeKey: newActiveKey,
        });
    };

    handleNewTransportWay = () => {
        this.setState({
            wayVisible: true,
            wayError: null,
            ways: null
        }, () => {
            this.wayForm.current.setFieldsValue({
                expressType: null,
                transportWay: null,
                carrierEnName: null,
                carrierWebsite: null
            });
        });
    }

    handleWayOk = () => {
        this.wayForm.current.validateFields().then(values => {
            const { settings, activeKey } = this.state;
            const newSettings = [...settings];
            const currentTab = newSettings.find(s => s.countryCode === activeKey);
            const isExist = currentTab.wayInfos.some(x => x.wayCode === values.transportWay.value);
            if (isExist) {
                this.setState({ wayError: '此物流渠道已存在！' });
            } else {
                currentTab.wayInfos = [...currentTab.wayInfos, {
                    expressType: values.expressType.value,
                    expressTypeString: values.expressType.label,
                    wayName: values.transportWay.label,
                    wayCode: values.transportWay.value,
                    carrierEnName: values.carrierEnName,
                    carrierWebsite: values.carrierWebsite
                }];
                this.setState({ settings: newSettings, wayVisible: false });
            }

        }).catch(errorInfo => { });
    }

    handleWayCancel = () => {
        this.setState({ wayVisible: false });
    }

    handleEditWay = (record) => {
        this.setState({
            editVisible: true,
            currentRecord: record
        }, () => {
            this.wayEditForm.current.setFieldsValue({
                carrierEnName: record.carrierEnName,
                carrierWebsite: record.carrierWebsite
            });
        });
    }

    handleRemoveWay = (record) => {
        const { settings, activeKey } = this.state;
        const newSettings = [...settings];
        const currentTab = newSettings.find(s => s.countryCode === activeKey);
        const index = currentTab.wayInfos.findIndex(x => x.wayCode === record.wayCode);
        if (index > -1) {
            const newWays = [...currentTab.wayInfos];
            newWays.splice(index, 1);
            currentTab.wayInfos = newWays;
            this.setState({ settings: newSettings });
        }
    }

    handleExpressTypeChange = (value) => {
        const _this = this;
        _this.wayForm.current.setFieldsValue({
            transportWay: null
        });
        const getExpressWays = new Promise((resolve, reject) => {
            if (_this.allWays[value.value]) {
                resolve();
            }
            else {
                _this.setState({ expressLoading: true });
                request.get('/Express/GetTransportWays', { params: { expressType: value.value } }).then(function (response) {
                    _this.setState({ expressLoading: false });
                    if (response && response.success) {
                        _this.allWays[value.value] = response.results;
                    }
                    resolve();
                });
            }
        });

        getExpressWays.then(() => {
            const ways = commonUtil.filterWaysByCountry(_this.allWays[value.value], this.state.activeKey);
            _this.setState({
                ways: ways
            });
        })
    }

    handleSave = () => {
        PageLoading.show();
        request.post('/Setting/SaveLogisticsSettingConfig', {
            data: { ...this.currentSetting, customConfig: { logisticsSettingInfos: this.state.settings } }
        }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                message.success("保存物流渠道设置成功！");
            } else {
                message.error("保存物流渠道设置失败！");
            }
        });
    }

    handleSearch = (input, option) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }

    handleEditOk = () => {
        this.wayEditForm.current.validateFields().then(values => {
            const { settings, activeKey } = this.state;
            const newSettings = [...settings];
            const currentTab = newSettings.find(s => s.countryCode === activeKey);
            const index = currentTab.wayInfos.findIndex(x => x.wayCode === this.state.currentRecord.wayCode);
            if (index > -1) {
                let newWays = [...currentTab.wayInfos];
                newWays[index] = {
                    ...newWays[index],
                    carrierEnName: values.carrierEnName,
                    carrierWebsite: values.carrierWebsite
                };
                currentTab.wayInfos = newWays;
                this.setState({ settings: newSettings, editVisible: false });
            }
        }).catch(errorInfo => { });
    }

    handleEditCancel = () => {
        this.setState({ editVisible: false });
    }

    render() {
        const { settings, activeKey, currentRecord = {} } = this.state;
        const { breakpoint } = this.props;
        return (
            <PageContainer className="transport-way-setting-page">
                <Card loading={this.state.loading}>
                    <Tabs
                        type="editable-card"
                        tabPosition={!breakpoint.md ? "top" : "left"}
                        onChange={this.onChange}
                        activeKey={activeKey}
                        onEdit={this.onEdit}
                    >
                        {settings.map(pane => (
                            <TabPane tab={`${pane.countryCnName} (${pane.countryCode})`}
                                key={pane.countryCode}
                                closable={true}
                                style={{ minHeight: '400px' }}
                                closeIcon={
                                    <Popconfirm
                                        title="确定要删除此国家地区么？"
                                        onConfirm={evt => this.handleConfirm(evt, pane.countryCode)}
                                        onCancel={this.handleCancel}
                                    >
                                        <CloseOutlined />
                                    </Popconfirm>
                                }>
                                <Button style={{ float: 'right', marginBottom: '12px' }} onClick={this.handleNewTransportWay} type="primary" icon={<PlusOutlined />}>添加</Button>
                                <Table
                                    pagination={false}
                                    dataSource={pane.wayInfos}
                                    tableLayout="fixed"
                                    columns={this.columns}
                                    rowKey="wayCode"
                                    components={{
                                        body: {
                                            wrapper: this.DraggableContainer,
                                            row: (prpt) => this.DraggableBodyRow(prpt, pane.wayInfos),
                                        },
                                    }}
                                />
                            </TabPane>
                        ))}
                    </Tabs>
                    <Button style={{ float: 'right', marginTop: '12px' }} type="primary" onClick={this.handleSave}>全部保存</Button>
                </Card>
                <Modal title="新建国家地区"
                    className="transport-way-country-modal"
                    maskClosable={false}
                    visible={this.state.visible}
                    onOk={this.handleCountryOk}
                    onCancel={this.handleCountryCancel}
                >
                    <div>
                        {!!this.state.countryError && <Alert message={this.state.countryError} style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                        <Form ref={this.form}>
                            <Form.Item name="countryCnName" label="国家地区" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item name="countryCode" label="国际简码" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
                <Modal title="添加物流渠道"
                    className="transport-way-setting-modal"
                    maskClosable={false}
                    visible={this.state.wayVisible}
                    onOk={this.handleWayOk}
                    onCancel={this.handleWayCancel}
                >
                    <Spin spinning={this.state.expressLoading}>
                        {!!this.state.wayError && <Alert message={this.state.wayError} style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                        <Form ref={this.wayForm} labelAlign="left" wrapperCol={{ span: 19 }} labelCol={{ span: 5 }}>
                            <Form.Item name="expressType" label="物流" rules={[{ required: true }]}>
                                <Select labelInValue onChange={this.handleExpressTypeChange}>
                                    {this.state.expressTypes?.map(et => <Option key={et.expressType} value={et.expressType}>{et.displayName}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item name="transportWay" label="渠道" rules={[{ required: true }]}>
                                <Select labelInValue showSearch
                                    filterOption={this.handleSearch}>
                                    {this.state.ways?.map(way => <Option key={way.code} value={way.code}>{way.displayName}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item name="carrierEnName" label="末端派送商" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item name="carrierWebsite" label="派送商网址" rules={[{ type: 'url' }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
                <Modal title="编辑物流渠道"
                    className="transport-way-edit-modal"
                    maskClosable={false}
                    visible={this.state.editVisible}
                    onOk={this.handleEditOk}
                    onCancel={this.handleEditCancel}
                >
                    <Spin spinning={this.state.editLoading}>
                        <Form ref={this.wayEditForm} labelAlign="left" wrapperCol={{ span: 19 }} labelCol={{ span: 5 }}>
                            <Form.Item label="物流">
                                <div>{currentRecord.expressTypeString}</div>
                            </Form.Item>
                            <Form.Item label="渠道">
                                <div>{currentRecord.wayName}</div>
                            </Form.Item>
                            <Form.Item name="carrierEnName" label="末端派送商" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item name="carrierWebsite" label="派送商网址" rules={[{ type: 'url' }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </PageContainer>
        );
    }
}

export default withBreakpoint(TransportWaySetting);