import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import { Card, Select } from "antd";
import ReactEcharts from 'echarts-for-react';
import request from "@utils/request";
import PageLoading from '@components/Common/PageLoading/PageLoading';

const { Option } = Select;

class LogisticsTimeliness extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            logisticsVendors: [],
            value: null,
            data: null
        }
    }

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/Express/GetExpressVendersList').then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                const value = response.results?.length > 0 ? response.results[0].expressType : null;
                _this.setState({ logisticsVendors: response.results, value }, _this.loadCharts);
            }
        });
    }

    loadCharts = () => {
        const _this = this;
        PageLoading.show();
        request.get('/Track/GetTimelinessInfos', { params: { expressType: this.state.value } }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.setState({ data: response.results });
            }
        });
    }

    handleChange = value => {
        this.setState({ value }, this.loadCharts);
    }

    render() {
        return (<PageContainer>
            <Card loading={this.state.loading}>
                <Select style={{ width: 180 }} value={this.state.value}
                    onChange={this.handleChange}>
                    {this.state.logisticsVendors?.map(l => <Option key={l.expressType} value={l.expressType}>{l.displayName}</Option>)}
                </Select>
                {this.state.data?.map(d => {
                    const option = {
                        title: {
                            text: d.transportWay,
                            subtext: "交付时效",
                            subtextStyle: {
                                color: '#4caf50',
                                fontSize: 14
                            }
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            },
                            formatter(params) {
                                var result = '<table><tbody>';
                                var newParams = params.filter(p => p.data.trackingCount !== 0);
                                if (newParams?.length <= 0) {
                                    return '';
                                }
                                newParams.forEach(function (item) {
                                    result += '<tr><td>' + item.marker + item.seriesName + "</td><td style='padding-left:5px'>平均天数: " + item.data.aveValue + "</td><td  style='padding-left:5px'>最长天数: " + item.data.maxValue + "</td><td style='padding-left:5px'>最短天数: " + item.data.minValue + "</td><td style='padding-left:5px'>统计单数: " + item.data.trackingCount + "</td></tr>";
                                })
                                result += '</tbody></table>';
                                return result;
                            }
                        },
                        grid: {
                            bottom: '5px',
                            containLabel: true
                        },
                        legend: {
                            width: '60%',
                            right: '10%',
                            data: d.deliverItems?.map(item => item.name),
                        },
                        xAxis: {
                            type: 'category',
                            data: d.deliverItems?.length > 0 ? d.deliverItems[0].details.map(detail => detail.time) : [],
                        },
                        yAxis: {
                            type: 'value',
                            minInterval: 1
                        },
                        series: d.deliverItems?.map(item => {
                            return {
                                name: item.name,
                                type: 'bar',
                                data: item?.details.map(detail => {
                                    return { ...detail, value: detail.aveValue };
                                })
                            };
                        })
                    };
                    return (<ReactEcharts key={d.transportWay} style={{ height: 300, marginTop: 24 }} option={option}></ReactEcharts>)
                })}
            </Card>
        </PageContainer>);
    }
}

export default LogisticsTimeliness;