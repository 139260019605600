import React from "react";
import request from "@utils/request";
import { Modal, Spin, Alert, Select, Button, Form, message } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

const { Option } = Select;

class EditProductMapping extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            hasError: false
        }
    }

    form = React.createRef();

    handleOk = () => {
        this.form.current.validateFields().then(values => {
            const { record } = this.props;
            const controller = new AbortController();
            const { signal } = controller;
            this.controller = controller;
            this.setState({ loading: true, hasError: false });
            const _this = this;
            const newRecord = {
                ...record,
                mappingId: values.mapping.value,
                productName: values.mapping.label
            }
            request.post('/Product/SetProductMapping', {
                signal,
                data: newRecord
            }).then(function (response) {
                _this.setState({ loading: false });
                if (response && response.success) {
                    _this.setState({ visible: false });
                    message.success(`${record.title}商品匹配设置成功！`);
                    if (_this.props.afterUpdate) {
                        _this.props.afterUpdate(newRecord);
                    }
                }
                if (response && response.success === false) {
                    _this.setState({ hasError: true });
                }
            });
        }).catch(errorInfo => { });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleOpen = () => {
        if (this.controller) {
            this.controller.abort();
        }
        this.setState({ visible: true, hasError: false }, () => {
            this.form.current.resetFields();
        });
    }

    render() {
        const { record, products } = this.props;
        return (
            <>
                <Button type="primary" onClick={this.handleOpen} shape="round" icon={<SettingOutlined />} >设置</Button>
                <Modal title="商品匹配设置"
                    okButtonProps={{ loading: this.state.loading }}
                    maskClosable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Spin spinning={this.state.loading}>
                        {this.state.hasError && <Alert message="商品匹配设置失败！" style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                        <Form ref={this.form} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} initialValues={{
                            mapping: {
                                label: record.productName,
                                value: record.mappingId
                            }
                        }}>
                            <Form.Item label="商品名称">
                                <div>{record.title}</div>
                            </Form.Item>
                            <Form.Item label="销售平台">
                                <div>{record.siteDisplayName}</div>
                            </Form.Item>
                            <Form.Item name="mapping" label="对应产品" rules={[{ required: true }]}>
                                <Select showSearch labelInValue filterOption={(input, option) => {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }}>
                                    {products?.map(pdt => <Option key={pdt.id} value={pdt.id}>{pdt.productName}</Option>)}
                                </Select>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        );
    }
};

export default EditProductMapping;