import React from 'react';
import { Space, Button, Skeleton, Typography } from "antd";
import classNames from 'classnames';
import './DateTrackSkeleton.less';

const { Text } = Typography;

const DateTrackSkeleton = (props) => {
    const { loading = false, showMore = true, onMoreClick, showDate = true } = props;
    return (<div className='date-track-skeleton-container'>
        {loading && <Space size={0} direction="vertical" style={{ width: '100%' }}>
            <div className='date-track-skeleton'>
                {showDate && <Skeleton.Avatar style={{ borderRadius: 3 }} size={64} active={true} shape="square" />}
                <Skeleton.Input className={classNames({
                    "date-track-skeleton-input": true,
                    "with-date": showDate
                })} size="large" active={true} />
            </div>
            <div className='date-track-skeleton'>
                {showDate && <Skeleton.Avatar style={{ borderRadius: 3 }} size={64} active={true} shape="square" />}
                <Skeleton.Input className={classNames({
                    "date-track-skeleton-input": true,
                    "with-date": showDate
                })} size="large" active={true} />
            </div>
            <div className='date-track-skeleton'>
                {showDate && <Skeleton.Avatar style={{ borderRadius: 3 }} size={64} active={true} shape="square" />}
                <Skeleton.Input className={classNames({
                    "date-track-skeleton-input": true,
                    "with-date": showDate
                })} size="large" active={true} />
            </div>
        </Space>}
        <div style={{ textAlign: 'center', marginTop: '12px' }}>
            {showMore && <Button type="primary" onClick={onMoreClick} loading={loading} size="small" shape="round" ghost >
                加载更多
            </Button>}
            {!showMore && <Text type="secondary">没有更多数据了~</Text>}
        </div>
    </div>);
}

export default DateTrackSkeleton;