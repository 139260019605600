import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import PageLoading from '@components/Common/PageLoading/PageLoading';
import commonConfig from '@common/commonConfig';
import request from "@utils/request";
import { Card, Table, Form, Input, Select, Typography, Row, Col, Tooltip, Image } from "antd";
import EditProductMapping from "@components/Product/EditProductMapping/EditProductMapping";
import FallbackImage from '@common/CommonImage';
import './product-mapping.less';

const { Option } = Select;
const { Search } = Input;
const { Text } = Typography;

const topSiteSelectProps = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 8,
};

const topSearchSelectProps = {
    xs: 24,
    sm: 12,
    md: { span: 8, push: 4 },
    lg: { span: 8, push: 4 },
    xl: { span: 8, push: 8 },
};

class ProductProductMapping extends React.Component {
    state = {
        loading: false,
        pageNumber: 1,
        pageSize: commonConfig.pageSize,
        dataSource: null,
        totalCount: 0
    }

    form = React.createRef();
    search = React.createRef();

    columns = [
        {
            title: '',
            key: 'thumbnail',
            dataIndex: 'thumbnail',
            render: (thumbnail) => (
                <Image className="custom-image" src={thumbnail} fallback={FallbackImage} preview={false} />
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.thumbnail !== nextRecord.thumbnail,
            width: 12
        },
        {
            title: '商品名称',
            key: 'title',
            dataIndex: 'title',
            render: (title, record) => (<div>
                <Text>{title}</Text>
                <div>
                    <Text type="secondary">Product Id: {record.productId}</Text>
                </div>
            </div>),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.title !== nextRecord.title
                || prevRecord.productId !== nextRecord.productId,
            width: 36
        },
        {
            title: '销售平台',
            key: 'siteDisplayName',
            dataIndex: 'siteDisplayName',
            width: 17
        },
        {
            title: '对应产品',
            key: 'productName',
            dataIndex: 'productName',
            render: (pdtName) => (<Text type={pdtName === "未设置" ? 'danger' : ''}>{pdtName}</Text>),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.productName !== nextRecord.productName,
            width: 17
        },
        {
            title: '操作',
            key: 'action',
            render: (record) => (
                <EditProductMapping record={record} products={this.state.products} afterUpdate={(newPdt) => {
                    const newSource = [...this.state.dataSource];
                    const idx = newSource.findIndex(x => x.id === record.id);
                    newSource[idx] = { ...record, ...newPdt };
                    this.setState({ dataSource: newSource });
                }} />
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord !== nextRecord
                || prevRecord.mappingId !== nextRecord.mappingId,
            width: 15
        },
    ];

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        Promise.all([request.get('/Site/GetSimpleSites', {
            params: {
                includeAll: true
            }
        }), request.get('/Product/GetAllVendorProducts')]).then(function (responses) {
            _this.setState({ loading: false });
            const response = responses[0];
            const pdtResponse = responses[1];
            if (response && response.success) {
                _this.setState({
                    sites: response.results
                }, () => {
                    _this.form.current.setFieldsValue({
                        site: response.results?.length > 0 ? response.results[0].id : null
                    });
                    _this.loadData();
                });
            }
            if (pdtResponse && pdtResponse.success) {
                _this.setState({
                    products: pdtResponse.result
                });
            }
        });
    }

    handleValuesChange = (values) => {
        this.setState({ pageNumber: 1 }, this.loadData);
    }

    onSearch = (value) => {
        this.setState({ pageNumber: 1 }, this.loadData);
    }

    loadData = () => {
        const _this = this;
        PageLoading.show();
        request.get('/Product/GetPagedProductMappings', {
            params: {
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize,
                name: this.search.current.input.value,
                siteId: _this.form.current.getFieldsValue().site
            }
        }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.setState({
                    dataSource: response.result.data,
                    totalCount: response.result.totalRecord
                });
            }
        });
    }

    handlePageChange = (page, pageSize) => {
        this.setState({
            pageNumber: page,
            pageSize: pageSize
        }, this.loadData);
    }

    render() {
        return (<PageContainer className="product-mapping-page">
            <Card loading={this.state.loading}>
                <Form
                    style={{ marginTop: '12px' }}
                    ref={this.form}
                    onValuesChange={this.handleValuesChange}
                >
                    <Row className="top-select-row" gutter={24}>
                        <Col {...topSiteSelectProps}>
                            <Form.Item name="site" label="平台">
                                <Select>
                                    {this.state.sites?.map(site => {
                                        return <Option key={site.id} value={site.id}>{site.displayName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...topSearchSelectProps}>
                            <Form.Item>
                                <Tooltip title="搜索商品名称、ProductId">
                                    <Search ref={this.search} placeholder="搜索..." allowClear onSearch={this.onSearch} />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Table rowKey='id'
                    columns={this.columns}
                    dataSource={this.state.dataSource}
                    tableLayout='fixed'
                    size="middle"
                    scroll={{ x: 950 }}
                    pagination={{
                        pageSize: this.state.pageSize,
                        current: this.state.pageNumber,
                        total: this.state.totalCount,
                        showQuickJumper: true,
                        size: 'default',
                        onChange: this.handlePageChange
                    }} />
            </Card>
        </PageContainer>);
    }
}

export default ProductProductMapping;