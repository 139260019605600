import React from "react";
import request from "@utils/request";
import { Modal, Spin, Alert, Input, Button, Form, message, InputNumber, Select, Tooltip } from 'antd';
import IconFont from '@components/Common/IconFont';

const { TextArea } = Input;
const { Option } = Select;

class EditStock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            errorMsg: null
        }
    }

    form = React.createRef();

    handleOk = () => {
        this.form.current.validateFields().then(values => {
            const { record } = this.props;
            const controller = new AbortController();
            const { signal } = controller;
            this.controller = controller;
            this.setState({ loading: true, errorMsg: null });
            const _this = this;
            var selectType = this.props.stockTypes.find(s => s.value === values.stockType);
            const newData = {
                stock: { ...record, ...values, stockTypeString: selectType?.text },
                isCreate: false
            };
            request.post('/Stock/CreateStock', {
                signal,
                data: newData
            }).then(function (response) {
                _this.setState({ loading: false });
                if (response && response.success) {
                    _this.setState({ visible: false });
                    message.success(`编辑产品库存${values.stockName}成功！`);
                    if (_this.props.afterUpdate) {
                        _this.props.afterUpdate(newData.stock);
                    }
                }
                if (response && response.success === false) {
                    _this.setState({ errorMsg: response.errorMsg });
                }
            });
        }).catch(errorInfo => { });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleOpen = () => {
        if (this.controller) {
            this.controller.abort();
        }
        this.setState({ visible: true, errorMsg: null }, () => {
            this.form.current.resetFields();
        });
    }

    render() {
        const { stockTypes, record } = this.props;
        return (
            <>
                <Tooltip title="编辑产品库存">
                    <Button type="primary" onClick={this.handleOpen} shape="circle" icon={<IconFont type="icon-edit-stock" />} />
                </Tooltip>
                <Modal title="编辑产品库存"
                    okButtonProps={{ loading: this.state.loading }}
                    maskClosable={false}
                    width={800}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Spin spinning={this.state.loading}>
                        {!!this.state.errorMsg && <Alert message={this.state.errorMsg} style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                        <Form ref={this.form} labelAlign="left" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} initialValues={{
                            stockName: record?.stockName,
                            description: record?.description,
                            stockType: record?.stockType,
                        }}>
                            <Form.Item label="库存名称" name="stockName" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="库存描述" name="description">
                                <TextArea rows={4} />
                            </Form.Item>
                            <Form.Item label="进货库存">
                                <InputNumber value={record?.stockCount} disabled style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item label="出货库存">
                                <InputNumber value={record?.shipmentCount} disabled style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item label="库存类型" name="stockType" rules={[{ required: true }]}>
                                <Select>
                                    {stockTypes?.map(stock => <Option key={stock.value} value={stock.value}>{stock.text}</Option>)}
                                </Select>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        );
    }
};

export default EditStock;