import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import { InfoCircleOutlined, GiftOutlined, WarningOutlined, StopOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import { Card, Button, Row, Col, Space, Select, Tooltip, Form, Input, Pagination, Typography, Image, message, Modal } from "antd";
import request from "@utils/request";
import commonConfig from '@common/commonConfig';
import PageLoading from '@components/Common/PageLoading/PageLoading';
import EditComment from '@components/Order/EditComment/EditComment';
import ExpressForm from '@components/Order/ExpressForm/ExpressForm';
import ImageContainer from '@components/Order/ImageContainer/ImageContainer';
import DesignImageContainer from "@components/Order/DesignImageContainer/DesignImageContainer";
import TableContainer from "@components/Common/TableContainer/TableContainer";
import classNames from 'classnames';
import TrackDetail from '@components/Order/TrackDetail/TrackDetail';
import UpdateTracking from "@components/Order/UpdateTracking/UpdateTracking";
import UpdateAddress from "@components/Order/UpdateAddress/UpdateAddress";
import CloneOrder from "@components/Order/CloneOrder/CloneOrder";
import EditOrder from "@components/Order/EditOrder/EditOrder";
import ExportOrderProperties from "@components/Order/ExportOrderProperties/ExportOrderProperties";
import ManualCreateOrder from "@components/Order/ManualCreateOrder/ManualCreateOrder";
import AddRemoveOrder from "@components/Order/AddRemoveOrder/AddRemoveOrder";
import FallbackImage from '@common/CommonImage';
import commonUtil from "@utils/commonUtil";
import './manage.less';

const { Option } = Select;
const { Search } = Input;
const { Text } = Typography;

const topSiteSelectProps = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 8,
    xl: 8,
};

const topFilterSelectProps = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 8,
    xl: 8,
};

const topSearchSelectProps = {
    xs: 24,
    sm: {
        span: 12,
        push: 12
    },
    md: {
        span: 8,
        push: 16
    },
    lg: {
        span: 8,
        push: 0
    },
    xl: 8
};

class OrderManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            sites: null,
            venders: null,
            dataSource: null,
            totalCount: 0,
            pageNumber: 1,
            pageSize: commonConfig.pageSize
        }
    }

    form = React.createRef();
    search = React.createRef();
    shipForm = React.createRef();
    tableContent = React.createRef();

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/Order/GetOrderManageParams').then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                _this.setState({
                    sites: response.result.sites,
                    leftColumns: response.result.leftColumns,
                    rightColumns: response.result.rightColumns,
                    mid1Columns: response.result.mid1Columns,
                    mid2Columns: response.result.mid2Columns,
                    venders: response.result.venders,
                }, () => {
                    _this.form.current.setFieldsValue({
                        site: response.result.sites?.length > 0 ? response.result.sites[0].id : null,
                        expressType: response.result.venders?.length > 0 ? response.result.venders[0].expressType : null
                    });
                    this.orderStatuses = response.result.orderStatuses;
                    _this.loadData();
                });
            }
        });
    }

    handleValuesChange = (values) => {
        this.setState({ pageNumber: 1 }, this.loadData);
    }

    loadData = () => {
        const _this = this;
        PageLoading.show();
        const formData = this.form.current.getFieldsValue();
        request.get('/Order/GetManageOrders', {
            params: {
                siteId: formData.site,
                orderStatus: 0,
                operator: 1,
                expressType: formData.expressType,
                pageSize: this.state.pageSize,
                pageNumber: this.state.pageNumber,
                name: this.search.current.input.value
            }
        }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.setState({
                    dataSource: response.result.data,
                    totalCount: response.result.totalRecord
                });
            }
        });
    }

    handlePageChange = (page, pageSize) => {
        this.setState({
            pageNumber: page,
            pageSize: pageSize
        }, this.loadData);
    }

    onSearch = (value) => {
        this.setState({ pageNumber: 1 }, this.loadData);
    }

    handleAfterUpdate = (newRecord, idx) => {
        const { dataSource } = this.state;
        const newSource = [...dataSource];
        newSource[idx] = newRecord;
        this.setState({ dataSource: newSource });
    }

    handleCancelOrder = (order) => {
        const _this = this;
        const msg = order.deleteStatus === 0 ? "取消订单" : "恢复订单";
        Modal.confirm({
            title: "请确认",
            content: order.deleteStatus === 0 ? "确定此订单已在销售网站取消？" : "确定要恢复此订单？",
            onOk: () => {
                PageLoading.show();
                request.post('/Order/CancelOrder', {
                    data: order
                }).then(function (response) {
                    PageLoading.hide();
                    if (response && response.success) {
                        message.success(`${msg}成功！`);
                        order.deleteStatus = order.deleteStatus === 0 ? 1 : 0;
                        _this.setState({ dataSource: _this.state.dataSource });
                    }
                    if (response && response.success === false) {
                        message.error(`系统异常，${msg}失败！`);
                    }
                });
            }
        })
    }

    render() {
        return (
            <PageContainer className="order-manage-page">
                <Card loading={this.state.loading}>
                    <Space wrap>
                        <ExportOrderProperties leftColumns={this.state.leftColumns}
                            rightColumns={this.state.rightColumns}
                            mid1Columns={this.state.mid1Columns}
                            mid2Columns={this.state.mid2Columns}
                            sites={this.state.sites} />
                        <ManualCreateOrder afterUpdate={() => this.loadData()} />
                        <AddRemoveOrder sites={this.state.sites} afterUpdate={() => this.loadData()} />
                    </Space>
                    <Form
                        style={{ marginTop: '12px' }}
                        ref={this.form}
                        onValuesChange={this.handleValuesChange}
                    >
                        <Row className="top-select-row" gutter={24}>
                            <Col {...topSiteSelectProps}>
                                <Form.Item name="site" label="平台">
                                    <Select>
                                        {this.state.sites?.map(site => {
                                            return <Option key={site.id} value={site.id}>{site.displayName}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...topFilterSelectProps}>
                                <Form.Item label="物流" name="expressType">
                                    <Select>
                                        {this.state.venders?.map(vd => {
                                            return <Option key={vd.expressType} value={vd.expressType}>{vd.displayName}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...topSearchSelectProps}>
                                <Form.Item>
                                    <Tooltip title="搜索客户单号、订单id、备注、产品名、快递单号、物流单号、末端派送商单号、邮箱">
                                        <Search ref={this.search} placeholder="搜索..." allowClear onSearch={this.onSearch} />
                                    </Tooltip>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <TableContainer className="order-table">
                        <table>
                            <colgroup>
                                <col width="90" />
                                <col width="120" />
                                <col width="120" />
                                <col width="400" />
                                <col width="180" />
                            </colgroup>
                            <thead className="ant-table-thead">
                                <tr>
                                    <th>客户单号</th>
                                    <th>产品图片</th>
                                    <th>定制信息</th>
                                    <th>效果图</th>
                                    <th>快递单号</th>
                                </tr>
                            </thead>
                            {
                                this.state.dataSource?.map((order, idx) => {
                                    return (<tbody key={order.id} className="ant-table-tbody">
                                        <tr className="order-extra-row">
                                            <td rowSpan={1 + order.lineItems.length * 2}>
                                                <div className="order-number-container">
                                                    <h4 className="h4-info">
                                                        {order.orderNumber}
                                                    </h4>
                                                    {
                                                        order.progressStatus === 1 && <span style={{ marginLeft: 4 }}>
                                                            <WarningOutlined style={{ fontSize: 16, color: '#faad14' }} />
                                                        </span>
                                                    }
                                                </div>
                                            </td>
                                            <td colSpan={4} className="order-extra-col">
                                                <div className="order-extra-info">
                                                    <Space direction="vertical" size={4}>
                                                        <Space size={["middle", 4]} wrap={true}>
                                                            <span>订单id：{order.orderId}</span>
                                                            <span>下单时间：{order.createTimeString}</span>
                                                            <span>{order.shippingMethod}</span>
                                                            {
                                                                order.needsGiftWrap && <span>
                                                                    <GiftOutlined style={{ color: '#ff883e', fontSize: 16, verticalAlign: -3 }} /> 需要包装
                                                                </span>
                                                            }
                                                            <span>Ship by: {order.shippedDateString}</span>
                                                            <span>Estimated delivery: {order.estimatedDeliveryDate}</span>
                                                        </Space>
                                                        <Space size={["middle", 4]} wrap={true}>
                                                            <span>姓名：{order.receiverName}</span>
                                                            <span>邮箱：{order.email}</span>
                                                            <span className={!order.reachable ? 'text-danger' : ''}>地址：{order.firstLine} {order.secondLine}, {order.city} {order.state} {order.zip}, {order.receiverCountry}</span>
                                                        </Space>
                                                        <div>客户备注：{order.buyerComment}</div>
                                                        <div>备注：{order.internalComment}</div>
                                                        <Space size={["middle", 4]} wrap={true}>
                                                            <span>Ship by: {order.shippedDateString}</span>
                                                            <span>Estimated delivery: {order.estimatedDeliveryDate}</span>
                                                            {
                                                                this.form.current.getFieldsValue().operator === 1 &&
                                                                <span>物流标注：{commonUtil.convertExpressNote(order.expressNote)}</span>
                                                            }
                                                        </Space>
                                                        <Row gutter={4}>
                                                            <Col span={6}><div>当前状态：<span className={classNames({
                                                                "text-danger": order.deleteStatus === 1
                                                            })}>{order.deleteStatus === 1 ? '订单取消' : order.orderStatusString}</span></div></Col>
                                                            <Col span={6}>
                                                                <div>物流：{order.expressTypeString}</div>
                                                            </Col>
                                                            <Col span={6}>
                                                                <div>渠道：{order.transportWay}</div>
                                                            </Col>
                                                            <Col span={6}>
                                                                <div><TrackDetail label="运单号：" record={order} style={{ display: 'inline-block' }} trackMethod='express' /></div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={6}>
                                                                <div>末端派送商：{order.carrierEName}</div>
                                                            </Col>
                                                            <Col span={6}>
                                                                <div>跟踪网址：{order.carrierWebsite}</div>
                                                            </Col>
                                                            <Col span={6}>
                                                                <div><TrackDetail label="跟踪号：" record={order} trackMethod='track' /></div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={6}>
                                                                <div>同步订单：{order.syncTimeString}</div>
                                                            </Col>
                                                            <Col span={6}>
                                                                <div>完成制作：{order.prepareTimeString}</div>
                                                            </Col>
                                                            <Col span={6}>
                                                                <div>创建物流：{order.createExpressTimeString}</div>
                                                            </Col>
                                                            <Col span={6}>
                                                                <div>完成预报：{order.declareTimeString}</div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={6}>
                                                                <div>完成打包：{order.packageTimeString}</div>
                                                            </Col>
                                                            <Col span={6}>
                                                                <div>生成跟踪号：{order.trackingTimeString}</div>
                                                            </Col>
                                                            <Col span={6}>
                                                                <div>Fulfill时间：{order.fulfillTimeString}</div>
                                                            </Col>
                                                            <Col span={6}>
                                                                <div>交付时间：{order.finishTimeString}</div>
                                                            </Col>
                                                        </Row>
                                                        <Space size={["middle", 4]} style={{ marginTop: 4 }}>
                                                            <EditComment
                                                                record={{ ...order, parentId: order.id }}
                                                                afterUpdate={(newRecord) => {
                                                                    order.internalComment = newRecord.internalComment;
                                                                    this.setState({ dataSource: [...this.state.dataSource] });
                                                                }} />
                                                            <span>
                                                                <UpdateAddress record={order}
                                                                    afterUpdate={(newReocrd) => this.handleAfterUpdate(newReocrd, idx)} />
                                                                <Tooltip title="物流订单创建之后不可以修改订单地址">
                                                                    <InfoCircleOutlined style={{ fontSize: 14, color: "rgba(0, 0, 0, 0.45)", marginLeft: 4 }} />
                                                                </Tooltip>
                                                            </span>
                                                            <UpdateTracking record={order}
                                                                afterUpdate={(newReocrd) => this.handleAfterUpdate(newReocrd, idx)} />
                                                            <CloneOrder record={order} afterUpdate={() => this.loadData()} />
                                                            <EditOrder record={order} orderStatuses={this.orderStatuses} afterUpdate={(newReocrd) => this.handleAfterUpdate(newReocrd, idx)} />
                                                            <Button shape="round" type="default" onClick={() => this.handleCancelOrder(order)} icon={order.deleteStatus === 0 ? <StopOutlined /> : <IssuesCloseOutlined />}>
                                                                {order.deleteStatus === 0 ? "取消订单" : "恢复订单"}
                                                            </Button>
                                                        </Space>
                                                    </Space>
                                                </div>
                                            </td>
                                        </tr>
                                        {order.lineItems?.map((line, lidx) => {
                                            return (<React.Fragment key={line.id}>
                                                <tr className="product-start-row">
                                                    <td colSpan={4}>
                                                        <Row gutter={8}>
                                                            <Col span={2}>
                                                                <span>数量：{line.count}</span>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Text title={line.title} ellipsis>产品：{line.title}</Text>
                                                            </Col>
                                                            <Col span={10}>
                                                                <span>样式：{line.optionsString}</span>
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                </tr>
                                                <tr className="product-end-row" style={{ verticalAlign: 'top' }}>
                                                    <td>
                                                        <Image className="custom-image" src={line.thumbnail} fallback={FallbackImage} preview={false} />
                                                    </td>
                                                    <td className="drag-column">
                                                        <ImageContainer record={line} editable={false} />
                                                    </td>
                                                    <td>
                                                        <DesignImageContainer record={line} orderStatus={order.orderStatus} editable={true} afterUpdate={rd => {
                                                            line.designImageSmallList = rd.designImageSmallList;
                                                            line.designImageList = rd.designImageList;
                                                            this.setState({ dataSource: [...this.state.dataSource] });
                                                        }} />
                                                    </td>
                                                    <td>
                                                        <ExpressForm record={line} editable={true} orderStatus={order.orderStatus} afterUpdate={rd => {
                                                            line.expressName = rd.expressName;
                                                            line.expressNumber = rd.expressNumber;
                                                            line.comment = rd.comment;
                                                            this.setState({ dataSource: [...this.state.dataSource] });
                                                        }} />
                                                    </td>
                                                </tr>
                                            </React.Fragment>)
                                        })}
                                    </tbody>)
                                })
                            }
                        </table>
                    </TableContainer>
                    <Pagination size="default" style={{ float: 'right', marginTop: 20 }} onChange={this.handlePageChange} showQuickJumper={true} current={this.state.pageNumber} pageSize={this.state.pageSize} total={this.state.totalCount} />
                </Card>
            </PageContainer>);
    }
}

export default OrderManage;