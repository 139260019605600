import React from "react";
import request from "@utils/request";
import { Modal, Spin, Alert, Select, Form, Tooltip, Button } from 'antd';
import IconFont from '@components/Common/IconFont';

const { Option } = Select;

class EditTrackStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            hasError: false
        }
    }

    form = React.createRef();

    handleOk = () => {
        const controller = new AbortController();
        const { signal } = controller;
        this.controller = controller;
        this.setState({ loading: true, hasError: false });
        const _this = this;
        request.post('/Track/UpdateTrackStatus', {
            signal,
            data: {
                id: this.props.record.id,
                trackStatus: this.form.current.getFieldsValue().trackStatus
            }
        }).then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                _this.setState({ visible: false });
                if (_this.props.afterUpdate) {
                    _this.props.afterUpdate({
                        id: _this.props.record.id,
                        trackStatus: _this.form.current.getFieldsValue().trackStatus
                    });
                }
            }
            if (response && response.success === false) {
                _this.setState({ hasError: true });
            }
        });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleOpen = () => {
        if (this.controller) {
            this.controller.abort();
        }
        this.setState({ visible: true, hasError: false }, () => {
            this.form.current.resetFields();
        });
    }

    render() {
        return (
            <>
                <Tooltip title="更改轨迹状态">
                    <Button type="primary" onClick={this.handleOpen} shape="circle" icon={<IconFont type="icon-status" />} />
                </Tooltip>
                <Modal title="更改跟踪方式"
                    okButtonProps={{ loading: this.state.loading }}
                    maskClosable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Spin spinning={this.state.loading}>
                        {this.state.hasError && <Alert message="编辑备注失败！" style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                        <Form ref={this.form} initialValues={{
                            trackStatus: this.props.record.trackStatus
                        }}>
                            <Form.Item name="trackStatus" label="轨迹状态">
                                <Select>
                                    <Option value={1}>等待揽收</Option>
                                    <Option value={2}>运输途中</Option>
                                    <Option value={3}>成功签收</Option>
                                    <Option value={4}>投递失败</Option>
                                    <Option value={5}>可能异常</Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        );
    }
};

export default EditTrackStatus;