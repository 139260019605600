import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import { Card, Select, Drawer } from "antd";
import ReactEcharts from 'echarts-for-react';
import PageLoading from '@components/Common/PageLoading/PageLoading';
import request from "@utils/request";
import TrackDetail from '@components/Order/TrackDetail/TrackDetail';
import withBreakpoint from '@components/Common/withBreakpoint';
import './transit-timeliness.less';

const { Option } = Select;

class LogisticsTransitTimeliness extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            logisticsVendors: [],
            value: null,
            data: null,
            visible: false,
            currentOrder: null
        }
    }

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/Express/GetExpressVendersList', { params: { includeAll: true } }).then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                const value = response.results?.length > 0 ? response.results[0].expressType : null;
                _this.setState({ logisticsVendors: response.results, value }, _this.loadCharts);
            }
        });
    }

    loadCharts = () => {
        const _this = this;
        PageLoading.show();
        request.get('/Track/GetTransitTimeInfos', { params: { expressType: this.state.value } }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.setState({ data: response.results });
            }
        });
    }

    onClose = () => {
        this.setState({ visible: false });
    }

    handleChange = value => {
        this.setState({ value }, this.loadCharts);
    }

    handleChartClick = (params) => {
        //params.event.cancelBubble = true;
        //params.event.event.stopPropagation();
        // params.event.event.cancelBubble = true;
        params.event.event.preventDefault();

        this.setState({ currentOrder: params.data, visible: true });
    }

    chartsEvents = {
        'click': this.handleChartClick.bind(this)
    }

    render() {
        const option = {
            title: {
                text: "在途时效",
            },
            toolbox: {
                feature: {
                    dataZoom: {
                        yAxisIndex: false
                    }
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            dataZoom: [
                {
                    type: 'inside'
                },
                {
                    type: 'slider'
                }
            ],
            xAxis: {
                type: 'category',
                data: this.state.data?.map(d => d.orderNumber),
                silent: false,
                splitLine: {
                    show: false
                },
                splitArea: {
                    show: false
                }
            },
            yAxis: {
                splitArea: {
                    show: false
                },
                minInterval: 1
            },
            series: [
                {
                    type: 'bar',
                    data: this.state.data,
                    large: true
                }
            ]
        };

        const { breakpoint } = this.props;
        return (<PageContainer className="transit-timeliness-page">
            <Card loading={this.state.loading}>
                <Select style={{ width: 180 }} value={this.state.value}
                    onChange={this.handleChange}>
                    {this.state.logisticsVendors?.map(l => <Option key={l.expressType} value={l.expressType}>{l.displayName}</Option>)}
                </Select>
                <ReactEcharts style={{ height: 400, marginTop: 24 }} option={option} onEvents={this.chartsEvents} />
            </Card>
            <Drawer className="transit-timeliness-drawer" push={{ distance: 260 }} title="订单信息" width={((breakpoint.xxl || breakpoint.xl || breakpoint.lg || breakpoint.md) ? 400 : 300)} placement="right" visible={this.state.visible} onClose={this.onClose}>
                {this.state.currentOrder && <div>
                    <table className="order-info-table">
                        <colgroup>
                            <col width={10} />
                            <col width={30} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td>订单号</td>
                                <td>{this.state.currentOrder.orderNumber}</td>
                            </tr>
                            <tr>
                                <td>物流</td>
                                <td>{this.state.currentOrder.expressName}</td>
                            </tr>
                            <tr>
                                <td>渠道</td>
                                <td>{this.state.currentOrder.transportWay}</td>
                            </tr>
                            <tr>
                                <td>物流单号</td>
                                <td><TrackDetail record={this.state.currentOrder} trackMethod='express' /></td>
                            </tr>
                            <tr>
                                <td>末端派送商</td>
                                <td>{this.state.currentOrder.carrierEName}</td>
                            </tr>
                            <tr>
                                <td>跟踪号</td>
                                <td><TrackDetail record={this.state.currentOrder} trackMethod='track' /></td>
                            </tr>
                            <tr>
                                <td>国家</td>
                                <td>{this.state.currentOrder.receiverCountry}</td>
                            </tr>
                            <tr>
                                <td>在途时长</td>
                                <td>{this.state.currentOrder.value}天</td>
                            </tr>
                        </tbody>
                    </table>
                </div>}
            </Drawer>
        </PageContainer>);
    }
}

export default withBreakpoint(LogisticsTransitTimeliness);