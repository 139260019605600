import React from "react";
import request from "@utils/request";
import { Modal, Spin, Alert, Select, Form, Button, DatePicker, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import commonConfig from "@common/commonConfig";

const { Option } = Select;
const { RangePicker } = DatePicker;

class ExportOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            error: null
        }
    }

    form = React.createRef();

    handleOk = () => {
        const controller = new AbortController();
        const { signal } = controller;
        this.controller = controller;
        this.form.current.validateFields().then(values => {
            this.setState({ loading: true, error: null });
            const _this = this;
            const rangeValue = values['timeRange'];
            request.post('/Order/ExportOrder', {
                signal,
                responseType: 'blob',
                data: {
                    siteId: values.site,
                    progressStatus: values.progressStatus,
                    startDate: rangeValue[0].format(commonConfig.dateTimeFormat),
                    endDate: rangeValue[1].format(commonConfig.dateTimeFormat)
                }
            }).then(function (response) {
                _this.setState({ loading: false });
                if (response.ok === 200 || response.ok === undefined) {
                    if (response.type === 'text/plain') {
                        response.text().then(text => {
                            _this.setState({
                                error: `导出订单失败，${text}`
                            })
                        })
                    } else {
                        //const blob = new Blob([response]);
                        const objectURL = URL.createObjectURL(response);
                        let btn = document.createElement('a');
                        btn.download = `OrderInfos_${moment().format("YYYYMMDDHHmmss")}.zip`;
                        btn.href = objectURL;
                        btn.click();
                        btn.remove();
                        URL.revokeObjectURL(objectURL);
                        btn = null;
                        _this.setState({ visible: false });
                        message.success("导出订单成功！");
                    }
                }
            });
        }).catch(errorInfo => { });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleOpen = () => {
        if (this.controller) {
            this.controller.abort();
        }
        this.setState({ visible: true, error: null });
    }

    render() {
        return (
            <>
                <Button type="primary" onClick={this.handleOpen} icon={<DownloadOutlined />} ghost>
                    导出订单
                </Button>
                <Modal title="导出订单"
                    okButtonProps={{ loading: this.state.loading }}
                    maskClosable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Spin spinning={this.state.loading}>
                        {!!this.state.error && <Alert message={this.state.error} style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                        <Form ref={this.form} initialValues={{
                            site: this.props.sites?.length > 0 ? this.props.sites[0].id : null,
                            progressStatus: 100,
                        }}>
                            <Form.Item name="site" label="平台" rules={[{ required: true }]}>
                                <Select>
                                    {this.props.sites?.map(site => {
                                        return <Option key={site.id} value={site.id}>{site.displayName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item name="progressStatus" label="状态" rules={[{ required: true }]}>
                                <Select>
                                    <Option value={100}>全部</Option>
                                    <Option value={0}>正常单</Option>
                                    <Option value={1}>问题单</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="timeRange" label="日期" rules={[{ type: 'array', required: true }]}>
                                <RangePicker showTime format={commonConfig.dateTimeFormat} />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        );
    }
};

export default ExportOrder;