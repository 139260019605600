const commonConfig = {
    siteName: '中志云协作平台',
    navHeader: '中志 ERP',
    companyNameEn: 'Zhongzhi Cloud Business Technology Co., Ltd.',
    companyNameCn: '中志云商务科技有限公司',
    pageSize: 20,
    allowUploadSize: 4 * 1024 * 1024,
    allowImageType: ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.webp'],
    dateFormat: 'yyyy/MM/DD',
    timeFormat: 'HH:mm:ss',
    dateTimeFormat: 'yyyy/MM/DD HH:mm:ss',
    encryptionKey: 'smaright'
}

export default commonConfig;