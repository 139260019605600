import React from "react";
import request from "@utils/request";
import { Modal, Spin, Alert, Radio, Space, Tooltip, Button, Form } from 'antd';
import IconFont from '@components/Common/IconFont';

class EditTrackMethod extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            hasError: false
        }
    }

    form = React.createRef();

    handleOk = () => {
        const controller = new AbortController();
        const { signal } = controller;
        this.controller = controller;
        this.setState({ loading: true, hasError: false });
        const _this = this;
        request.post('/Track/UpdateTrackMethod', {
            signal,
            data: {
                parentId: this.props.record.parentId,
                trackMethod: this.form.current.getFieldsValue().trackMethod
            }
        }).then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                _this.setState({ visible: false });
                if (_this.props.afterUpdate) {
                    _this.props.afterUpdate({
                        parentId: _this.props.record.parentId,
                        trackMethod: _this.form.current.getFieldsValue().trackMethod
                    });
                }
            }
            if (response && response.success === false) {
                _this.setState({ hasError: true });
            }
        });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleOpen = () => {
        if (this.controller) {
            this.controller.abort();
        }
        this.setState({ visible: true, hasError: false }, () => {
            this.form.current.resetFields();
        });
    }

    render() {
        return (
            <>
                <Tooltip title="更改跟踪方式">
                    <Button type="primary" onClick={this.handleOpen} shape="circle" icon={<IconFont type="icon-track-changes" />} />
                </Tooltip>
                <Modal title="更改跟踪方式"
                    okButtonProps={{ loading: this.state.loading }}
                    maskClosable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Spin spinning={this.state.loading}>
                        {this.state.hasError && <Alert message="编辑备注失败！" style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                        <Form ref={this.form} initialValues={{
                            trackMethod: this.props.record.trackMethod
                        }}>
                            <Form.Item name="trackMethod">
                                <Radio.Group>
                                    <Space direction="vertical">
                                        <Radio value={0}>使用物流商轨迹进行跟踪</Radio>
                                        <Radio value={1}>使用末端派送商轨迹进行跟踪</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        );
    }
};

export default EditTrackMethod;