import React from "react";
import request from "@utils/request";
import { Modal, Spin, Alert, Button, Form, Input, Select, message } from 'antd';
import IconFont from '@components/Common/IconFont';
import { isAdmin } from "@utils/authority";
import './AddRemoveOrder.less';

const { Option } = Select;

class AddRemoveOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            errorMsg: null
        }
    }

    form = React.createRef();

    handleAdd = () => {
        this.form.current.validateFields().then(values => {
            this.setState({ loading: true, errorMsg: null });
            const _this = this;
            request.post('/Order/AddOrder', {
                data: { ...values }
            }).then(function (response) {
                _this.setState({ loading: false });
                if (response && response.success) {
                    _this.setState({ visible: false });
                    message.success("添加订单成功！");
                    if (_this.props.afterUpdate) {
                        _this.props.afterUpdate();
                    }
                }
                if (response && response.success === false) {
                    _this.setState({ errorMsg: response.errorMsg });
                }
            });
        }).catch(errorInfo => { });
    };

    handleRemove = () => {
        this.form.current.validateFields().then(values => {
            this.setState({ loading: true, errorMsg: null });
            const _this = this;
            request.post('/Order/DeleteOrder', {
                data: { ...values }
            }).then(function (response) {
                _this.setState({ loading: false });
                if (response && response.success) {
                    _this.setState({ visible: false });
                    message.success("删除订单成功！");
                    if (_this.props.afterUpdate) {
                        _this.props.afterUpdate();
                    }
                }
                if (response && response.success === false) {
                    _this.setState({ errorMsg: "系统异常，删除订单失败！" });
                }
            });
        }).catch(errorInfo => { });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleOpen = () => {
        this.setState({ visible: true, errorMsg: null }, () => {
            this.form.current.resetFields();
        });
    }

    render() {
        const sites = this.props.sites?.filter(s => s.id !== 0);
        return isAdmin() ? (
            <>
                <Button type="primary" ghost onClick={this.handleOpen} icon={<IconFont type="icon-increase-decrease-line" />}>
                    增减订单
                </Button>
                <Modal title="增减订单"
                    wrapClassName="manual-create-order-modal"
                    maskClosable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="cancel" type="default" onClick={this.handleCancel}>取消</Button>,
                        <Button key="remove" type="primary" onClick={this.handleRemove} danger>删除</Button>,
                        <Button key="add" type="primary" onClick={this.handleAdd} >添加</Button>
                    ]}
                >
                    <Spin spinning={this.state.loading}>
                        {!!this.state.errorMsg && <Alert message={this.state.errorMsg} style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                        <Form ref={this.form} labelAlign="left" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} initialValues={{
                            siteId: null,
                            orderId: null
                        }}>
                            <Form.Item name="siteId" label="平台" rules={[{ required: true }]}>
                                <Select>
                                    {sites?.map(site => {
                                        return <Option key={site.id} value={site.id}>{site.displayName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item label="订单Id" name="orderId" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        ) : '';
    }
};

export default AddRemoveOrder;