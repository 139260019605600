import React from "react";
import { Modal, Space, Tag, Form, Input, Alert, Spin, Button, message } from "antd";
import request from "@utils/request";
import classNames from 'classnames';
import IconFont from '@components/Common/IconFont';
import './ShipOrder.less';

class ShipOrder extends React.Component {
    constructor(props) {
        super(props);
        const { record, settings } = props;
        const ways = settings?.find(s => s.countryCode === record.receiverCountry)?.wayInfos;
        const way = ways?.length > 0 ? ways[0] : null;
        this.state = {
            visible: false,
            shipLoading: false,
            selectedTag: way,
            ways: ways
        }
        this.initialValues = { shipWay: this.formatTag(way) };
    }

    shipForm = React.createRef();

    handleShipOpen = () => {
        const { record } = this.props;
        if (record.progressStatus !== 0) {
            Modal.warning({
                title: "请注意",
                content: "请将订单状态改为正常单后再进行发货！"
            });
            return;
        }
        if (record.lineItems.some(l => !l.expressName || !l.expressNumber)) {
            Modal.warning({
                title: "请注意",
                content: "请填入快递名称和单号后再进行发货！"
            });
            return;
        }
        var needstock = [];
        for (var i = 0; i < record.lineItems.length; i++) {
            var lineitm = record.lineItems[i];
            if (lineitm.stockId) {
                if (needstock[lineitm.stockId]) {
                    needstock[lineitm.stockId] += lineitm.count;
                } else {
                    needstock[lineitm.stockId] = lineitm.count;
                }
                if ((needstock[lineitm.stockId]) * lineitm.expendUnit > lineitm.stockCount) {
                    Modal.warning({
                        title: "请注意",
                        content: `${lineitm.stockName} 库存不足！`
                    });
                    return;
                }
            }
        }
        const { ways } = this.state;
        const way = ways?.length > 0 ? ways[0] : null;
        this.setState({ visible: true, shipError: null, selectedTag: way }, () => {
            this.shipForm.current.resetFields();
        });
    }

    handleShipOk = () => {
        const _this = this;
        this.shipForm.current.validateFields().then(values => {
            _this.setState({ shipLoading: true, shipError: null });
            const newRecord = {
                ...this.props.record,
                expressNote: !!this.state.selectedTag ? JSON.stringify(this.state.selectedTag) : values.shipWay
            };
            request.post('/Order/SavetoTransport', {
                data: newRecord
            }).then(function (response) {
                _this.setState({ shipLoading: false });
                if (response && response.success) {
                    _this.setState({
                        visible: false
                    });
                    message.success("发货成功！");
                    if (_this.props.afterUpdate) {
                        _this.props.afterUpdate(newRecord);
                    }
                }
                if (response && response.success === false) {
                    _this.setState({
                        shipError: '设置发货渠道异常，发货失败！'
                    });
                }
            });
        }).catch(errorInfo => { });
    }

    handleShipCancel = () => {
        this.setState({ visible: false });
    }

    handShipTextChange = (values) => {
        //window.setTimeout(() => {
        const { ways } = this.state;
        const selectedTag = ways?.find(w => this.formatTag(w) === values.shipWay);
        this.setState({ selectedTag: selectedTag });
        //}, 0);
    }

    handleTagClick = (tag) => {
        if (this.state.selectedTag !== tag) {
            this.setState({ selectedTag: tag }, () => {
                this.shipForm.current.setFieldsValue({
                    shipWay: this.formatTag(tag)
                });
            });
        }
    }

    formatTag = (tag) => {
        return !!tag ? `${tag.wayName} (${tag.expressTypeString})` : '';
    }

    render() {
        return (<>
            <Button type="primary" onClick={this.handleShipOpen} shape="round" icon={<IconFont type="icon-localshipping" />} >
                发货
            </Button>
            <Modal title="发货渠道"
                className="prepare-ship-modal"
                okButtonProps={{ loading: this.state.shipLoading }}
                maskClosable={false}
                visible={this.state.visible}
                onOk={this.handleShipOk}
                onCancel={this.handleShipCancel}
            >
                <Spin spinning={this.state.shipLoading}>
                    {!!this.state.shipError && <Alert message={this.state.shipError} style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                    <Form ref={this.shipForm} initialValues={this.initialValues} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} onValuesChange={this.handShipTextChange}>
                        <Form.Item name="shipWay" label="物流渠道" rules={[{ required: true }]}
                            extra={
                                <Space direction="vertical" size={4} style={{ width: '100%', marginTop: 8 }}>
                                    {this.state.ways?.map(w => <Tag className={classNames({
                                        'ship-selected-tag': this.state.selectedTag === w,
                                        'ship-tag': true
                                    })} color="processing" key={w.wayCode} onClick={() => this.handleTagClick(w)}>{this.formatTag(w)}</Tag>)}
                                </Space>
                            }>
                            <Input autoComplete="off" />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>)
    }
}

export default ShipOrder;