import { Tag } from 'antd';
import React from 'react';
import Avatar from './AvatarDropdown';
import './index.less';
import NoticeIconView from './NoticeIconView';

const ENVTagColor = {
    dev: 'orange',
    test: 'green',
    pre: '#87d068',
};

const RightContent = (props) => {
    return (
        <div className='right header-right-content'>
            <NoticeIconView />
            <Avatar menu />
            {
                process.env.REACT_APP_ENV && (
                    <span>
                        <Tag color={ENVTagColor[process.env.REACT_APP_ENV]}>{process.env.REACT_APP_ENV}</Tag>
                    </span>
                )
            }
        </div >
    );
};

export default RightContent;
