import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import PageLoading from '@components/Common/PageLoading/PageLoading';
import commonConfig from '@common/commonConfig';
import request from "@utils/request";
import { ExportOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import IconFont from '@components/Common/IconFont';
import { Card, Button, Space, Table, Row, Col, Typography, Modal, message } from "antd";
import moment from 'moment';
import MarkWaiting from "@components/Order/MarkWaiting/MarkWaiting";
import ManualCreateLogistics from "@components/Order/ManualCreateLogistics/ManualCreateLogistics";
import CreateLogistics from "@components/Order/CreateLogistics/CreateLogistics";
import commonUtil from "@utils/commonUtil";
import './create-logistics.less';

const { Text } = Typography;

class OrderCreateLogistics extends React.Component {
    state = {
        loading: false,
        pageNumber: 1,
        pageSize: commonConfig.pageSize,
        dataSource: null,
        totalCount: 0
    }

    columns = [
        {
            title: '客户单号',
            key: 'orderNumber',
            render: (record) => (<div><span className="primary-text">{record.orderNumber}</span>{record.progressStatus === 1 && <IconFont className="wait-order" type="icon-sandclock" />}</div>),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.progressStatus !== nextRecord.progressStatus,
            width: 18
        },
        {
            title: '订单ID',
            key: 'orderId',
            dataIndex: 'orderId',
            width: 20
        },
        {
            title: '国家',
            key: 'countryCnName',
            dataIndex: 'countryCnName',
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.countryCnName !== nextRecord.countryCnName,
            render: country => (<span className={classNames({ "special-country": country.toUpperCase() !== '美国' })}>{country}</span>),
            width: 14
        },
        {
            title: '标注',
            key: 'expressNote',
            dataIndex: 'expressNote',
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.expressNote !== nextRecord.expressNote,
            render: note => {
                const newNote = commonUtil.convertExpressNote(note);
                return (<span>{newNote}</span>)
            },
            width: 28
        },
        {
            title: '操作',
            key: 'action',
            render: (record) => (
                <Space wrap>
                    <ManualCreateLogistics record={record} venders={this.venders} allWays={this.ways}
                        afterUpdate={(newRecord) => {
                            this.setState({ pageNumber: 1 }, () => { this.loadData(); });
                        }} />
                    <MarkWaiting record={record}
                        afterUpdate={(newRecord) => {
                            const idx = this.state.dataSource.findIndex(x => x.id === record.id);
                            const rd = { ...record, progressStatus: newRecord.progressStatus };
                            const newSource = [...this.state.dataSource];
                            newSource[idx] = rd;
                            this.setState({ dataSource: newSource });
                        }} />
                    <Button type="primary" shape="round" icon={<IconFont type="icon-file-add" />}
                        onClick={() => this.handleOpenCreate(record)}
                    >创建</Button>
                </Space>
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord !== nextRecord
                || prevRecord.progressStatus !== nextRecord.progressStatus,
            width: 20
        },
    ];

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/Order/GetTransportParams').then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                _this.venders = response.result.venders;
                _this.ways = response.result.ways;
                _this.expressDefaultInfo = response.result.expressDefaultInfo;
                _this.loadData();
            }
        });
    }

    handleOpenCreate = record => {
        if (record.progressStatus !== 0) {
            Modal.warning({
                title: "请注意",
                content: "请将订单状态改为正常单后再进行物流建单！"
            });
            return;
        }
        this.setState({ createVisible: true, currentReacord: record })
    }

    loadData = () => {
        const _this = this;
        PageLoading.show();
        request.get('/Order/GetTransporteOrders', {
            params: {
                orderStatus: 2,
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize
            }
        }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.setState({
                    dataSource: response.result.data,
                    totalCount: response.result.totalRecord
                });
            }
        });
    }

    handleExportOrder = () => {
        const _this = this;
        PageLoading.show();
        request.post('/Order/ExportTransportOrders', {
            responseType: 'blob'
        }).then(function (response) {
            PageLoading.hide();
            if (response.ok === 200 || response.ok === undefined) {
                if (response.type === 'text/plain') {
                    response.text().then(text => {
                        _this.setState({
                            error: `导出订单失败，${text}`
                        })
                    })
                } else {
                    //const blob = new Blob([response]);
                    const objectURL = URL.createObjectURL(response);
                    let btn = document.createElement('a');
                    btn.download = `CreateExpress_${moment().format("YYYYMMDDHHmmss")}.xlsx`;
                    btn.href = objectURL;
                    btn.click();
                    btn.remove();
                    URL.revokeObjectURL(objectURL);
                    btn = null;
                    message.success("导出订单成功！");
                }
            }
        });
    }

    handlePageChange = (page, pageSize) => {
        this.setState({
            pageNumber: page,
            pageSize: pageSize
        }, this.loadData);
    }

    render() {
        return (<PageContainer className="create-logistics-page">
            <Card loading={this.state.loading}>
                <Button type="primary" onClick={this.handleExportOrder} icon={<ExportOutlined />} ghost>
                    导出订单
                </Button>
                <Table rowKey='id'
                    columns={this.columns}
                    dataSource={this.state.dataSource}
                    tableLayout='fixed'
                    size="middle"
                    scroll={{ x: 950 }}
                    expandable={{
                        expandedRowKeys: this.state.dataSource?.map(d => { return d.id }),
                        expandedRowRender: record => <div className="order-extra-info">{record.declareItems.map((line, idx) =>
                            <Row gutter={12} key={idx}>
                                <Col span="2"><Text>数量：{line.quantity}</Text></Col>
                                <Col span="12"><Text title={line.cnName ? line.cnName : line.enName} ellipsis={true}>产品：{line.cnName ? line.cnName : line.enName}</Text></Col>
                                <Col span="10"><Text>快递备注：<Text mark={line.comment?.toLowerCase()?.includes('dhl') ? true : false}>{line.comment}</Text></Text></Col>
                            </Row>)}
                            <Row gutter={12}>
                                <Col span="24">备注：{record.internalComment}</Col>
                            </Row>
                        </div>,
                        columnWidth: 0
                    }}
                    pagination={{
                        pageSize: this.state.pageSize,
                        current: this.state.pageNumber,
                        total: this.state.totalCount,
                        showQuickJumper: true,
                        size: 'default',
                        onChange: this.handlePageChange
                    }} />
            </Card>
            <CreateLogistics visible={this.state.createVisible}
                record={this.state.currentReacord}
                venders={this.venders}
                onCancel={() => { this.setState({ createVisible: false }) }}
                afterUpdate={() => { this.setState({ pageNumber: 1 }, () => { this.loadData(); }); }} />
        </PageContainer>);
    }
}

export default OrderCreateLogistics;