import React from "react";
import { Link } from "react-router-dom";
import ProLayout, { DefaultFooter } from '@ant-design/pro-layout';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { checkAuthorized } from '../components/Authorized/Authorized';
import logo from '@assets/images/logo.svg';
import routerConfig from './routerConfig';
import commonConfig from '@common/commonConfig';
import RightContent from '../components/GlobalHeader/RightContent';
import { IconFontUrl } from '../components/Common/IconFont';
import ResizeObserver from 'rc-resize-observer';
import withBreakpoint from '@components/Common/withBreakpoint';
import Authorized from "../components/Authorized/Authorized";
import * as _ from 'lodash';
import './BasicLayout.less';

const menuDataRender = (menuList) => {
    let newMenuList = [];
    for (let i = 0; i < menuList.length; i++) {
        const menu = menuList[i];
        if (menu.children) {
            const childrenList = menuDataRender(menu.children);
            if (childrenList?.length > 0) {
                const newMenu = { ...menu, children: childrenList };
                newMenuList.push(newMenu);
            }
        } else {
            if (checkAuthorized(menu)) {
                newMenuList.push(menu);
            }
        }
    }
    return newMenuList;
}

class BasicLayout extends React.Component {
    constructor(props) {
        super(props);
        const { collapsed = false } = props;
        this.state = {
            collapsed: collapsed
        }
        this.throttleResize = _.throttle(this.resize, 200);
    }

    componentDidUpdate(preProps) {
        if (preProps.collapsed !== this.props.collapsed) {
            this.setState({
                collapsed: this.props.collapsed
            });
        }
    }

    handleMenuCollapse = (collap) => {
        this.setState({
            collapsed: collap
        })
    }

    resize = () => {
        if (document.body.className.indexOf('ant-scrolling-effect') > -1) {
            const clientHeight = document.body.clientHeight;
            const scrollHeight = document.body.scrollHeight;
            if (scrollHeight > clientHeight) {
                document.body.classList.add("custom-with-scroll");
            } else {
                document.body.classList.remove("custom-with-scroll");
            }
        }
    };

    render() {
        return (
            <>
                <ProLayout
                    style={{ minHeight: '100vh' }}
                    headerHeight={48}
                    className="basic-layout"
                    logo={logo}
                    route={routerConfig.route}
                    navTheme='dark'
                    layout='side'
                    contentWidth='Fluid'
                    fixedHeader={true}
                    fixSiderbar={true}
                    title={commonConfig.navHeader}
                    iconfontUrl={IconFontUrl}
                    onPageChange={() => {
                        if (this.props.breakpoint.lg === false) {
                            this.setState({ collapsed: true });
                        }
                    }}
                    collapsed={this.state.collapsed}
                    onCollapse={this.handleMenuCollapse}
                    onMenuHeaderClick={() => this.props.history.push('/')}
                    menuItemRender={(menuItemProps, defaultDom) => {
                        const paddingLeft = menuItemProps.icon ? '0px' : '10px';
                        if (
                            menuItemProps.isUrl ||
                            !menuItemProps.path ||
                            this.props.location.pathname === menuItemProps.path
                        ) {
                            return <div style={{ paddingLeft }}>{defaultDom}</div>;
                        }
                        return <Link to={menuItemProps.path}><div style={{ paddingLeft }}>{defaultDom}</div></Link>;

                    }}
                    breadcrumbRender={(routers = []) => [
                        {
                            path: '/',
                            breadcrumbName: "首页",
                        },
                        ...routers,
                    ]}
                    itemRender={(route, params, routes, paths) => {
                        const first = routes.indexOf(route) === 0;
                        return first ? (
                            <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
                        ) : (
                            <span>{route.breadcrumbName}</span>
                        );
                    }}
                    footerRender={() => {
                        return (<DefaultFooter
                            copyright={`${new Date().getFullYear()} ${commonConfig.companyNameCn}`}
                            links={[]}
                        />);
                    }}
                    menuDataRender={menuDataRender}
                    collapsedButtonRender={false}
                    headerContentRender={() => {
                        return (
                            <ResizeObserver onResize={this.throttleResize}>
                                <div>
                                    <span
                                        onClick={() => this.handleMenuCollapse(!this.state.collapsed)}
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: '16px',
                                            marginLeft: this.props.breakpoint.md === true ? 0 : 8
                                        }}
                                    >
                                        {this.state.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                    </span>
                                </div>
                            </ResizeObserver>
                        );
                    }}
                    rightContentRender={() => <RightContent />}
                >
                    <Authorized location={this.props.location} path={this.props.match.path}>
                        {this.props.children}
                    </Authorized>
                </ProLayout>
            </>
        )
    }
}

export default withBreakpoint(BasicLayout);