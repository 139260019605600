import React from "react";
import { Drawer, Button, List, Skeleton, Typography } from "antd";
import commonConfig from '@common/commonConfig';
import request from "@utils/request";
import { HistoryOutlined, SyncOutlined } from '@ant-design/icons';
import withBreakpoint from '@components/Common/withBreakpoint';
import './TimerHistory.less';

const { Text } = Typography;

class TrackDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            initLoading: false,
            visible: false,
            pageNumber: 1,
            pageSize: commonConfig.pageSize,
            showLoadMore: true,
            data: [],
            list: []
        }
    }

    handleOpen = () => {
        this.setState({ visible: true });
        if (!this.state.initLoading) {
            this.setState({ initLoading: true });
            this.loadItems();
        }
    }

    handleLoadMore = () => {
        this.setState((state, props) => ({
            pageNumber: state.pageNumber + 1
        }), () => this.loadItems());
    }

    handleClose = () => {
        this.setState({ visible: false });
    }

    handleRefresh = () => {
        this.setState({
            pageNumber: 1,
            showLoadMore: true,
            data: [],
            list: []
        }, () => {
            this.loadItems();
        })
    }

    loadItems = () => {
        const _this = this;
        const { record } = this.props;
        _this.setState({
            loading: true,
            list: this.state.data.concat([...new Array(3)].map(() => ({ loading: true })))
        });
        request.get('/Setting/GetTimerHistory', {
            params: {
                timerType: record.timerType,
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize,
                showAllRecord: true
            }
        }).then(function (response) {
            if (response && response.success) {
                const data = _this.state.data.concat(response.result.data);
                _this.setState({
                    loading: false,
                    data,
                    list: data,
                    showLoadMore: data.length < response.result.totalRecord ? true : false
                });
            }
        });
    }

    render() {
        return (<>
            <Button type="link" onClick={this.handleOpen} icon={<HistoryOutlined />}>运行记录</Button>
            <Drawer width={this.props.breakpoint.sm === false ? 300 : 450} className="timer-history-drawer" title={<div><span>{this.props.title}</span><Button type="link" onClick={this.handleRefresh} icon={<SyncOutlined />} /></div>} placement="right" visible={this.state.visible} onClose={this.handleClose}>
                <List
                    className="timer-history-list"
                    itemLayout="horizontal"
                    loadMore={
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: 12
                            }}
                        >
                            {this.state.showLoadMore && <Button ghost loading={this.state.loading} type="primary" onClick={this.handleLoadMore}>Load More</Button>}
                            {!this.state.showLoadMore && <Text type="secondary">没有更多数据了~</Text>}
                        </div>
                    }
                    dataSource={this.state.list}
                    renderItem={item => (
                        <List.Item
                        // actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">more</a>]}
                        >
                            <Skeleton title={false} loading={item.loading} active>
                                {/* <List.Item.Meta
                                    title={<Text type={item.status === 1 ? "success" : "danger"} >{record.createTime}</Text>}
                                    description={<Text>{item.comment}</Text>}
                                /> */}
                                <div>
                                    <Text type={item.status === 1 ? "success" : "danger"} >{item.createTime}</Text>
                                    <Text style={{ marginLeft: 8 }}>{item.comment}</Text>
                                </div>
                            </Skeleton>
                        </List.Item>
                    )}
                />
            </Drawer>
        </>);
    }
}

export default withBreakpoint(TrackDetail);