import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import PageLoading from '@components/Common/PageLoading/PageLoading';
import { EditOutlined, DeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import commonConfig from '@common/commonConfig';
import defaultAvatar from '../../../assets/images/avatar_default.png';
import CryptoJs from 'crypto-js';
import request from "@utils/request";
import { Card, Form, Space, Checkbox, Popconfirm, Tag, Avatar, Button, Select, message, Table, Spin, Modal, Alert, Input } from "antd";
import './user.less';

const { Option } = Select;

class UserSetting extends React.Component {
    state = {
        visible: false,
        detailLoading: false,
        detailTitle: null,
        addLoading: false,
        addVisible: false,
        editUser: null,
        isEdit: false,
        roleOptions: null
    }

    form = React.createRef();

    handleDeleteConfirm = (record) => {
        this.setState({ addLoading: true, errorMsg: null });
        const _this = this;
        PageLoading.show();
        request.post(`/User/DeleteUser/${record.id}`).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.loadAllUsers();
                message.success(`删除用户${record.displayName}成功！`);
            }
            if (response && response.success === false) {
                _this.setState({ errorMsg: "删除用户失败！" });
            }
        });
    }

    columns = [
        {
            title: '头像',
            key: 'wxAvatar',
            dataIndex: 'wxAvatar',
            render: (wxAvatar) => (<Avatar src={wxAvatar ? wxAvatar : defaultAvatar} />),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.wxAvatar !== nextRecord.wxAvatar,
            width: '50px'
        },
        {
            title: '名称',
            key: 'displayName',
            dataIndex: 'displayName',
            width: '15%'
        },
        {
            title: '登录名',
            key: 'name',
            dataIndex: 'name',
            width: '15%'
        },
        {
            title: '权限',
            key: 'roles',
            dataIndex: 'roles',
            width: '30%',
            render: (roles) => <Space size={[0, 8]} wrap>
                {(roles?.split(',')?.map(r => {
                    return r ? <Tag key={r} color={this.getColorByRole(r)}>{r}</Tag> : "";
                }))}
            </Space>,
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.roles !== nextRecord.roles,
        },
        {
            title: '可用物流',
            key: 'expressTypeList',
            dataIndex: 'expressTypeList',
            width: '40%',
            render: (expressTypeList) => <Space size={[0, 8]} wrap>{(expressTypeList?.map(eps => {
                const name = this.getExpressNameByType(eps);
                return name ? <Tag key={eps} color="blue">{name}</Tag> : "";
            }))}
            </Space>,
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.expressTypeList !== nextRecord.expressTypeList,
        },
        {
            title: '操作',
            key: 'action',
            render: (record) => (
                <Space wrap>
                    <Button type="primary" onClick={() => this.handleEditUser(record)} shape="round" icon={<EditOutlined />}>编辑</Button>
                    <Popconfirm
                        title="确定要删除此用户么？"
                        onConfirm={() => this.handleDeleteConfirm(record)}
                        onCancel={this.handleCancel}
                    >
                        <Button type="default" shape="circle" icon={<DeleteOutlined />}></Button>
                    </Popconfirm>
                </Space>
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord !== nextRecord
                || prevRecord.id !== nextRecord.id,
            width: '160px'
        }
    ];

    getColorByRole = (role) => {
        switch (role) {
            case "Admin":
                return "volcano";
            case "OrderMgr":
                return "purple";
            case "Packer":
                return "cyan";
            default:
                return "green";
        }
    }

    getExpressNameByType = (expressType) => {
        return this.state?.expressList.find(e => e.expressType === expressType)?.displayName;
    }

    handleAddOpen = () => {
        if (this.controller) {
            this.controller.abort();
        }
        this.setState({ addVisible: true, isEdit: false, errorMsg: null }, () => {
            this.form.current.resetFields();
        });
    }

    handleAddCancel = () => {
        this.setState({ addVisible: false });
    }

    handleAddOk = () => {
        this.form.current.validateFields().then(values => {
            const controller = new AbortController();
            const { signal } = controller;
            this.controller = controller;
            this.setState({ addLoading: true, errorMsg: null });
            const _this = this;
            if (this.state.isEdit) {
                request.post('/User/UpdateUser', {
                    signal,
                    data: {
                        NeedChangePassword: values.needChangePassword,
                        User: {
                            ...this.state.editUser,
                            ...values,
                            roles: values.roles?.join(','),
                            expressTypes: values.expressTypeList?.sort()?.join(';'),
                            password: CryptoJs.HmacSHA256(CryptoJs.MD5(values.password), commonConfig.encryptionKey).toString(CryptoJs.enc.Base64)
                        }
                    }
                }).then(function (response) {
                    _this.setState({ addLoading: false });
                    if (response && response.success) {
                        _this.setState({ addVisible: false });
                        _this.loadAllUsers();
                        message.success(`编辑用户${values.displayName}成功！`);
                    }
                    if (response && response.success === false) {
                        _this.setState({ errorMsg: "更新用户信息失败！" });
                    }
                });
            } else {
                request.post('/User/AddUser', {
                    signal,
                    data: {
                        ...values, roles: values.roles?.join(','),
                        expressTypes: values.expressTypeList?.sort()?.join(';'),
                        password: CryptoJs.HmacSHA256(CryptoJs.MD5(values.password), commonConfig.encryptionKey).toString(CryptoJs.enc.Base64)
                    }
                }).then(function (response) {
                    _this.setState({ addLoading: false });
                    if (response && response.success) {
                        _this.setState({ addVisible: false });
                        _this.loadAllUsers();
                        message.success(`添加用户${values.displayName}成功！`);
                    }
                    if (response && response.success === false) {
                        _this.setState({ errorMsg: "添加用户失败！" });
                    }
                });
            }
        }).catch(errorInfo => { });
    }

    handleEditUser = (record) => {
        if (this.controller) {
            this.controller.abort();
        }
        this.setState({
            addVisible: true,
            isEdit: true,
            addLoading: false,
            editUser: record,
            errorMsg: null,
            needChangePassword: false
        }, () => {
            this.form.current.setFieldsValue({
                ...record,
                needChangePassword: false,
                roles: record?.roles?.split(',')
            });
        });
    }

    loadAllUsers = () => {
        const _this = this;
        PageLoading.show();
        request.get('/User/GetAllUsers').then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.setState({ dataSource: response.results });
            }
            if (response && response.success === false) {
                message.error("系统异常，获取用户列表失败！");
            }
        });
    }

    handleChangeClick = (e) => {
        this.setState({ needChangePassword: e.target.checked });
    }

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/User/GetUserParams').then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                _this.setState({
                    roleOptions: response.result.roleOptions,
                    expressList: response.result.expressList,
                });
                _this.loadAllUsers();
            }
        });
    }

    render() {
        return (<PageContainer className="user-setting-page">
            <Card loading={this.state.loading}>
                <Button type="primary" onClick={this.handleAddOpen} icon={<UserAddOutlined />} ghost>
                    添加用户
                </Button>
                <Table rowKey='id'
                    columns={this.columns}
                    style={{ marginTop: 12 }}
                    dataSource={this.state.dataSource}
                    tableLayout='fixed'
                    size="middle"
                    scroll={{ x: 950 }}
                    pagination={false} />
            </Card>
            <Modal title={this.state.isEdit ? "编辑用户" : "添加用户"}
                okButtonProps={{ loading: this.state.addLoading }}
                maskClosable={false}
                visible={this.state.addVisible}
                onOk={this.handleAddOk}
                width={800}
                onCancel={this.handleAddCancel}
            >
                <Spin spinning={this.state.addLoading}>
                    {!!this.state.errorMsg && <Alert message={this.state.errorMsg} style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                    <Form ref={this.form} onValuesChange={this.handleValueChange} labelAlign="left" labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} initialValues={{

                    }}>
                        <Form.Item label="名称" name="displayName" rules={[{ required: true }]}>
                            <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item label="登录名" name="name" rules={[{ required: true }]}>
                            <Input autoComplete="off" />
                        </Form.Item>
                        {this.state.isEdit &&
                            <Form.Item label="修改密码" valuePropName="checked" name="needChangePassword">
                                <Checkbox onChange={this.handleChangeClick}>修改密码</Checkbox>
                            </Form.Item>
                        }
                        {(this.state.needChangePassword || !this.state.isEdit) &&
                            <Form.Item label="密码" name="password" rules={[{ required: true }]}>
                                <Input.Password autoComplete="new-password" />
                            </Form.Item>
                        }
                        <Form.Item label="权限" name="roles">
                            <Checkbox.Group options={this.state.roleOptions}></Checkbox.Group>
                        </Form.Item>
                        <Form.Item label="可用物流" name="expressTypeList" >
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                            >
                                {this.state.expressList?.map(s => <Option key={s.expressType} value={s.expressType}>{s.displayName}</Option>)}
                            </Select>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </PageContainer >);
    }
}

export default UserSetting;