import React from "react";
import request from "@utils/request";
import { Modal, Tooltip, Button, message } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import PageLoading from '@components/Common/PageLoading/PageLoading';

const MarkOrder = (props) => {
    const { record } = props;
    const handleOk = () => {
        PageLoading.show();
        request.post('/Order/MarkOrder', {
            data: {
                id: record.id,
                progressStatus: record.progressStatus
            }
        }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                if (props.afterUpdate) {
                    props.afterUpdate({
                        id: record.id,
                        progressStatus: record.progressStatus === 0 ? 1 : 0
                    });
                }
            }
            if (response && response.success === false) {
                message.error("系统异常，标记订单失败！");
            }
        });
    };

    const handleOpen = () => {
        Modal.confirm({
            title: '标记订单',
            content: props.record.progressStatus === 0 ? '确定标记此单为问题单？' : '确定将此单恢复为正常单？',
            onOk: handleOk
        })
    }

    return (
        <>
            <Tooltip title="标记订单">
                <Button type="primary" onClick={handleOpen} shape="circle" icon={<WarningOutlined />} />
            </Tooltip>
        </>
    );
};

export default MarkOrder;