import cookie from 'react-cookies';
import isLogedIn, { setAccountInfo } from '@utils/authority';
import queryString from 'query-string';

const WXRedirect = (props) => {
    let ck = cookie.load("ecp_account_ck");
    cookie.remove("ecp_account_ck", { path: '/' });
    setAccountInfo(ck);
    let returnUrl = queryString.parse(props.location.search)?.returnUrl;
    returnUrl = returnUrl ? returnUrl : "/";
    if (isLogedIn()) {
        let reg = new RegExp('^(?:[a-z]+:)?//', 'i');
        if (returnUrl && !reg.test(returnUrl)) {
            props.history.push(returnUrl);
        } else {
            props.history.push('/home/dashboard');
        }
    } else {
        props.history.push(`/auth/login?returnUrl=${encodeURIComponent(returnUrl)}`);
    }
    return '';
}

export default WXRedirect;