import React from "react";
import { Tooltip, Button } from 'antd';
import IconFont from '@components/Common/IconFont';


class SpareDetailButton extends React.Component {
    handleOpen = () => {
        window.open(`/spare/detail?spare=${this.props.record?.id}`);
    }

    render() {
        return (
            <>
                <Tooltip title="备品详情">
                    <Button type="primary" onClick={this.handleOpen} shape="circle" icon={<IconFont type="icon-list" />} />
                </Tooltip>
            </>
        );
    }
};

export default SpareDetailButton;