import React from "react";
import { Button, Image, Modal, Spin, Alert, Upload, message } from "antd";
import { InboxOutlined, EditOutlined } from '@ant-design/icons';
import request from "@utils/request";
import commonConfig from '@common/commonConfig';
import FallbackImage from '@common/CommonImage';
import classNames from 'classnames';
import './DesignImageContainer.less';

const { Dragger } = Upload;

class DesignImageContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            previewVisible: false,
            hasError: false,
            loading: false,
            visible: false,
            designCurrent: 0,
            fileList: null
        }
    }

    generateFileFromRecord = (record) => {
        const { designImageSmallList, designImageList } = record;
        return designImageSmallList?.length > 0 ? designImageSmallList?.map((d, idx) => {
            return {
                name: 'Design Image',
                status: 'done',
                fullUrl: designImageList[idx],
                url: d
            }
        }) : [];
    }

    handleOpen = () => {
        const { record } = this.props;
        if (this.controller) {
            this.controller.abort();
        }
        const fileList = this.generateFileFromRecord(record);
        this.setState({
            visible: true,
            hasError: false,
            fileList: fileList
        });
    }

    handleOk = () => {
        const { record } = this.props;
        const newImages = this.state.fileList.filter(f => f.status === 'done');
        const _this = this;
        this.setState({
            loading: true,
            hasError: false,
        });
        const controller = new AbortController();
        const { signal } = controller;
        this.controller = controller;
        request.post('/Order/SavePreviewImage/', {
            signal,
            data: {
                id: record.id,
                designImageSmallList: newImages.map(f => f.url),
                designImageList: newImages.map(f => f.fullUrl)
            }
        }).then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                _this.setState({ visible: false });
                message.success('编辑效果图成功！');
                if (_this.props.afterUpdate) {
                    _this.props.afterUpdate({
                        id: _this.props.record.id,
                        designImageSmallList: newImages.map(f => f.url),
                        designImageList: newImages.map(f => f.fullUrl)
                    });
                }
            }
            if (response && response.success === false) {
                _this.setState({ hasError: true });
            }
        })
    }

    handleCancel = () => {
        this.setState({ visible: false });
    }

    handleChange = (info) => {
        const { file, fileList } = info;
        if (file.name === this.dropName) {
            return;
        }
        const newFileList = [...fileList];
        const newFile = newFileList.find(f => f.uid === file.uid);
        switch (file.status) {
            case 'uploading':
                newFile.percent = 30;
                break;
            case 'done':
                newFile.url = file.response.result.thumbnailPath;
                newFile.fullUrl = file.response.result.path;
                newFile.originFileObj = null;
                newFile.optionName = "Design Image";
                break;
            case 'error':
                newFile.originFileObj = null;
                break;
            case 'removed':
                break;
            default:
                const isLt4M = file.size <= commonConfig.allowUploadSize;
                newFile.status = 'error';
                newFile.originFileObj = null;
                newFile.error = { message: isLt4M ? '文件类型不支持' : '文件大小超过限制' };
                break;
        }
        this.setState({
            fileList: newFileList
        });
    }

    customRequest = (options) => {
        const { onSuccess, onError, file } = options;
        if (file.name === this.dropName) {
            this.dropName = "";
            return;
        }
        const formData = new FormData();
        formData.append('files', file);
        formData.append('lineId', this.props.record.id);
        request('/Order/UploadPreviewImage', { method: 'post', data: formData, requestType: 'form', }).then(function (response) {
            if (response && response.success) {
                onSuccess(response, file);
            }
            if (response && response.success === false) {
                onError({ message: response.errorMsg }, response);
            }
        });
    }

    beforeUpload = (file) => {
        const isImage = commonConfig.allowImageType.some(t => t.indexOf(file.type.split('/')[1]) > -1);
        const isLt4M = file.size <= commonConfig.allowUploadSize;
        return isImage && isLt4M;
    }

    handleDrop = (e) => {
        if (e.dataTransfer.types.indexOf('text/uri-list') > -1) {
            const imageUrl = e.dataTransfer.getData('text/uri-list');
            var subUrl = imageUrl.split('?')[0];
            var imageReg = /http(s?):\/\/\S*?\.(png|jpg|gif|jpeg|bmp|webp)$/i;
            if (imageReg.test(subUrl)) {
                if (e.dataTransfer.files?.length > 0) {
                    this.dropName = e.dataTransfer.files[0].name;
                }
                const _this = this;
                let fileList = [...this.state.fileList];
                var filename = subUrl.substring(subUrl.lastIndexOf('/') + 1);
                const newFile = {
                    uid: -(new Date().getTime()),
                    name: filename,
                    status: 'uploading',
                    percent: 30,
                    optionName: "Design Image"
                }
                fileList.push(newFile);
                this.setState({
                    fileList: fileList
                })
                request.post(`/Order/UploadPreviewImageUrl/${this.props.record.id}`, {
                    data: {
                        value: imageUrl
                    }
                }).then(function (response) {
                    const idx = _this.state.fileList.findIndex(f => f.uid === newFile.uid);
                    const flist = [..._this.state.fileList];
                    if (response && response.success) {
                        const finishFile = {
                            ...newFile,
                            status: 'done',
                            percent: 100,
                            url: response.result.thumbnailPath,
                            fullUrl: response.result.path
                        };
                        flist[idx] = finishFile;
                    }
                    if (response && response.success === false) {
                        //onError({ message: response.errorMsg }, response);
                        const finishFile = {
                            ...newFile,
                            status: 'error',
                            percent: 100,
                            error: { message: response.errorMsg }
                        };
                        flist[idx] = finishFile;
                    }
                    _this.setState({
                        fileList: flist
                    });
                })
            }
        }
    }

    render() {
        const { cols = 3, record } = this.props;
        const { designImageSmallList, designImageList } = record;
        return <div className={classNames({
            "design-image-container": true,
            "design-image-container-has-btn": this.props.editable,
            "design-image-container-empty": !this.props.editable && !(designImageSmallList?.length > 0)
        })}>
            {
                designImageSmallList?.length > 0 && <>
                    <div className="small-image-container">
                        {
                            designImageSmallList.map((img, idx) =>
                                <div key={idx} style={{
                                    display: 'inline-block',
                                    width: `calc(${100 / cols}% - 6px)`,
                                    margin: `0 ${(idx + 1) % cols === 0 ? 0 : 6 * cols / (cols - 1)}px ${(idx + 1) > (Math.ceil(designImageSmallList.length / cols) - 1) * cols ? 0 : 6 * cols / (cols - 1)}px 0`,
                                    // fontSize: 0
                                }}>
                                    <Image
                                        preview={{ visible: false }}
                                        src={img}
                                        fallback={FallbackImage}
                                        onClick={() => this.setState({ previewVisible: true, designCurrent: idx })}
                                    />
                                </div>
                            )
                        }
                    </div>
                    <div style={{ display: 'none' }}>
                        <Image.PreviewGroup preview={{ current: this.state.designCurrent, visible: this.state.previewVisible, onVisibleChange: vis => this.setState({ previewVisible: vis }) }}>
                            {
                                designImageList.map((img, idx) => <Image key={idx} src={img} />)
                            }
                        </Image.PreviewGroup>
                    </div>
                </>
            }
            {
                this.props.editable && <div className="edit-btn-container">
                    <Button type="link" disabled={this.props.orderStatus > 4 ? true : false} icon={<EditOutlined />} onClick={this.handleOpen}>
                        编辑效果图
                    </Button>
                </div>
            }
            <Modal title="编辑效果图"
                className="design-image-modal"
                okButtonProps={{ loading: this.state.loading }}
                maskClosable={false}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
            >
                <Spin spinning={this.state.loading}>
                    {this.state.hasError && <Alert message="编辑效果图失败！" style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                    <Dragger
                        name="file"
                        multiple={true}
                        listType="picture-card"
                        showUploadList={{
                            showPreviewIcon: false,
                            showRemoveIcon: true,
                            showDownloadIcon: true
                        }}
                        onDownload={(file) => { window.open(file.fullUrl) }}
                        accept={commonConfig.allowImageType.join(',')}
                        fileList={this.state.fileList}
                        onChange={this.handleChange}
                        customRequest={this.customRequest}
                        onDrop={this.handleDrop}
                        beforeUpload={this.beforeUpload}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">点击或拖动文件到此区域上传</p>
                        <p className="ant-upload-hint">
                            支持单个或批量上传，单个图片上传大小限制为4M。
                        </p>
                    </Dragger>
                </Spin>
            </Modal>
        </div>;
    }
}

export default DesignImageContainer;