import React from 'react';
import { PageContainer } from "@ant-design/pro-layout";
import DatabaseAction from '@components/Statistics/DatabaseAction/DatabaseAction';
import TimerAction from '@components/Statistics/TimerAction/TimerAction';
import { Card, Row, Col } from "antd";
import './statistics-action.less';

class StatisticsAction extends React.Component {
    state = {
        loading: false
    }
    form = React.createRef();

    render() {
        return (<PageContainer className="statistics-action-page">
            <Card loading={this.state.loading}>
                <Row gutter={[24, 24]}>
                    <Col sm={24} md={12}>
                        <DatabaseAction title="数据库备份状态" />
                    </Col>
                    <Col sm={24} md={12}>
                        <TimerAction title="Timer执行状态" />
                    </Col>
                </Row>
            </Card>
        </PageContainer >);
    }
}

export default StatisticsAction;