import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import PageLoading from '@components/Common/PageLoading/PageLoading';
import commonConfig from '@common/commonConfig';
import request from "@utils/request";
import { Card, Table, Input, Tooltip, Row, Col } from "antd";
import NewVariantInfo from "@components/Product/NewVariantInfo/NewVariantInfo";
import EditVariantInfo from "@components/Product/EditVariantInfo/EditVariantInfo";
import './variant-info.less';

const { Search } = Input;

class ProductVariantInfo extends React.Component {
    state = {
        loading: false,
        pageNumber: 1,
        pageSize: commonConfig.pageSize,
        dataSource: null,
        totalCount: 0
    }
    search = React.createRef();

    columns = [
        {
            title: '款式名称',
            key: 'cnTitle',
            dataIndex: 'cnTitle',
            width: 30
        },
        {
            title: '商家编号',
            key: 'vendorCode',
            dataIndex: 'vendorCode',
            width: 30
        },
        {
            title: '创建时间',
            key: 'createTimeString',
            dataIndex: 'createTimeString',
            width: 25
        },
        {
            title: '操作',
            key: 'action',
            render: (record) => (
                <EditVariantInfo record={record} afterUpdate={(newVariant) => {
                    const newSource = [...this.state.dataSource];
                    const idx = newSource.findIndex(x => x.id === record.id);
                    newSource[idx] = { ...record, ...newVariant };
                    this.setState({ dataSource: newSource });
                }} />
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord !== nextRecord,
            width: 15
        },
    ];

    componentDidMount() {
        this.loadData();
    }

    handlePageChange = (page, pageSize) => {
        this.setState({
            pageNumber: page,
            pageSize: pageSize
        }, this.loadData);
    }

    loadData = () => {
        const _this = this;
        PageLoading.show();
        request.get('/Product/GetVariantInfos', {
            params: {
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize,
                name: this.search.current.input.value
            }
        }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.setState({
                    dataSource: response.result.data,
                    totalCount: response.result.totalRecord
                });
            }
        });
    }

    onSearch = (value) => {
        this.setState({ pageNumber: 1 }, this.loadData);
    }

    render() {
        return (<PageContainer className="product-vendor-info-page">
            <Card loading={this.state.loading}>
                <Row gutter={[12, 12]}>
                    <Col xs={24} sm={12} md={8}>
                        <NewVariantInfo afterUpdate={() => this.loadData()} />
                    </Col>
                    <Col xs={24} sm={12} md={{ span: 8, push: 8 }}>
                        <Tooltip title="搜索款式名称、商家编号">
                            <Search ref={this.search} placeholder="搜索..." allowClear onSearch={this.onSearch} />
                        </Tooltip>
                    </Col>
                </Row>
                <Table rowKey='id'
                    columns={this.columns}
                    style={{ marginTop: 12 }}
                    dataSource={this.state.dataSource}
                    tableLayout='fixed'
                    size="middle"
                    scroll={{ x: 950 }}
                    pagination={{
                        pageSize: this.state.pageSize,
                        current: this.state.pageNumber,
                        total: this.state.totalCount,
                        showQuickJumper: true,
                        size: 'default',
                        onChange: this.handlePageChange
                    }} />
            </Card>
        </PageContainer>);
    }
}

export default ProductVariantInfo;