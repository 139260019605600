import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import request from "@utils/request";
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import winPic from '../../../assets/images/windows_10_log_531x503.png';
import awsPic from '../../../assets/images/aws_smile_header_59x35.png';
import { Card, Row, Col, Table, Typography } from "antd";
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';
import './system-info.less';

const { Text } = Typography;

const startTime = new moment().format("HH:mm:ss");
const cpuOption = {
    grid: {
        left: 5,
        top: 2,
        bottom: 1,
        right: 5,
        show: true,
        borderColor: '#66aad3',
        zlevel: 99
    },
    tooltip: {
        trigger: 'axis',
        formatter: function (params) {
            params = params[0];
            return params.name + ' : ' + params.value[1] + "%";
        },
        axisPointer: {
            animation: false
        }
    },
    xAxis: {
        type: 'category',
        boundaryGap: false,
        splitLine: {
            show: true,
            lineStyle: {
                color: '#e8f2f7'
            },
            interval: 4
        },
        max: 30,
        axisLabel: {
            show: false,
            interval: 0
        },
        axisLine: {
            show: false,
        },
        axisTick: {
            show: false,
        },
        inverse: true,
        data: [startTime]
    },
    yAxis: {
        type: 'value',
        boundaryGap: false,
        min: 0,
        max: 100,
        interval: 10,
        axisLabel: {
            show: false,
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: '#e8f2f7'
            }
        }
    },
    series: [{
        name: 'CPU Usage',
        type: 'line',
        showSymbol: false,
        hoverAnimation: false,
        lineStyle: {
            color: '#66aad3',
            width: 1
        },
        areaStyle: {
            color: '#eaf5fd'
        },
        data: [[startTime, 0]]
    }]
};

class SystemInfo extends React.Component {
    state = {
        loading: false,
    }
    cpuXData = [startTime];
    cpuYData = [[startTime, 0]];
    memoryYData = [[startTime, 0]];
    cpuChart = React.createRef();
    memoryChart = React.createRef();

    columns = [
        {
            title: '文件名',
            key: 'name',
            dataIndex: 'name',
            width: 30
        },
        {
            title: '上传时间',
            key: 'lastModified',
            dataIndex: 'lastModified',
            width: 30
        },
        {
            title: '文件大小',
            key: 'size',
            dataIndex: 'size',
            width: 20
        },
        {
            title: '储存类别',
            key: 'storageClass',
            dataIndex: 'storageClass',
            width: 20
        }
    ];

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/Home/GetWindowsMaxMemory').then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                _this.maxMemory = response.result;
                _this.setState({
                    maxConvertMemory: (response.result / 1024).toFixed(1)
                });
            }
            _this.getWindowsDisks();
            _this.chartInterval = setInterval(() => {
                _this.getUsage();
            }, 2000);
        });
        this.getAmzStorageSize();
    }

    componentWillUnmount() {
        clearInterval(this.chartInterval);
    }

    getWindowsDisks = () => {
        const _this = this;
        this.setState({ windowsDiskLoading: true });
        request.get('/Home/GetWindowsDisks').then(function (response) {
            _this.setState({ windowsDiskLoading: false });
            if (response && response.success) {
                _this.setState({
                    windowsDisks: response.results
                });
            }
        });
    }

    getAmzStorageSize = () => {
        const _this = this;
        this.setState({ amzloading: true });
        request.get('/Home/getAmzStorageSize').then(function (response) {
            _this.setState({ amzloading: false });
            if (response && response.success) {
                _this.setState({
                    amzSize: response.result.amzStorageSize,
                    dataSource: response.result.backupFiles
                });
            }
        });
    }

    getUsage = () => {
        const _this = this;
        request.get('/Home/GetServerUsage').then(function (response) {
            if (!_this.cpuChart.current) {
                return;
            }
            if (response && response.success) {
                var xData = response.result.name;
                if (_this.cpuXData.length >= 31) {
                    _this.cpuXData.pop();
                }
                _this.cpuXData.unshift(xData);
                if (_this.cpuYData.length >= 31) {
                    _this.cpuYData.pop();
                }
                _this.cpuYData.unshift([xData, response.result.cpuValue]);
                if (_this.memoryYData.length >= 31) {
                    _this.memoryYData.pop();
                }
                const usedMemory = _this.maxMemory - response.result.memoryValue;
                const memoryPercentage = (usedMemory / _this.maxMemory).toFixed(2) * 100;
                var mdata = [xData, memoryPercentage, (usedMemory / 1024).toFixed(1)];
                _this.memoryYData.unshift(mdata);
                _this.cpuChart.current.getEchartsInstance().setOption({
                    xAxis: {
                        data: _this.cpuXData
                    },
                    series: [{
                        data: _this.cpuYData
                    }]
                });
                _this.memoryChart.current.getEchartsInstance().setOption({
                    xAxis: {
                        data: _this.cpuXData
                    },
                    series: [{
                        data: _this.memoryYData
                    }]
                });
            }
        });
    }

    render() {
        const _this = this;
        const memoryOption = {
            grid: {
                left: 5,
                top: 2,
                bottom: 1,
                right: 5,
                show: true,
                borderColor: '#b66acc',
                zlevel: 99
            },
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    params = params[0];
                    return params.name + ' : ' + params.value[2] + "/" + _this.state.maxConvertMemory + " GB (" + params.value[1] + "%)";
                },
                axisPointer: {
                    animation: false
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#eae2ec'
                    },
                    interval: 4
                },
                max: 30,
                axisLabel: {
                    show: false,
                    interval: 0
                },
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                inverse: true,
                data: this.cpuXData
            },
            yAxis: {
                type: 'value',
                boundaryGap: false,
                min: 0,
                max: 100,
                interval: 10,
                axisLabel: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#eae2ec'
                    }
                }
            },
            series: [{
                name: 'CPU Usage',
                type: 'line',
                showSymbol: false,
                hoverAnimation: false,
                lineStyle: {
                    color: '#b66acc',
                    width: 1
                },
                areaStyle: {
                    color: '#f4f2f4'
                },
                data: this.memoryYData
            }]
        };

        return (<PageContainer className="admin-tool-page">
            <Card loading={this.loading} size="small" className="win-card" title={
                <div className="img-card-title">
                    <img alt="Windows VM" width={24} src={winPic} />
                    <span style={{ marginLeft: 12 }}>Windows VM</span>
                </div>
            }>
                <Row style={{ marginTop: 12 }}>
                    <Col span={24}>
                        <div className="windows-disk-container">
                            {
                                this.state.windowsDiskLoading && <div className="windows-disk-panel">
                                    <LoadingOutlined style={{
                                        fontSize: '28px',
                                        marginLeft: '24px',
                                        marginTop: '18px'
                                    }} />
                                </div>
                            }
                            {
                                !this.state.windowsDiskLoading && this.state.windowsDisks?.map((disk, idx) => <div key={idx} className={classNames({
                                    "windows-disk-panel": true,
                                    "windows-system-disk": disk.isSystemDisk
                                })}>
                                    <div className="windows-disk-name">Local Disk ({disk.name})</div>
                                    <div className="windows-disk-volume">
                                        <div className={classNames({
                                            "windows-disk-bar": true,
                                            "bg-danger": disk.usedPercentage > 90
                                        })} style={{ width: `${disk.usedPercentage}%` }}></div>
                                    </div>
                                    <Text style={{ fontSize: 13, lineHeight: '1.3' }} type="secondary">{disk.freeSpace} free of {disk.totalSize}</Text>
                                </div>)
                            }
                        </div>
                    </Col>
                </Row>
                <Row gutter={[48, 24]} style={{ marginTop: 12 }}>
                    <Col xs={24} sm={24} md={12}>
                        <div className="chart-title">CPU</div>
                        <div className="chart-comment clearfix"><Text style={{ float: 'left', paddingLeft: 5 }} type="secondary">% Utilization</Text><Text type="secondary" style={{ float: 'right', paddingRight: 5 }}>100%</Text></div>
                        <ReactEcharts ref={this.cpuChart} style={{ height: 200 }} option={cpuOption}></ReactEcharts>
                        <div className="chart-comment clearfix"><Text style={{ float: 'left', paddingLeft: 5 }} type="secondary">60 seconds</Text><Text type="secondary" style={{ float: 'right', paddingRight: 5 }}>0</Text></div>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <div className="chart-title">Memory</div>
                        <div className="chart-comment clearfix"><Text style={{ float: 'left', paddingLeft: 5 }} type="secondary">Memory usage</Text><Text type="secondary" style={{ float: 'right', paddingRight: 5 }}>{this.state.maxConvertMemory} GB</Text></div>
                        <ReactEcharts ref={this.memoryChart} style={{ height: 200 }} option={memoryOption}></ReactEcharts>
                        <div className="chart-comment clearfix"><Text style={{ float: 'left', paddingLeft: 5 }} type="secondary">60 seconds</Text><Text type="secondary" style={{ float: 'right', paddingRight: 5 }}>0</Text></div>
                    </Col>
                </Row>
            </Card>
            <Card className="aws-card" size="small" loading={this.state.amzloading} title={
                <div className="img-card-title">
                    <img alt="Amazon AWS" width={38} src={awsPic} />
                    <span style={{ marginLeft: 12 }}>Amazon AWS</span>
                </div>
            }>
                <div>
                    <span>Amazon S3 Storage: </span>
                    <span style={{ marginLeft: 12 }}>{this.state.amzSize}</span>
                </div>
                <Table rowKey='name'
                    columns={this.columns}
                    dataSource={this.state.dataSource}
                    tableLayout='fixed'
                    size="middle"
                    scroll={{ x: 950 }}
                    pagination={false} />
            </Card>
        </PageContainer >);
    }
}

export default SystemInfo;