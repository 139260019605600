import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import commonConfig from '@common/commonConfig';
import commonUtil from "@utils/commonUtil";
import request from "@utils/request";
import { Card, Form, Input, Row, Col, DatePicker, Button, message, InputNumber } from "antd";
import './admin-tool.less';

const { RangePicker } = DatePicker;

class AdminTool extends React.Component {
    state = {
        loading: false,
        chinaTimeLoading: false,
        ticksLoading: false,
        rangeLoading: false,
        calculateLoading: false
    }
    chinaTimeForm = React.createRef();
    ticksForm = React.createRef();
    rangeForm = React.createRef();
    calculateForm = React.createRef();

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/Setting/GetCommonSettingConfig').then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                _this.calculateForm.current.setFieldsValue({
                    packagePrice: response.result.customConfig.packagePriceSettingInfo.packageUnitPrice,
                    giftPrice: response.result.customConfig.packagePriceSettingInfo.giftPackagingUnitPrice
                })
            }
            if (response && response.success === false) {
                message.error("系统异常，获取默认设置失败！");
            }
        });

    }

    handleConvertChinaTime = () => {
        this.chinaTimeForm.current.validateFields().then(values => {
            const _this = this;
            this.setState({ chinaTimeLoading: true });
            request.get('/Tool/ConvertChinaTimeToUTCTicks', {
                params: {
                    chinaTime: values.chinaTime.format(commonConfig.dateTimeFormat),
                }
            }).then(function (response) {
                _this.setState({ chinaTimeLoading: false });
                if (response && response.success) {
                    _this.setState({ chinaTimeResult: response.result });
                }
                if (response && response.success === false) {
                    message.error("系统异常，转换中国时间失败！");
                }
            });
        }).catch(errorInfo => { });
    }

    handleConvertTicks = () => {
        this.ticksForm.current.validateFields().then(values => {
            const _this = this;
            this.setState({ ticksLoading: true });
            request.get('/Tool/ConvertTicksToDatetime', {
                params: {
                    ticks: values.ticks
                }
            }).then(function (response) {
                _this.setState({ ticksLoading: false });
                if (response && response.success) {
                    _this.setState({ ticksResult: response.result });
                }
                if (response && response.success === false) {
                    message.error("系统异常，转换Ticks失败！");
                }
            });
        }).catch(errorInfo => { });
    }

    handleRangeClick = () => {
        this.rangeForm.current.validateFields().then(values => {
            const _this = this;
            this.setState({ rangeLoading: true });
            request.get('/Tool/CalculatePackageCount', {
                params: {
                    startDate: values.range[0].format(commonConfig.dateTimeFormat),
                    endDate: values.range[1].format(commonConfig.dateTimeFormat)
                }
            }).then(function (response) {
                _this.setState({ rangeLoading: false });
                if (response && response.success) {
                    _this.calculateForm.current.setFieldsValue({
                        packageCount: response.result.packageCount,
                        giftCount: response.result.giftCount,
                    });
                }
                if (response && response.success === false) {
                    message.error(response.errorMsg);
                }
            });
        }).catch(errorInfo => { });
    }

    handleCalculate = () => {
        this.calculateForm.current.validateFields().then(values => {
            this.setState({
                packageResult: values.packagePrice * values.packageCount + values.giftPrice * values.giftCount
            });
        }).catch(errorInfo => { });
    }

    render() {
        return (<PageContainer className="admin-tool-page">
            <Card title="时间转换" loading={this.state.loading}>
                <Row gutter={24}>
                    <Col xs={24} sm={12}>
                        <Form ref={this.chinaTimeForm}>
                            <Form.Item label="中国时间" style={{ marginBottom: 0 }}>
                                <Form.Item name="chinaTime" rules={[{ required: true, message: "请输入中国时间" }]}
                                    style={{ display: 'inline-block' }}>
                                    <DatePicker showTime />
                                </Form.Item>
                                <Form.Item
                                    style={{ display: 'inline-block', marginLeft: 12 }}>
                                    <Button loading={this.state.chinaTimeLoading} onClick={this.handleConvertChinaTime} type="primary" ghost >转换</Button>
                                </Form.Item>
                            </Form.Item>
                        </Form>
                        <div>UTC Ticks: {this.state.chinaTimeResult}</div>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form ref={this.ticksForm}>
                            <Form.Item label="Ticks" style={{ marginBottom: 0 }}>
                                <Form.Item name="ticks" rules={[{ required: true, message: "请输入Ticks" }]}
                                    style={{ display: 'inline-block', width: 220 }}>
                                    <Input autoComplete="off" />
                                </Form.Item>
                                <Form.Item
                                    style={{ display: 'inline-block', marginLeft: 12 }}>
                                    <Button loading={this.state.ticksLoading} onClick={this.handleConvertTicks} type="primary" ghost >转换</Button>
                                </Form.Item>
                            </Form.Item>
                        </Form>
                        <div>UTC 时间: {this.state.ticksResult}</div>
                    </Col>
                </Row>
            </Card>
            <Card style={{ marginTop: 24 }} title="打包统计" loading={this.state.loading}>
                <Form ref={this.rangeForm}>
                    <Form.Item label="时间范围" style={{ marginBottom: 0 }}>
                        <Form.Item name="range" style={{ display: 'inline-block' }} rules={[{ required: true, message: "请输入时间范围" }]}>
                            <RangePicker showTime format={commonConfig.dateTimeFormat} />
                        </Form.Item>
                        <Form.Item
                            style={{ display: 'inline-block', marginLeft: 12 }}>
                            <Button loading={this.state.rangeLoading} onClick={this.handleRangeClick} type="primary" ghost >统计</Button>
                        </Form.Item>
                    </Form.Item>
                </Form>
                <Form layout="inline" ref={this.calculateForm}>
                    <Form.Item style={{ marginRight: 4 }}>￥</Form.Item>
                    <Form.Item name="packagePrice" rules={[{ required: true, message: "请输入打包价格" }, { validator: (_, value) => commonUtil.greaterThanZero(_, value, "") }]}>
                        <InputNumber placeholder="打包价格" />
                    </Form.Item>
                    <Form.Item style={{ fontSize: 18 }}>x</Form.Item>
                    <Form.Item name="packageCount" style={{ marginRight: 0 }} rules={[{ required: true, message: "请输入打包数量" }, { validator: (_, value) => commonUtil.isPositiveNumber(_, value, "") }]}>
                        <InputNumber placeholder="打包数量" />
                    </Form.Item>
                    <Form.Item style={{ marginRight: 8 }}>（打包数量）</Form.Item>
                    <Form.Item style={{ fontSize: 18 }}>+</Form.Item>
                    <Form.Item style={{ marginRight: 4 }}>￥</Form.Item>
                    <Form.Item name="giftPrice" rules={[{ required: true, message: "请输入包装价格" }, { validator: (_, value) => commonUtil.greaterThanZero(_, value, "") }]}>
                        <InputNumber placeholder="包装价格" />
                    </Form.Item>
                    <Form.Item style={{ fontSize: 18 }}>x</Form.Item>
                    <Form.Item name="giftCount" style={{ marginRight: 0 }} rules={[{ required: true, message: "请输入包装数量" }, { validator: (_, value) => commonUtil.isPositiveNumber(_, value, "") }]}>
                        <InputNumber placeholder="包装数量" />
                    </Form.Item>
                    <Form.Item style={{ marginRight: 8 }}>（包装数量）</Form.Item>
                    <Form.Item style={{ fontSize: 18 }}>=</Form.Item>
                    <Form.Item style={{ minWidth: 60 }}>{this.state.packageResult}</Form.Item>
                    <Form.Item>
                        <Button loading={this.state.calculateLoading} onClick={this.handleCalculate} type="primary" ghost >合计</Button>
                    </Form.Item>
                </Form>
            </Card>
        </PageContainer >);
    }
}

export default AdminTool;