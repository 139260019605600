import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import { FileTextOutlined, GiftOutlined, WarningOutlined } from '@ant-design/icons';
import { Card, Button, Row, Col, Space, Select, Tooltip, Form, Input, Pagination, Typography, Image, message, Modal } from "antd";
import request from "@utils/request";
import commonConfig from '@common/commonConfig';
import PageLoading from '@components/Common/PageLoading/PageLoading';
import ExpressForm from '@components/Order/ExpressForm/ExpressForm';
import ImageContainer from '@components/Order/ImageContainer/ImageContainer';
import DesignImageContainer from "@components/Order/DesignImageContainer/DesignImageContainer";
import TableContainer from "@components/Common/TableContainer/TableContainer";
import FallbackImage from '@common/CommonImage';
import IconFont from '@components/Common/IconFont';
import './package.less';

const { Option } = Select;
const { Search } = Input;
const { Text, Link } = Typography;

const topStatusSelectProps = {
    xs: 24,
    sm: 12,
    md: 8,
    lg: 8,
    xl: 8,
};

const topLogisticsSelectProps = {
    xs: 24,
    sm: 12,
    md: 8,
    lg: 8,
    xl: 8,
};

const topSearchSelectProps = {
    xs: 24,
    sm: {
        span: 12,
        push: 12
    },
    md: {
        span: 8,
        push: 0
    },
    lg: {
        span: 8,
        push: 0
    },
    xl: 8,
};

class OrderPackage extends React.Component {
    state = {
        loading: false,
        sites: null,
        dataSource: null,
        totalCount: 0,
        pageNumber: 1,
        pageSize: commonConfig.pageSize,
    }
    search = React.createRef();
    form = React.createRef();
    operationOptions = [{ value: 3, text: "未发货" }, { value: 1, text: "已发货" }];

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        this.getExpressVendersList(true).then(function () {
            _this.loadData();
        })
    }

    getExpressVendersList = (isInit) => {
        const _this = this;
        return request.get('/Express/GetExpressVendersListByUser').then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                _this.setState({
                    venders: response.results
                });
                if (isInit) {
                    _this.form.current.setFieldsValue({
                        expressType: response.results?.length > 0 ? response.results[0].expressType : null,
                        operator: _this.operationOptions[0].value
                    });
                }

            }
        });
    }

    loadData = () => {
        const _this = this;
        PageLoading.show();
        const formData = this.form.current.getFieldsValue();
        request.get('/Order/GetDeliverOrders', {
            params: {
                orderStatus: 4,
                orderStatuses: [2, 4],
                expressType: formData.expressType,
                operator: formData.operator,
                pageSize: this.state.pageSize,
                pageNumber: this.state.pageNumber,
                name: this.search.current.input.value
            }
        }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.setState({
                    dataSource: response.result.data,
                    totalCount: response.result.totalRecord
                });
            }
        });
    }

    handleValuesChange = (values) => {
        this.setState({ pageNumber: 1 }, this.loadData);
    }

    handlePageChange = (page, pageSize) => {
        this.setState({
            pageNumber: page,
            pageSize: pageSize
        }, this.loadData);
    }

    handleSearch = (value) => {
        this.setState({ pageNumber: 1 }, this.loadData);
    }

    handleShipOrder = (order) => {
        const _this = this;
        Modal.confirm({
            title: "请确认",
            content: "确定将订单标记为已发货？",
            onOk: () => {
                PageLoading.show();
                request.post('/Order/DeliverOrder', {
                    data: order
                }).then(function (response) {
                    PageLoading.hide();
                    if (response && response.success) {
                        message.success("订单发出成功！");
                        _this.loadData();
                        _this.getExpressVendersList();
                    }
                    if (response && response.success === false) {
                        message.error("系统异常，订单发出失败！");
                    }
                });
            }
        })
    }

    handleDownloadSheet = (order) => {
        PageLoading.show();
        request.post(`/Order/DownloadExpressSheet/${order.id}`, {
            responseType: 'blob'
        }).then(function (response) {
            PageLoading.hide();
            if (response.ok === 200 || response.ok === undefined) {
                if (response.type === 'text/plain') {
                    response.text().then(text => {
                        message.error(`下载面单失败，${text}`);
                    })
                }
                if (response.type === 'application/json') {
                    response.text().then(text => {
                        const result = JSON.parse(text);
                        if (result.type === 'redirect') {
                            let linkBtn = document.createElement('a');
                            linkBtn.href = result.file;
                            linkBtn.target = "_blank";
                            linkBtn.click();
                            linkBtn.remove();
                            linkBtn = null;
                            message.success("下载面单成功！");
                        }
                    })
                } else {
                    //const blob = new Blob([response]);
                    const objectURL = URL.createObjectURL(response);
                    let btn = document.createElement('a');
                    //btn.download = `${order.orderNumber}.pdf`;
                    btn.href = objectURL;
                    btn.target = "_blank";
                    btn.click();
                    btn.remove();
                    URL.revokeObjectURL(objectURL);
                    btn = null;
                    message.success("下载面单成功！");
                }
            }
        });
    }

    render() {
        return (
            <PageContainer className="order-package-page">
                <Card loading={this.state.loading}>
                    <Form
                        ref={this.form}
                        onValuesChange={this.handleValuesChange}
                    >
                        <Row className="top-select-row" gutter={24}>
                            <Col {...topStatusSelectProps}>
                                <Form.Item name="operator" label="状态">
                                    <Select>
                                        {this.operationOptions.map(option => (<Option key={option.value} value={option.value}>{option.text}</Option>))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...topLogisticsSelectProps}>
                                <Form.Item label="物流" name="expressType">
                                    <Select>
                                        {this.state.venders?.map(vd => {
                                            return <Option key={vd.expressType} value={vd.expressType}>{`${vd.displayName} (${vd.orderCount})`}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...topSearchSelectProps}>
                                <Form.Item>
                                    <Tooltip title="搜索快递单号、备注、定制信息、运单号、客户单号、订单id">
                                        <Search ref={this.search} placeholder="搜索..." allowClear onSearch={this.handleSearch} />
                                    </Tooltip>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <TableContainer className="order-table">
                        <table>
                            <colgroup>
                                <col width="90" />
                                <col width="120" />
                                <col width="180" />
                                <col width="120" />
                                <col width="400" />
                            </colgroup>
                            <thead className="ant-table-thead">
                                <tr>
                                    <th>客户单号</th>
                                    <th>产品图片</th>
                                    <th>快递单号</th>
                                    <th>定制信息</th>
                                    <th>效果图</th>
                                </tr>
                            </thead>
                            {
                                this.state.dataSource?.map(order => {
                                    const isCreatingExpress = order.orderStatus === 2 || order.orderStatus === 3;
                                    const hasException = order.progressStatus === 1;
                                    return (<tbody key={order.id} className="ant-table-tbody">
                                        <tr className="order-extra-row">
                                            <td rowSpan={1 + order.lineItems.length * 2}>
                                                {isCreatingExpress && <div className="ribbon-container"><div className={`ribbon-tag ${hasException ? "danger" : ""}`}>{hasException ? "缺信息无法建单" : "等待建单中"}</div></div>}
                                                <div className={`order-number-container ${isCreatingExpress ? "grey-out" : ""}`}>
                                                    <h4 className="h4-info">
                                                        {order.orderNumber}
                                                    </h4>
                                                    {
                                                        order.progressStatus === 1 && <span style={{ marginLeft: 4 }}>
                                                            <WarningOutlined style={{ fontSize: 16, color: '#faad14' }} />
                                                        </span>
                                                    }
                                                </div>
                                            </td>
                                            <td colSpan={4} className={`order-extra-col ${isCreatingExpress ? "grey-out" : ""}`}>
                                                <Row className="order-extra-info">
                                                    <Col span={19}>
                                                        <Space direction="vertical" size={4}>
                                                            <Space size={["middle", 4]} wrap={true}>
                                                                <span>运单号：{order.expressId}</span>
                                                                <span>渠道：{order.transportWay}</span>
                                                                <span>国家：{order.countryCnName}</span>
                                                            </Space>
                                                            <Space size={["middle", 4]} wrap={true}>
                                                                <span>订单id：{order.orderId}</span>
                                                                <span>预报时间：{order.declareTimeString}</span>
                                                                {
                                                                    order.needsGiftWrap && <span>
                                                                        <GiftOutlined style={{ color: '#ff883e' }} /> 需要包装
                                                                    </span>
                                                                }
                                                            </Space>
                                                            <Space size={["middle", 4]} wrap={true}>
                                                                <span>收件人：{order.receiverName}</span>
                                                                <span>电话：{order.phoneNumber}</span>
                                                                <span>邮箱：{order.email}</span>
                                                            </Space>
                                                            <div>地址：{order.firstLine} {order.secondLine}, {order.city} {order.state} {order.zip}, {order.receiverCountry}</div>
                                                        </Space>
                                                    </Col>
                                                    <Col span={5}>
                                                        {!isCreatingExpress && <Space wrap size={[18, 4]}>
                                                            <Link style={{ fontSize: '14px' }} onClick={() => this.handleDownloadSheet(order)}><FileTextOutlined style={{ marginRight: '6px' }} />面单</Link>
                                                            {
                                                                this.form.current.getFieldsValue().operator === this.operationOptions[0].value &&
                                                                <Button type="primary" shape="round" onClick={() => this.handleShipOrder(order)} icon={<IconFont type="icon-flight-takeoff-line" />} >发出</Button>
                                                            }
                                                            {
                                                                this.form.current.getFieldsValue().operator === this.operationOptions[1].value &&
                                                                <div className="prepare-time">发货日期：<br />{order.packageTimeString}</div>
                                                            }
                                                        </Space>}
                                                    </Col>
                                                    <Col span={24}>
                                                        <div style={{ marginTop: '4px' }}>备注：{order.internalComment}</div>
                                                    </Col>
                                                </Row>
                                            </td>
                                        </tr>
                                        {order.lineItems?.map((line, lidx) => {
                                            return (<React.Fragment key={line.id}>
                                                <tr className={`product-start-row ${isCreatingExpress ? "grey-out" : ""}`}>
                                                    <td colSpan={4}>
                                                        <Row gutter={8}>
                                                            <Col span={2}>
                                                                <span>数量：{line.count}</span>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Text title={line.cnTitle} ellipsis>产品：{line.cnTitle}</Text>
                                                            </Col>
                                                            <Col span={10}>
                                                                <span>样式：{line.optionsString}</span>
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                </tr>
                                                <tr className={`product-end-row ${isCreatingExpress ? "grey-out" : ""}`} style={{ verticalAlign: 'top' }}>
                                                    <td>
                                                        <Image className="custom-image" fallback={FallbackImage} src={line.thumbnail} preview={false} />
                                                    </td>
                                                    <td>
                                                        <ExpressForm record={line} editable={false} afterUpdate={rd => {
                                                            line.expressName = rd.expressName;
                                                            line.expressNumber = rd.expressNumber;
                                                            line.comment = rd.comment;
                                                            this.setState({ dataSource: [...this.state.dataSource] });
                                                        }} />
                                                    </td>
                                                    <td className="drag-column">
                                                        <ImageContainer record={line} editable={false} afterUpdate={rd => {
                                                            line.properties = rd.properties;
                                                            this.setState({ dataSource: [...this.state.dataSource] });
                                                        }} />
                                                    </td>
                                                    <td>
                                                        <DesignImageContainer record={line} editable={false} afterUpdate={rd => {
                                                            line.designImageSmallList = rd.designImageSmallList;
                                                            line.designImageList = rd.designImageList;
                                                            this.setState({ dataSource: [...this.state.dataSource] });
                                                        }} />
                                                    </td>
                                                </tr>
                                            </React.Fragment>)
                                        })}
                                    </tbody>)
                                })
                            }
                        </table>
                    </TableContainer>
                    <Pagination size="default" style={{ float: 'right', marginTop: 20 }} onChange={this.handlePageChange} showQuickJumper={true} current={this.state.pageNumber} pageSize={this.state.pageSize} total={this.state.totalCount} />
                </Card>
            </PageContainer>);
    }
}

export default OrderPackage;