import React from 'react';
import request from "@utils/request";
import commonConfig from '@common/commonConfig';
import { Card, Typography, message } from "antd";
import classNames from 'classnames';
import DateTrackSkeleton from '@components/Common/DateTrackSkeleton/DateTrackSkeleton';
import './DatabaseAction.less';

const { Text } = Typography;

class DatabaseAction extends React.Component {
    state = {
        loading: false,
        pageNumber: 1,
        pageSize: commonConfig.pageSize,
        records: [],
        showMore: true
    }
    form = React.createRef();

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const _this = this;
        const { records } = this.state;
        const lastMonthYear = records?.length > 0 ? records[records.length - 1].createMonthYear : "";
        this.setState({ loading: true });
        request.get('/Statistics/GetActionRecords', {
            params: {
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize,
                actionType: 1,
                lastMonthYear: lastMonthYear
            }
        }).then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                const newSource = [...records].concat(response.result.data)
                const showMore = newSource.length < response.result.totalRecord;
                _this.setState({
                    records: newSource,
                    showMore: showMore
                });
            }
            if (response && response.success === false) {
                message.error("加载数据库备份状态失败！");
            }
        });
    }

    handleLoadMore = () => {
        this.setState((state, props) => ({
            pageNumber: state.pageNumber + 1
        }), () => this.loadData());
    }

    render() {
        const { title = "" } = this.props
        const { records, showMore } = this.state;
        return (<Card className="date-track-action-card" size="small" title={title}>
            <table className="date-track-action-table">
                <colgroup>
                    <col width="80px" />
                    <col width="100%"/>
                </colgroup>
                <tbody>
                    {records?.map((record, idx) => <React.Fragment key={idx}>
                        {record.showMonthYear && <tr className="date-track-monthrow">
                            <td colSpan="3">
                                <div className="date-track-month">{record.createMonthYear}</div>
                            </td>
                        </tr>
                        }
                        <tr>
                            <td>
                                <div className={classNames({
                                    "date-track-datecontainer": true,
                                    "date-track-action-success": record.status === 1,
                                    "date-track-action-danger": record.status === 0
                                })}>
                                    <div className="date-track-date text-center">{record.createDate}</div>
                                    <div className="date-track-week text-center">{record.createWeek}</div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    {record.status === 0 && <Text>备份数据库失败。</Text>}
                                    {record.status === 1 && <Text>备份数据库成功，备份文件：<Text strong>{record.itemName}</Text></Text>}
                                </div>
                                <div>
                                    <Text>{record.comment}</Text>
                                </div>
                            </td>
                        </tr>
                    </React.Fragment>)}
                </tbody>
            </table>
            <DateTrackSkeleton loading={this.state.loading} showMore={showMore} onMoreClick={this.handleLoadMore} />
        </Card>);
    }
}

export default DatabaseAction;