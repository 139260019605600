import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import request from "@utils/request";
import { Card } from "antd";
import ReactEcharts from 'echarts-for-react';
import './country-timeliness.less';

class LogisticsCountryTimeliness extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/Track/GetCountryTimeInfos').then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                _this.setState({ data: response.results });
            }
        });
    }

    render() {
        return (<PageContainer className='logistics-country-timeliness-page'>
            {this.state.loading && <Card loading={true}></Card>}
            {
                this.state.data?.map((chartsData) => {
                    const option = {
                        title: {
                            text: chartsData.title,
                            subtext: chartsData.subTitle,
                            subtextStyle: {
                                color: '#4caf50',
                                fontSize: 14
                            }
                        },
                        grid: {
                            bottom: '5px',
                            containLabel: true
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            },
                            formatter(params) {
                                var result = '<table><tbody>';
                                var newParams = params.filter(p => p.data.trackingCount !== 0);
                                if (newParams?.length <= 0) {
                                    return '';
                                }
                                newParams.forEach(function (item) {
                                    result += '<tr><td>' + item.marker + item.seriesName + "</td><td style='padding-left:5px'>平均天数: " + item.data.aveValue + "</td><td  style='padding-left:5px'>最长天数: " + item.data.maxValue + "</td><td style='padding-left:5px'>最短天数: " + item.data.minValue + "</td><td style='padding-left:5px'>统计单数: " + item.data.trackingCount + "</td></tr>";
                                })
                                result += '</tbody></table>';
                                return result;
                            }
                        },
                        legend: {
                            width: '80%',
                            right: '10%',
                            data: chartsData.legend,
                        },
                        xAxis: [{
                            type: 'category',
                            data: chartsData.xAxisData
                        }],
                        yAxis: [
                            {
                                type: 'value',
                                axisLine: {
                                    show: true,
                                },
                                minInterval: 1
                            }
                        ],
                        series: chartsData.series,
                    };
                    return (<Card key={chartsData.title} className='chart-card'>
                        <ReactEcharts style={{ height: 300 }} option={option}></ReactEcharts>
                    </Card>)
                })
            }
        </PageContainer>);
    }
}

export default LogisticsCountryTimeliness;