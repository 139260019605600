import BasicLayout from './layouts/BasicLayout';
import Login from '@/pages/auth/login/login';
import AutoLogin from '@/pages/auth/login/auto-login';
import WXRedirect from '@/pages/auth/login/wx-redirect';
import NoFoundPage from '@/pages/404';
import routerConfig from './layouts/routerConfig';
import { BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import './App.less';

function getAllRoutes(){
  let allRoutes= getRoutes([routerConfig.route]);
  return allRoutes.map((r)=>generateRoute(r));
}

function getRoutes(routes){
  let newRoutes=[];
  routes.forEach((r,idx)=>{
    newRoutes.push(r);
    if(r?.routes?.length>0){
      let childRoutes=getRoutes(r.routes);
      newRoutes=newRoutes.concat(childRoutes);
    }
  });
  return newRoutes;
}

function generateRoute(route){
  if(route.redirect){
    return <Redirect key={0} exact from={route.path} to={route.redirect}></Redirect>;
  }
  if(route.component){
    let Comp;
    try{
      Comp=require(`./pages${route.component}`).default;
      Comp=Comp?Comp:NoFoundPage;
    }
    catch{
      Comp= NoFoundPage;
    }
    return <Route exact key={0}
                  path={route.path}
                  render={(routeProps) => <BasicLayout {...routeProps} >
          <Comp {...routeProps}/>
      </BasicLayout>} />;
  }
}

const routes=getAllRoutes();

function App() {
  return (
    <Router>
      <Switch>    
        <Route exact path="/auth/login" component={Login}/>
        <Route exact path="/auth/redirect" component={AutoLogin}/>
        <Redirect exact from="/auth" to="/auth/login"></Redirect>
        <Route exact path="/auth/wx-redirect" component={WXRedirect}/>
        {/* <Route exact path="/order/prepare" render={(routeProps) => <BasicLayout {...routeProps} ><OrderPrepare></OrderPrepare></BasicLayout>} />
        <Route exact path="/order/create-logistics" render={(routeProps) => <BasicLayout {...routeProps} ><OrderCreateLogistics></OrderCreateLogistics></BasicLayout>}/>
        <Redirect exact from="/order" to="/order/prepare"></Redirect> */}
        {routes}
        <Route render={(routeProps) => <BasicLayout {...routeProps} >
            <NoFoundPage />
          </BasicLayout>}>
        </Route>     
        {/* <Route component={NoFoundPage} /> */}
      </Switch>
    </Router>
  );
}

export default App;
