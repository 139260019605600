import React from "react";
import request from "@utils/request";
import { Modal, Spin, Alert, Space, Card, Tooltip, Typography, Button, Form, Select, Input, Row, Col, Tag } from 'antd';
import IconFont from '@components/Common/IconFont';

const { Option } = Select;
const { Text } = Typography;

class ManualCreateLogistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            hasError: false
        }
    }

    form = React.createRef();

    handleOk = () => {
        const _this = this;
        this.setState({ hasError: false });
        this.form.current.validateFields().then(values => {
            const { record } = this.props;
            const controller = new AbortController();
            const { signal } = controller;
            this.controller = controller;
            this.setState({
                loading: true,
                hasError: false,
            });
            let newRecord = {
                ...record,
                expressType: values.expressType,
                transportWay: values.transportWay,
                transportWayCode: values.transportWayCode,
                expressId: values.expressId
            };
            request.post('/Order/ManualCreateExpress', {
                signal,
                data: newRecord
            }).then(function (response) {
                _this.setState({ loading: false });
                if (response && response.success) {
                    _this.setState({ visible: false });
                    if (_this.props.afterUpdate) {
                        _this.props.afterUpdate(newRecord);
                    }
                }
                if (response && response.success === false) {
                    _this.setState({ hasError: true });
                }
            })
        }).catch(errorInfo => { });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleOpen = () => {
        if (this.props.record.progressStatus !== 0) {
            Modal.warning({
                title: "请注意",
                content: "请将订单状态改为正常单后再进行手动建单！"
            });
            return;
        }
        if (this.controller) {
            this.controller.abort();
        }
        this.setState({ visible: true, hasError: false, ways: null }, () => {
            this.form.current.resetFields();
        });
    }

    handleValuesChange = values => {
        if (values.expressType !== undefined) {
            const ways = this.props.allWays[values.expressType];
            this.setState({ ways: ways });
        }
    }

    handleTagClick = tag => {
        this.form.current.setFieldsValue({
            transportWay: tag.displayName,
            transportWayCode: tag.code,
        });
    }

    render() {
        const { record, venders } = this.props;
        return (
            <>
                <Tooltip title="手动创建物流订单">
                    <Button type="primary" onClick={this.handleOpen} shape="circle" icon={<IconFont type="icon-manual" />} />
                </Tooltip>
                <Modal title="手动创建物流订单"
                    wrapClassName="manual-create-logistics-modal"
                    okButtonProps={{ loading: this.state.loading }}
                    maskClosable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={800}
                >
                    <Spin spinning={this.state.loading}>
                        <Card size="small" title="订单信息">
                            <Space direction="vertical" style={{ width: '100%' }}>
                                <Row>
                                    <Col span={4}><Text type="secondary">客户单号</Text></Col>
                                    <Col span={20}><Text>{record.orderNumber}</Text></Col>
                                </Row>
                                <Row>
                                    <Col span={4}><Text type="secondary">收件人</Text></Col>
                                    <Col span={20}><Text>{record.receiverName}</Text></Col>
                                </Row>
                                <Row>
                                    <Col span={4}><Text type="secondary">收件人国家</Text></Col>
                                    <Col span={20}><Text>{record.receiverCountry}</Text></Col>
                                </Row>
                                <Row>
                                    <Col span={4}><Text type="secondary">街道名</Text></Col>
                                    <Col span={20}><Text>{record.firstLine}</Text></Col>
                                </Row>
                                <Row>
                                    <Col span={4}><Text type="secondary">门牌号/建筑物</Text></Col>
                                    <Col span={20}><Text>{record.secondLine}</Text></Col>
                                </Row>
                                <Row>
                                    <Col span={4}><Text type="secondary">城市</Text></Col>
                                    <Col span={20}><Text>{record.city}</Text></Col>
                                </Row>
                                <Row>
                                    <Col span={4}><Text type="secondary">省/州</Text></Col>
                                    <Col span={20}><Text>{record.state}</Text></Col>
                                </Row>
                                <Row>
                                    <Col span={4}><Text type="secondary">收货邮编</Text></Col>
                                    <Col span={20}><Text>{record.zip}</Text></Col>
                                </Row>
                                <Row>
                                    <Col span={4}><Text type="secondary">收件人手机</Text></Col>
                                    <Col span={20}><Text>{record.phoneNumber}</Text></Col>
                                </Row>
                                <Row>
                                    <Col span={4}><Text type="secondary">邮箱</Text></Col>
                                    <Col span={20}><Text>{record.email}</Text></Col>
                                </Row>
                                <Row>
                                    <Col span={4}><Text type="secondary">公司名称</Text></Col>
                                    <Col span={20}><Text>{record.company}</Text></Col>
                                </Row>
                            </Space>
                        </Card>
                        <Row style={{ marginTop: '12px' }} gutter={[12, 12]}>
                            {record.declareItems.map((declareItem, idx) => <Col key={idx} xs={24} sm={12}>
                                <Card size="small" title="海关申报信息">
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        <Row>
                                            <Col span={7}><Text type="secondary">英文品名</Text></Col>
                                            <Col span={17}><Text>{declareItem.enName}</Text></Col>
                                        </Row>
                                        <Row>
                                            <Col span={7}><Text type="secondary">中文品名</Text></Col>
                                            <Col span={17}><Text>{declareItem.cnName}</Text></Col>
                                        </Row>
                                        <Row>
                                            <Col span={7}><Text type="secondary">净重(kg)</Text></Col>
                                            <Col span={17}><Text>{declareItem.netWeight}</Text></Col>
                                        </Row>
                                        <Row>
                                            <Col span={7}><Text type="secondary">申报数量</Text></Col>
                                            <Col span={17}><Text>{declareItem.quantity}</Text></Col>
                                        </Row>
                                        <Row>
                                            <Col span={7}><Text type="secondary">申报单价</Text></Col>
                                            <Col span={17}><Text>{declareItem.unitPrice}</Text></Col>
                                        </Row>
                                        <Row>
                                            <Col span={7}><Text type="secondary">申报币种</Text></Col>
                                            <Col span={17}><Text>{declareItem.currencyCode}</Text></Col>
                                        </Row>
                                    </Space>
                                </Card>
                            </Col>)}
                        </Row>
                        <Card size="small" title="物流信息" style={{ marginTop: '12px' }}>
                            {this.state.hasError && <Alert message="手动创建物流订单失败！" style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                            <Form ref={this.form} labelCol={{ span: 4 }} onValuesChange={this.handleValuesChange} wrapperCol={{ span: 20 }} labelAlign="left">
                                <Form.Item name="expressType" label="物流商" rules={[{ required: true }]}
                                    extra={
                                        this.state.ways?.length > 0 && <Space wrap style={{ marginTop: '8px' }}>
                                            {this.state.ways?.map(w => <Tag style={{ padding: '2px 6px', marginRight: 0, cursor: 'pointer' }} key={w.displayName} onClick={() => this.handleTagClick(w)} color="processing">{w.displayName}</Tag>)}
                                        </Space>
                                    }>
                                    <Select>
                                        {venders?.map(v => <Option key={v.expressType} value={v.expressType}>{v.displayName}</Option>)}
                                    </Select>
                                </Form.Item>
                                <Form.Item name="transportWay" label="运输渠道" rules={[{ required: true }]}>
                                    <Input autoComplete="off" />
                                </Form.Item>
                                <Form.Item name="transportWayCode" label="渠道代码">
                                    <Input autoComplete="off" />
                                </Form.Item>
                                <Form.Item name="expressId" label="物流单号" rules={[{ required: true }]}>
                                    <Input autoComplete="off" />
                                </Form.Item>
                            </Form>
                        </Card>
                    </Spin>
                </Modal>
            </>
        );
    }
};

export default ManualCreateLogistics;