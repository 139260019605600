import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import { Card, Form, Tag, Input, Select, Row, Col, Tooltip, Radio, Table, Space } from "antd";
import request from "@utils/request";
import { CheckOutlined, WarningOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import PageLoading from '@components/Common/PageLoading/PageLoading';
import commonConfig from '@common/commonConfig';
import TrackDetail from '@components/Order/TrackDetail/TrackDetail';
import TrackStatus from '@components/Order/TrackStatus/TrackStatus';
import EditComment from '@components/Order/EditComment/EditComment';
import EditTrackMethod from '@components/Order/EditTrackMethod/EditTrackMethod';
import EditTrackStatus from '@components/Order/EditTrackStatus/EditTrackStatus'
import IconFont from '@components/Common/IconFont';
import withBreakpoint from '@components/Common/withBreakpoint';
import './track.less';

const { Search } = Input;
const { Option } = Select;

const topSiteSelectProps = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 6,
    xl: 6,
};

const topVendorSelectProps = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 5,
    xl: 5,
};

const topWaySelectProps = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 7,
    xl: 7,
};

const topConditionSelectProps = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 6,
    xl: 6,
};

const topSearchSelectProps = {
    xs: 24,
    sm: {
        span: 12,
        push: 12
    },
    md: {
        span: 8,
        push: 16
    },
    lg: {
        span: 8,
        push: 16
    },
    xl: 8
};

class LogisticsTrack extends React.Component {
    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/Track/GetTrackingSelectData').then(function (response) {
            _this.setState({ loading: false });
            if (response.success) {
                const vender = response.result?.venders?.length > 0 ? response.result.venders[0].expressType : null;
                _this.setState({
                    selectDatas: response.result,
                    ways: response.result?.ways[vender]
                });

                _this.form.current.setFieldsValue({
                    site: response.result?.sites?.length > 0 ? response.result.sites[0].id : null,
                    vendor: vender,
                    way: response.result?.ways && vender !== null && response.result.ways[vender]?.length > 0 ? response.result?.ways[vender][0].displayName : null,
                    condition: response.result?.conditions?.length > 0 ? response.result.conditions[0].value : null,
                    trackStatus: 0
                });

                _this.loadData();
            }
        });
    }

    state = {
        loading: true,
        selectDatas: null,
        countStatistic: null,
        pageNumber: 1,
        pageSize: commonConfig.pageSize,
        dataSource: null,
        totalCount: 0
    };
    search = React.createRef();
    form = React.createRef();
    columns = [
        {
            title: '单号',
            key: 'orderNumber',
            render: (record) => (
                <>
                    <span className="status-row">
                        <TrackStatus className="track-status" status={record.trackStatus} />
                        <span className="ordernumber-column">
                            <span>订单号：{record.orderNumber}</span>
                            <span>{record.trackStatusString}</span>
                        </span>
                    </span>
                    <div>订单id：{record.orderId}</div>
                    <div>
                        {record.carrierWebsite && <a href={record.carrierWebsite} target='_blank' rel="noreferrer noopener">{record.carrierEName}</a>}
                        {!record.carrierWebsite && <span>{record.carrierEName}</span>}
                    </div>
                    <div>
                        <TrackDetail record={record} trackMethod='track' />
                    </div>
                </>
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.trackStatus !== nextRecord.trackStatus,
            width: 22
        },
        {
            title: '运输商',
            key: 'transportWay',
            render: (record) => (
                <>
                    <div>{record.expressTypeString}</div>
                    <div>
                        <TrackDetail record={record} trackMethod='express' />
                    </div>
                    <div>{record.transportWay}</div>
                    <div>{record.receiverCountry}</div>
                </>
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.expressTypeString !== nextRecord.expressTypeString
                || prevRecord.transportWay !== nextRecord.transportWay
                || prevRecord.receiverCountry !== nextRecord.receiverCountry,
            width: 22
        },
        {
            title: '最新事件',
            key: 'event',
            render: (record) => (
                <>
                    <div>{record.newEvent}</div>
                    <Tooltip title={`上网至今：${record.firstTillNow} 天`}>
                        <Tag color="#52c41a">{record.firstTillNow}</Tag>
                    </Tooltip>
                    <Tooltip title={`最后事件至今：${record.lastTillNow} 天`}>
                        <Tag color="#faad14">{record.lastTillNow}</Tag>
                    </Tooltip>
                </>
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.newEvent !== nextRecord.newEvent
                || prevRecord.firstTillNow !== nextRecord.firstTillNow
                || prevRecord.lastTillNow !== nextRecord.lastTillNow,
            width: 20
        },
        {
            title: 'GMT +08:00',
            key: 'time',
            render: (record) => (
                <>
                    <div>{`${record.createTimeString} Added`}</div>
                    <div>{`${record.trackTimeString} Tracked`}</div>
                </>
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.createTimeString !== nextRecord.createTimeString
                || prevRecord.trackTimeString !== nextRecord.trackTimeString,
            width: 21
        },
        {
            title: '操作',
            key: 'action',
            render: (record) => (
                <Space wrap>
                    <EditComment
                        record={record}
                        afterUpdate={(newRecord) => {
                            const rd = { ...record, internalComment: newRecord.internalComment };
                            const newSource = [...this.state.dataSource];
                            const idx = newSource.findIndex(x => x.id === record.id);
                            newSource[idx] = rd;
                            this.setState({ dataSource: newSource });
                        }} />
                    <EditTrackMethod
                        record={record}
                        afterUpdate={(newRecord) => {
                            const rd = { ...record, trackMethod: newRecord.trackMethod };
                            const newSource = [...this.state.dataSource];
                            const idx = newSource.findIndex(x => x.id === record.id);
                            newSource[idx] = rd;
                            this.setState({ dataSource: newSource });
                        }} />
                    <EditTrackStatus
                        record={record}
                        afterUpdate={(newRecord) => {
                            this.loadData();
                        }} />
                </Space>
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord !== nextRecord
                || prevRecord.internalComment !== nextRecord.internalComment
                || prevRecord.trackMethod !== nextRecord.trackMethod
                || prevRecord.trackStatus !== nextRecord.trackStatus,
            width: 15
        },
    ];

    getTrackingCountStatistic = () => {
        const _this = this;
        const formData = this.form.current.getFieldsValue();
        request.get('/Track/GetTrackingCountStatistic', {
            params: {
                siteId: formData.site,
                expressType: formData.vendor,
                transportWay: formData.way,
                trackingCondition: formData.condition,
                trackStatus: formData.trackStatus,
                name: this.search.current.input.value
            }
        }).then(function (response) {
            if (response && response.success) {
                _this.setState({ countStatistic: response.result });
            }
        });
    }

    getTrackingInfos = () => {
        const _this = this;
        PageLoading.show();
        const formData = this.form.current.getFieldsValue();
        request.get('/Track/GetTrackingInfos', {
            params: {
                siteId: formData.site,
                expressType: formData.vendor,
                transportWay: formData.way,
                trackingCondition: formData.condition,
                trackStatus: formData.trackStatus,
                pageSize: this.state.pageSize,
                pageNumber: this.state.pageNumber,
                name: this.search.current.input.value
            }
        }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.setState({
                    dataSource: response.result?.data,
                    totalCount: response.result?.totalRecord
                });
            }
        });
    }

    onSearch = (value) => {
        this.setState({ pageNumber: 1 }, this.loadData);
    }

    handleValuesChange = (values) => {
        if (values['vendor'] !== undefined) {
            this.setState({
                ways: this.state.selectDatas?.ways[values['vendor']]
            });
            this.form.current.setFieldsValue({
                way: this.state.selectDatas?.ways[values['vendor']][0].displayName
            });
        }
        this.setState({ pageNumber: 1 }, this.loadData);
    }

    loadData = () => {
        this.getTrackingCountStatistic();
        this.getTrackingInfos();
    }

    handleTableChange = (page, pageSize) => {
        this.setState({
            pageNumber: page,
            pageSize: pageSize
        }, this.loadData);
    }

    render() {
        return (<PageContainer className="logistics-track-page">
            <Card loading={this.state.loading}>
                <Form
                    ref={this.form}
                    onValuesChange={this.handleValuesChange}
                >
                    <Row className="top-select-row" gutter={24}>
                        <Col {...topSiteSelectProps}>
                            <Form.Item name="site" label="平台">
                                <Select>
                                    {this.state.selectDatas?.sites.map(site => {
                                        return <Option key={site.id} value={site.id}>{site.displayName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...topVendorSelectProps}>
                            <Form.Item name="vendor" label="物流">
                                <Select>
                                    {this.state.selectDatas?.venders.map(vendor => {
                                        return <Option key={vendor.expressType} value={vendor.expressType}>{vendor.displayName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...topWaySelectProps}>
                            <Form.Item name="way" label="渠道">
                                <Select>
                                    {this.state.ways?.map(way => {
                                        return <Option key={way.displayName} value={way.displayName}>{way.displayName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...topConditionSelectProps}>
                            <Form.Item name="condition" label="筛选">
                                <Select>
                                    {this.state.selectDatas?.conditions.map(condition => {
                                        return <Option key={condition.value} value={condition.value}>{condition.text}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="top-search-row">
                        <Col {...topSearchSelectProps}>
                            <Tooltip title="搜索订单号、订单id、物流单号、末端派送商单号">
                                <Search ref={this.search} placeholder="搜索..." allowClear onSearch={this.onSearch} />
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row className="filter-container" wrap={this.props.breakpoint.sm ? false : true}>
                        <Col className="filter-title" flex="none">
                            <div>
                                轨迹状态:
                            </div>
                        </Col>
                        <Col flex="auto">
                            <Form.Item name='trackStatus' style={{ marginBottom: 0 }}>
                                <Radio.Group buttonStyle="solid">
                                    <Radio.Button value={0}><IconFont style={{ color: '#fff' }} className="filter-icon" type="icon-blur" />全部 {this.state.countStatistic?.totalCount}</Radio.Button>
                                    <Radio.Button value={1}><IconFont style={{ color: '#b6b6b6' }} className="filter-icon" type="icon-hourglass" />等待揽收 {this.state.countStatistic?.waitTotalCount}</Radio.Button>
                                    <Radio.Button value={2}><IconFont style={{ color: '#096dd9' }} className="filter-icon" type="icon-flight" />运输途中 {this.state.countStatistic?.transitTotalCount}</Radio.Button>
                                    <Radio.Button value={3}><CheckOutlined style={{ color: '#52c41a' }} className="filter-icon" />成功签收 {this.state.countStatistic?.deliverTotalCount}</Radio.Button>
                                    <Radio.Button value={4}><ExclamationCircleOutlined style={{ color: '#ff4d4f' }} className="filter-icon" />投递失败 {this.state.countStatistic?.unDeliverTotalCount}</Radio.Button>
                                    <Radio.Button value={5}><WarningOutlined style={{ color: '#faad14' }} className="filter-icon" />可能异常 {this.state.countStatistic?.alertTotalCount}</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Table rowKey='id'
                    columns={this.columns}
                    dataSource={this.state.dataSource}
                    tableLayout='fixed'
                    size="middle"
                    scroll={{ x: 950 }}
                    expandable={{
                        expandedRowKeys: this.state.dataSource?.map(d => { return d.id }),
                        expandedRowRender: record => <Row className="order-extra-info" gutter={0}>
                            <Col span={7}>
                                Estimated Delivery Date: {record.estimatedDeliveryDate}
                            </Col>
                            <Col span={17}>
                                备注：{record.internalComment}
                            </Col>
                        </Row>,
                        columnWidth: 0
                    }}
                    pagination={{
                        pageSize: this.state.pageSize,
                        current: this.state.pageNumber,
                        total: this.state.totalCount,
                        showQuickJumper: true,
                        size: 'default',
                        onChange: this.handleTableChange
                    }} />
            </Card>
        </PageContainer>);
    }
}

export default withBreakpoint(LogisticsTrack);