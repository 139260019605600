import React from 'react';
import { PageContainer } from "@ant-design/pro-layout";
import request from "@utils/request";
import queryString from 'query-string';
import StockDetailList from '@components/Stock/StockDetailList/StockDetailList'
import { Card, Form, Select, Row, Col } from "antd";
import './stock-detail.less';

const { Option } = Select;

class StockDetail extends React.Component {
    state = {
        loading: false,
        stocks: null,
        stock: null
    }
    form = React.createRef();

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/Stock/GetAllStocks').then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                const urlStock = parseInt(queryString.parse(_this.props.location.search)?.stock);
                const stock = urlStock ? urlStock : response.results?.length > 0 ? response.results[0].id : null;
                _this.setState({
                    stocks: response.results,
                    stock: stock
                }, () => {
                    _this.form.current.setFieldsValue({
                        stock: stock
                    });
                });
            }
        });
    }

    handleValuesChange = (values) => {
        this.setState({ stock: values.stock });
    }

    render() {
        const { stocks } = this.state;
        return (<PageContainer className="stock-detail-page">
            <Card loading={this.state.loading}>
                <Form ref={this.form} onValuesChange={this.handleValuesChange}>
                    <Row gutter={24} className='top-select-row'>
                        <Col xs={24} sm={16} md={12} lg={10}>
                            <Form.Item name="stock" label="库存产品">
                                <Select showSearch filterOption={(input, option) => {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }}>
                                    {stocks?.map(st => <Option key={st.id} value={st.id}>{st.stockName}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row gutter={[24, 24]}>
                    <Col sm={24} md={12}>
                        <StockDetailList recordType={0} stock={this.state.stock} title="进货库存详情" />
                    </Col>
                    <Col sm={24} md={12}>
                        <StockDetailList recordType={1} stock={this.state.stock} title="出货库存详情" />
                    </Col>
                </Row>
            </Card>
        </PageContainer >);
    }
}

export default StockDetail;