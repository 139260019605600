import React from 'react';
import request from "@utils/request";
import commonConfig from '@common/commonConfig';
import { Card, Typography, message } from "antd";
import classNames from 'classnames';
import DateTrackSkeleton from '@components/Common/DateTrackSkeleton/DateTrackSkeleton';
import './TimerAction.less';

const { Text } = Typography;

class TimerAction extends React.Component {
    state = {
        loading: false,
        pageNumber: 1,
        pageSize: commonConfig.pageSize,
        records: [],
        showMore: true
    }
    form = React.createRef();

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const _this = this;
        const { records } = this.state;
        const lastDate = records?.length > 0 ? records[records.length - 1].createFullDate : "";
        this.setState({ loading: true });
        request.get('/Statistics/GetTimerRecords', {
            params: {
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize,
                lastDate: lastDate
            }
        }).then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                const newSource = [...records].concat(response.result.data)
                const showMore = newSource.length < response.result.totalRecord;
                _this.setState({
                    records: newSource,
                    showMore: showMore
                });
            }
            if (response && response.success === false) {
                message.error("加载Timer执行状态失败！");
            }
        });
    }

    handleLoadMore = () => {
        this.setState((state, props) => ({
            pageNumber: state.pageNumber + 1
        }), () => this.loadData());
    }

    render() {
        const { title = "" } = this.props
        const { records, showMore } = this.state;
        return (<Card className="date-track-action-card" size="small" title={title}>
            <table className="date-track-action-table table-list">
                <colgroup>
                    <col width="100%" />
                </colgroup>
                <tbody>
                    {records?.map((record, idx) => <React.Fragment key={idx}>
                        {
                            record.showDate && <tr className="date-track-monthrow">
                                <td colSpan="3">
                                    <div className="date-track-month">{record.createFullDate} ({record.createWeek})</div>
                                </td>
                            </tr>
                        }
                        <tr className={classNames({
                            "date-track-firstdate": record.showDate
                        })}>
                            <td>
                                <div>
                                    <Text type={record.status === 1 ? "success" : "danger"} >{record.createTime}</Text>
                                    <Text> {record.comment}</Text>
                                </div>
                            </td>
                        </tr>
                    </React.Fragment>)}
                </tbody>
            </table>
            <DateTrackSkeleton loading={this.state.loading} showDate={false} showMore={showMore} onMoreClick={this.handleLoadMore} />
        </Card>);
    }
}

export default TimerAction;