import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import { Card, Button, Space, Rate, Timeline, message, Typography, Tooltip } from "antd";
import request from "@utils/request";
import { QuestionCircleOutlined, MessageOutlined } from '@ant-design/icons'
import commonUtil from "@utils/commonUtil";
import DesignImageContainer from '@components/Order/DesignImageContainer/DesignImageContainer';
import moment from 'moment';
import AnswerQuestion from '@components/Platform/AnswerQuestion/AnswerQuestion';
import PageLoading from '@components/Common/PageLoading/PageLoading';
import './platform-timeline.less';

const { Text, Link } = Typography;

class PlatformTimeliness extends React.Component {
    state = {
        loading: false
    }

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/Review/GetPendingReviewsAndQuestions').then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                let dataitems = [];
                const result = response.result;
                if (result && result.reviews.length > 0) {
                    const reviews = result.reviews.map(ele => {
                        return {
                            ...ele,
                            itemtype: "review",
                            upload_date: moment(ele.upload_time, "YYYY/MM/DD HH:mm:ss")
                        }
                    });
                    dataitems = dataitems.concat(reviews);
                }
                if (result && result.questions.length > 0) {
                    const questions = result.questions.map(ele => {
                        return {
                            ...ele,
                            itemtype: "question",
                            upload_date: moment(ele.upload_time, "YYYY/MM/DD HH:mm:ss")
                        }
                    });
                    dataitems = dataitems.concat(questions);
                }
                dataitems.sort(function (a, b) {
                    return a.upload_date <= b.upload_date ? 1 : -1;
                });
                var displayitems = [];
                var month = "";
                var year = "";
                var today = new Date();
                var todayLength = ((today.getHours() * 60 + today.getMinutes()) * 60 + today.getSeconds()) * 1000 + today.getMilliseconds();
                var sites = {};
                dataitems.forEach((ele, idx) => {
                    if (!sites.hasOwnProperty(ele.site_name)) {
                        sites[ele.site_name] = {
                            site_id: ele.site_id,
                            products: [ele.product_id]
                        };
                    } else {
                        if (sites[ele.site_name].products.indexOf(ele.product_id) < 0) {
                            sites[ele.site_name].products.push(ele.product_id);
                        }
                    }
                    ele.product_name = ele.product_id;
                    ele.product_link = "https://" + ele.site_name + "/admin/api/2021-04/products/" + ele.product_id;
                    var itemMonth = ele.upload_date.format('MMM');
                    var itemYear = ele.upload_date.format('YYYY');
                    if (month !== itemMonth || year !== itemYear) {
                        displayitems.push({
                            itemtype: "date",
                            date: itemMonth + " " + itemYear
                        });
                        month = itemMonth;
                        year = itemYear;
                    }
                    var lengthRange = today - ele.upload_date;
                    if (lengthRange > todayLength) {
                        ele.dateString = itemMonth + ele.upload_date.format("DD hh:mm:ss");
                    } else {
                        ele.dateString = ele.upload_date.fromNow();
                    }
                    displayitems.push(ele);
                });

                _this.load_product_name(sites, displayitems);
            }
        });
    }

    load_product_name = (sites, items) => {
        const _this = this;
        var requests = [];
        var siteData = [];
        for (var key in sites) {
            if (sites.hasOwnProperty(key)) {
                var element = sites[key];
                var address = "https://" + key + "/admin/api/2021-04/products.json?ids=" + element.products.join(",") + "&fields=id,title,handle";

                var pdtRequest = request.post('/Base/SendShopifyRequest', {
                    data: {
                        site_id: element.site_id,
                        address: address,
                        method: 'GET'
                    }
                });
                requests.push(pdtRequest);
                siteData.push({
                    site_id: element.site_id
                });
            }
        }
        PageLoading.show();
        Promise.all(requests).then(function (response) {
            PageLoading.hide();
            var products = {};
            response.forEach((ele, idx) => {
                ele.products.forEach((pele, pidx) => {
                    pele.site_id = siteData[idx].site_id;
                    products[pele.site_id + "-" + pele.id] = pele;
                });
            });
            items.forEach((ele, idx) => {
                if (products.hasOwnProperty(ele.site_id + "-" + ele.product_id)) {
                    var pinfo = products[ele.site_id + "-" + ele.product_id];
                    ele.product_name = pinfo.title;
                    ele.product_link = "https://" + ele.site_name + "/products/" + pinfo.handle;
                }
            });
            _this.setState({ items });
        }, function (response) {
            PageLoading.hide();
            commonUtil.showShopifyError(response);
        });
    }

    handleApproveReview = (record) => {
        this.changeReview(record, 1);
    }

    handleRejectReview = (record) => {
        this.changeReview(record, 2);
    }

    changeReview = (record, approve) => {
        const _this = this;
        PageLoading.show();
        request.post('/Review/ApproveReview', {
            data: {
                id: record.id,
                approve
            }
        }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                const newItems = [..._this.state.items];
                const idx = _this.state.items.indexOf(record);
                newItems.splice(idx, 1);
                _this.setState({ items: newItems });
                message.success("更改评价状态成功！");
            }
            if (response && response.success === false) {
                message.error("系统异常，更改评价状态失败！");
            }
        });
    }

    handleRejectQuestion = (item) => {
        const _this = this;
        PageLoading.show();
        request.post('/Review/ApproveQuestion', {
            data: {
                id: item.id,
                approve: 2
            }
        }).then(function (response) {
            PageLoading.hide();

            if (response && response.success) {
                const newItems = [..._this.state.items];
                const idx = _this.state.items.indexOf(item);
                newItems.splice(idx, 1);
                _this.setState({ items: newItems });
                message.success("更改提问状态成功！");
            }
            if (response && response.success === false) {
                message.error("系统异常，更改提问状态失败！");
            }
        });
    }

    render() {
        return (
            <PageContainer className="platform-timeliness-page">
                <Card loading={this.state.loading}>
                    <Timeline mode="left" style={{ paddingLeft: 50 }}>
                        {this.state.items?.map((item, idx) => {
                            switch (item.itemtype) {
                                case 'date':
                                    return <Timeline.Item className="date-line" key={idx} dot={<span className="date-span">{item.date}</span>}></Timeline.Item>;
                                case 'review':
                                    return <Timeline.Item key={idx} dot={<MessageOutlined className="review-icon" />}>
                                        <div className="timeline-panel">
                                            <div>
                                                <h4 style={{ marginBottom: 0 }}>
                                                    <Tooltip title={item.email}>
                                                        <span className="review-panel-username">{item.first_name} {item.last_name}</span>
                                                    </Tooltip>
                                                    <span>写了一个评价关于</span>
                                                    <Link target="_blank" href={item.product_link}>{item.product_name}</Link>
                                                    <span>在网站</span>
                                                    <Link target="_blank" href={`https://${item.site_name}`}>{item.site_display_name}</Link>上
                                                </h4>
                                                <Text type="secondary" >{item.dateString}</Text>
                                            </div>
                                            <div>
                                                <div>
                                                    <Rate allowClear={false} disabled value={item.rate} />
                                                </div>
                                                <div>{item.comment}</div>
                                                <DesignImageContainer cols={6} record={{
                                                    designImageSmallList: item.thumbs,
                                                    designImageList: item.images,
                                                    editable: false
                                                }} />
                                                <Space style={{ marginTop: 8 }}>
                                                    <Button ghost type="primary" onClick={() => this.handleApproveReview(item)}>通过</Button>
                                                    <Button ghost type="primary" danger onClick={() => this.handleRejectReview(item)}>拒绝</Button>
                                                </Space>
                                            </div>
                                        </div>
                                    </Timeline.Item>;
                                case 'question':
                                    return <Timeline.Item key={idx} dot={<QuestionCircleOutlined className="question-icon" />}>
                                        <div className="timeline-panel">
                                            <div>
                                                <h4 style={{ marginBottom: 0 }}>
                                                    <Tooltip title={item.email}>
                                                        <span className="review-panel-username">{item.name}</span>
                                                    </Tooltip>
                                                    <span>提了一个问题关于</span>
                                                    <Link target="_blank" href={item.product_link}>{item.product_name}</Link>
                                                    <span>在网站</span>
                                                    <Link target="_blank" href={`https://${item.site_name}`}>{item.site_display_name}</Link>上
                                                </h4>
                                                <Text type="secondary" >{item.dateString}</Text>
                                            </div>
                                            <div>
                                                <div>{item.question}</div>
                                                <Space style={{ marginTop: 8 }}>
                                                    <AnswerQuestion answerButtonProps={{ ghost: true, type: "primary", children: "回答" }} record={item} afterUpdate={(newQuestion) => {
                                                        const newItems = [...this.state.items];
                                                        const idx = this.state.items.indexOf(item);
                                                        newItems.splice(idx, 1);
                                                        this.setState({ items: newItems });
                                                    }} />
                                                    <Button ghost type="primary" danger onClick={() => this.handleRejectQuestion(item)}>不回答</Button>
                                                </Space>
                                            </div>
                                        </div>
                                    </Timeline.Item>;
                                default:
                                    return '';
                            }
                        })}
                    </Timeline>
                </Card>
            </PageContainer>);
    }

}

export default PlatformTimeliness