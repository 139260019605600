import CryptoJS from "crypto-js";

const isLogedIn = () => {
    return !!getAccountInfo() && !isLoginExpired();
}

const isLoginExpired = () => {
    const accountInfo = getAccountInfo();
    if (!accountInfo) {
        return true;
    } else {
        const token = accountInfo?.token;
        const tokenArray = token?.split('.');
        if (tokenArray?.length > 1) {
            const payloadEncrypt = tokenArray[1];
            var encryptArray = CryptoJS.enc.Base64.parse(payloadEncrypt);
            var decryptStr = encryptArray.toString(CryptoJS.enc.Utf8);
            var exp = JSON.parse(decryptStr)?.exp;
            return exp ? new Date(exp * 1000) < new Date() : true;
        } else {
            return true;
        }
    }
}

const needUpdateToken = () => {
    const accountInfo = getAccountInfo();
    if (!accountInfo) {
        return false;
    } else {
        const token = accountInfo?.token;
        const tokenArray = token?.split('.');
        if (tokenArray?.length > 1) {
            const payloadEncrypt = tokenArray[1];
            var encryptArray = CryptoJS.enc.Base64.parse(payloadEncrypt);
            var decryptStr = encryptArray.toString(CryptoJS.enc.Utf8);
            var exp = JSON.parse(decryptStr)?.exp;
            if (exp && new Date(exp * 1000 - 30 * 60 * 1000) < new Date() && new Date(exp * 1000) > new Date()) {
                return true;
            }
        }
        return false;
    }
}

const setAccountInfo = (result) => {
    const resultString = Object.prototype.toString.call(result) === '[object String]' ? result : JSON.stringify(result);
    localStorage.setItem('ecp_account', resultString);
}

const getAccountInfo = () => {
    const resultString = localStorage.getItem('ecp_account');
    let accountObj = null;
    try {
        accountObj = JSON.parse(resultString)
    } catch (e) {
        console.log('Parse Account Info failed');
    }
    return accountObj;
}

const cleanAccount = () => {
    localStorage.removeItem('ecp_account');
}

const updateToken = (token) => {
    let accountInfo = getAccountInfo();
    if (!accountInfo) {
        return;
    } else {
        accountInfo.token = token;
        setAccountInfo(accountInfo);
    }
}

const isAdmin = () => {
    const accountInfo = getAccountInfo();
    const role = accountInfo?.roles;
    if (role) {
        const roleArray = role.split(',');
        return roleArray.some((item) => item.toLowerCase() === "admin");
    }
    return false;
}

export default isLogedIn;

export {
    isLoginExpired,
    setAccountInfo,
    getAccountInfo,
    updateToken,
    needUpdateToken,
    isAdmin,
    cleanAccount,
};