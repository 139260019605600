import React from "react";
import request from "@utils/request";
import { Modal, Spin, Alert, Tooltip, Button, Form, InputNumber, Input, message } from 'antd';
import { MinusSquareOutlined } from '@ant-design/icons';

const { TextArea } = Input;

class DecreaseSpare extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            hasError: false
        }
    }

    form = React.createRef();

    handleOk = () => {
        this.form.current.validateFields().then(values => {
            const { record } = this.props;
            const controller = new AbortController();
            const { signal } = controller;
            this.controller = controller;
            this.setState({ loading: true, errorMsg: null });
            const _this = this;
            const newData = {
                parentId: record.id,
                changeType: 1,
                ...values
            };
            request.post('/Spare/ChangeSpareCount', {
                signal,
                data: newData
            }).then(function (response) {
                _this.setState({ loading: false });
                if (response && response.success) {
                    _this.setState({ visible: false });
                    message.success(`${record.spareName}减少备品成功！`);
                    if (_this.props.afterUpdate) {
                        _this.props.afterUpdate({ ...newData, ...response.result });
                    }
                }
                if (response && response.success === false) {
                    _this.setState({ errorMsg: response.errorMsg });
                }
            });
        }).catch(errorInfo => { });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleOpen = () => {
        if (this.controller) {
            this.controller.abort();
        }
        this.setState({ visible: true, hasError: false }, () => {
            this.form.current.resetFields();
        });
    }

    render() {
        const { record } = this.props;
        return (
            <>
                <Tooltip title="减少备品">
                    <Button type="primary" onClick={this.handleOpen} shape="circle" icon={<MinusSquareOutlined />} />
                </Tooltip>
                <Modal title="减少备品"
                    okButtonProps={{ loading: this.state.loading }}
                    maskClosable={false}
                    width={800}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Spin spinning={this.state.loading}>
                        {this.state.hasError && <Alert message="减少备品失败！" style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                        <Form ref={this.form} labelAlign="left" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                            <Form.Item label="备品名称">
                                <div>{record?.spareName}</div>
                            </Form.Item>
                            <Form.Item label="减少数量" name="count" rules={[{ required: true }]}>
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item label="备注" name="comment">
                                <TextArea rows={4} />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        );
    }
};

export default DecreaseSpare;