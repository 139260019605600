import { Grid } from "antd";
import React from "react";

const { useBreakpoint } = Grid;

function withBreakpoint(WrappedComponent) {
    return (props) => {
        const screens = useBreakpoint();
        return (<WrappedComponent {...props} breakpoint={screens} />);
    };
}

export default withBreakpoint;