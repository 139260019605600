import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import { SyncOutlined, InfoCircleOutlined, GiftOutlined, WarningOutlined } from '@ant-design/icons';
import { Card, Button, Row, Col, Space, Select, Tooltip, Form, Input, Pagination, Typography, Image, message } from "antd";
import request from "@utils/request";
import commonConfig from '@common/commonConfig';
import PageLoading from '@components/Common/PageLoading/PageLoading';
import EditComment from '@components/Order/EditComment/EditComment';
import ExpressForm from '@components/Order/ExpressForm/ExpressForm';
import ImageContainer from '@components/Order/ImageContainer/ImageContainer';
import DesignImageContainer from "@components/Order/DesignImageContainer/DesignImageContainer";
import MarkOrder from "@components/Order/MarkOrder/MarkOrder";
import ExportOrder from "@components/Order/ExportOrder/ExportOrder";
import ShipOrder from "@components/Order/ShipOrder/ShipOrder";
import TableContainer from "@components/Common/TableContainer/TableContainer";
import commonUtil from "@utils/commonUtil";
import FallbackImage from '@common/CommonImage';
import './prepare.less';

const { Option } = Select;
const { Search } = Input;
const { Text } = Typography;

const topSiteSelectProps = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 8,
    xl: 8,
};

const topFilterSelectProps = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 8,
    xl: 8,
};

const topSearchSelectProps = {
    xs: 24,
    sm: {
        span: 12,
        push: 12
    },
    md: {
        span: 8,
        push: 16
    },
    lg: {
        span: 8,
        push: 0
    },
    xl: 8,
};

class OrderPrepare extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            sites: null,
            dataSource: null,
            totalCount: 0,
            pageNumber: 1,
            pageSize: commonConfig.pageSize,
            settings: null
        }
    }

    form = React.createRef();
    search = React.createRef();
    shipForm = React.createRef();
    tableContent = React.createRef();

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/Site/GetSimpleSites', {
            params: { includeAll: true }
        }).then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                _this.setState({
                    sites: response.results
                });

                _this.form.current.setFieldsValue({
                    site: response.results?.length > 0 ? response.results[0].id : null,
                    progressStatus: 100,
                    operator: 0
                });

                _this.loadData();
            }
        });

        request.get('/Setting/GetLogisticsSettingConfig').then((response) => {
            if (response && response.success) {
                _this.setState({
                    settings: response.result?.customConfig?.logisticsSettingInfos
                })
            }
        })
    }

    handleValuesChange = (values) => {
        this.setState({ pageNumber: 1 }, this.loadData);
    }

    loadData = () => {
        const _this = this;
        PageLoading.show();
        const formData = this.form.current.getFieldsValue();
        request.get('/Order/GetPrepareOrders', {
            params: {
                siteId: formData.site,
                orderStatus: 1,
                progressStatus: formData.progressStatus,
                operator: formData.operator,
                pageSize: this.state.pageSize,
                pageNumber: this.state.pageNumber,
                name: this.search.current.input.value
            }
        }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.setState({
                    dataSource: response.result.data,
                    totalCount: response.result.totalRecord
                });
            }
        });
    }

    handlePageChange = (page, pageSize) => {
        this.setState({
            pageNumber: page,
            pageSize: pageSize
        }, this.loadData);
    }

    onSearch = (value) => {
        this.setState({ pageNumber: 1 }, this.loadData);
    }

    handleSyncOrder = () => {
        PageLoading.show();
        request.get('/Order/SyncOrders').then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                message.success(`${response.errorMsg}共新增${response.result}个订单`);
            }
            if (response && response.success === false) {
                message.error(`同步失败！${response.errorMsg}`);
            }
        });
    }

    onRenderAddress = (order) => {
        const needPhone = order.receiverCountry.toLowerCase() !== 'us' && order.receiverCountry.toLowerCase() !== 'gb'
            && order.receiverCountry.toLowerCase() !== 'au' && !order.phoneNumber;
        return (<span>地址：<Tooltip title={!order.reachable ? "物流无法到达" : needPhone ? "邮寄需要电话" : ""}>
            <Text mark={needPhone && order.reachable} type={!order.reachable ? "danger" : ""}>{order.firstLine} {order.secondLine}, {order.city} {order.state} {order.zip}, {order.receiverCountry}</Text>
        </Tooltip></span>);
    }

    render() {
        return (
            <PageContainer className="order-prepare-page">
                <Card loading={this.state.loading}>
                    <Space wrap>
                        <ExportOrder sites={this.state.sites} />
                        <Button type="primary" onClick={this.handleSyncOrder} icon={<SyncOutlined />} ghost>
                            同步订单
                        </Button>
                        <Tooltip title="手动立刻同步所有平台的订单。">
                            <InfoCircleOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />
                        </Tooltip>
                    </Space>
                    <Form
                        style={{ marginTop: '12px' }}
                        ref={this.form}
                        onValuesChange={this.handleValuesChange}
                    >
                        <Row className="top-select-row" gutter={24}>
                            <Col {...topSiteSelectProps}>
                                <Form.Item name="site" label="平台">
                                    <Select>
                                        {this.state.sites?.map(site => {
                                            return <Option key={site.id} value={site.id}>{site.displayName}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...topFilterSelectProps}>
                                <Form.Item label="状态" style={{ marginBottom: 0 }}>
                                    <Form.Item name="operator"
                                        style={{ display: 'inline-block', width: 'calc(50% - 4px)' }}
                                    >
                                        <Select>
                                            <Option value={0}>制作中</Option>
                                            <Option value={1}>制作完成</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="progressStatus"
                                        style={{ display: 'inline-block', width: 'calc(50% - 4px)', margin: '0 0 0 8px' }}>
                                        <Select>
                                            <Option value={100}>全部</Option>
                                            <Option value={0}>正常单</Option>
                                            <Option value={1}>问题单</Option>
                                        </Select>
                                    </Form.Item>
                                </Form.Item>
                            </Col>
                            <Col {...topSearchSelectProps}>
                                <Form.Item>
                                    <Tooltip title="搜索订单id、产品名、客户单号">
                                        <Search ref={this.search} placeholder="搜索..." allowClear onSearch={this.onSearch} />
                                    </Tooltip>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <TableContainer className="order-table">
                        <table>
                            <colgroup>
                                <col width="90" />
                                <col width="120" />
                                <col width="120" />
                                <col width="400" />
                                <col width="180" />
                            </colgroup>
                            <thead className="ant-table-thead">
                                <tr>
                                    <th>客户单号</th>
                                    <th>产品图片</th>
                                    <th>定制信息</th>
                                    <th>效果图</th>
                                    <th>快递单号</th>
                                </tr>
                            </thead>
                            {
                                this.state.dataSource?.map(order => {
                                    return (<tbody key={order.id} className="ant-table-tbody">
                                        <tr className="order-extra-row">
                                            <td rowSpan={1 + order.lineItems.length * 2}>
                                                <div className="order-number-container">
                                                    <h4 className="h4-info">
                                                        {order.orderNumber}
                                                    </h4>
                                                    {
                                                        order.progressStatus === 1 && <span style={{ marginLeft: 4 }}>
                                                            <WarningOutlined style={{ fontSize: 16, color: '#faad14' }} />
                                                        </span>
                                                    }
                                                </div>
                                            </td>
                                            <td colSpan={4} className="order-extra-col">
                                                <Row className="order-extra-info">
                                                    <Col span={19}>
                                                        <Space direction="vertical" size={4}>
                                                            <Space size={["middle", 4]} wrap={true}>
                                                                <span>订单id：{order.orderId}</span>
                                                                <span>下单时间：{order.createTimeString}</span>
                                                                <span>{order.shippingMethod}</span>
                                                                {
                                                                    order.needsGiftWrap && <span>
                                                                        <GiftOutlined style={{ color: '#ff883e' }} /> 需要包装
                                                                    </span>
                                                                }
                                                            </Space>
                                                            <Space size={["middle", 4]} wrap={true}>
                                                                <span>姓名：{order.receiverName}</span>
                                                                <span>邮箱：{order.email}</span>
                                                                {this.onRenderAddress(order)}
                                                            </Space>
                                                            <Space size={["middle", 4]} wrap={true}>
                                                                <span>Ship by: {order.shippedDateString}</span>
                                                                <span>Estimated delivery: {order.estimatedDeliveryDate}</span>
                                                                {
                                                                    this.form.current.getFieldsValue().operator === 1 &&
                                                                    <span>物流标注：{commonUtil.convertExpressNote(order.expressNote)}</span>
                                                                }
                                                            </Space>
                                                        </Space>
                                                    </Col>
                                                    <Col span={5}>
                                                        <Space wrap className="prepare-action">
                                                            <EditComment
                                                                record={{ ...order, parentId: order.id }}
                                                                afterUpdate={(newRecord) => {
                                                                    order.internalComment = newRecord.internalComment;
                                                                    this.setState({ dataSource: [...this.state.dataSource] });
                                                                }} />
                                                            {
                                                                this.form.current.getFieldsValue().operator === 0 &&
                                                                <MarkOrder record={order}
                                                                    afterUpdate={(newRecord) => {
                                                                        order.progressStatus = newRecord.progressStatus;
                                                                        this.setState({ dataSource: [...this.state.dataSource] });
                                                                    }} />
                                                            }
                                                            {
                                                                this.form.current.getFieldsValue().operator === 0 &&
                                                                <ShipOrder settings={this.state.settings} record={order}
                                                                    afterUpdate={(newRecord) => {
                                                                        this.loadData();
                                                                    }} />
                                                            }
                                                            {
                                                                this.form.current.getFieldsValue().operator === 1 &&
                                                                <span className="prepare-time">制作完成：{order.prepareTimeString}</span>
                                                            }
                                                        </Space>
                                                    </Col>
                                                    <Col span={24}>
                                                        <div style={{ marginTop: '4px' }}>客户备注：{order.buyerComment}</div>
                                                        <div style={{ marginTop: '4px' }}>备注：{order.internalComment}</div>
                                                    </Col>
                                                </Row>
                                            </td>
                                        </tr>
                                        {order.lineItems?.map((line, lidx) => {
                                            return (<React.Fragment key={line.id}>
                                                <tr className="product-start-row">
                                                    <td colSpan={4}>
                                                        <Row gutter={8}>
                                                            <Col span={2}>
                                                                <span>数量：{line.count}</span>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Text title={line.title} ellipsis>产品：{line.title}</Text>
                                                            </Col>
                                                            <Col span={10}>
                                                                <span>样式：{line.optionsString}</span>
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                </tr>
                                                <tr className="product-end-row" style={{ verticalAlign: 'top' }}>
                                                    <td>
                                                        <Image className="custom-image" fallback={FallbackImage} src={line.thumbnail} preview={false} />
                                                    </td>
                                                    <td className="drag-column">
                                                        <ImageContainer record={line} editable={true} afterUpdate={rd => {
                                                            line.properties = rd.properties;
                                                            this.setState({ dataSource: [...this.state.dataSource] });
                                                        }} />
                                                    </td>
                                                    <td>
                                                        <DesignImageContainer record={line} editable={true} afterUpdate={rd => {
                                                            line.designImageSmallList = rd.designImageSmallList;
                                                            line.designImageList = rd.designImageList;
                                                            this.setState({ dataSource: [...this.state.dataSource] });
                                                        }} />
                                                    </td>
                                                    <td>
                                                        <ExpressForm record={line} editable={true} afterUpdate={rd => {
                                                            line.expressName = rd.expressName;
                                                            line.expressNumber = rd.expressNumber;
                                                            line.comment = rd.comment;
                                                            this.setState({ dataSource: [...this.state.dataSource] });
                                                        }} />
                                                    </td>
                                                </tr>
                                            </React.Fragment>)
                                        })}
                                    </tbody>)
                                })
                            }
                        </table>
                    </TableContainer>
                    <Pagination size="default" style={{ float: 'right', marginTop: 20 }} onChange={this.handlePageChange} showQuickJumper={true} current={this.state.pageNumber} pageSize={this.state.pageSize} total={this.state.totalCount} />
                </Card>
            </PageContainer>);
    }
}

export default OrderPrepare;