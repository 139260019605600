import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import request from "@utils/request";
import PageLoading from '@components/Common/PageLoading/PageLoading';
import { Card, Form, Input, Row, Col, Button } from "antd";
import './wx-tool.less';

class WXTool extends React.Component {
    state = {
        loading: false,
        data: 'result'
    }
    form = React.createRef();

    handleSubmit = () => {
        this.form.current.validateFields().then(values => {
            const _this = this;
            PageLoading.show();
            request.get(values.url).then(function (response) {
                PageLoading.hide();
                console.log(response);
                _this.setState({ data: JSON.stringify(response) });
            });
        }).catch(errorInfo => { });
    }

    render() {
        return (<PageContainer className="wx-tool-page">
            <Card loading={this.state.loading}>
                <Row gutter={24}>
                    <Col xs={24}>
                        <Form ref={this.form}>
                            <Form.Item label="Url" name="url" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Button onClick={this.handleSubmit} type="primary">提交</Button>
                <div>{this.state.data}</div>
            </Card>
        </PageContainer >);
    }
}

export default WXTool;