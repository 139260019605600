import {
  createFromIconfontCN
} from '@ant-design/icons';

const IconFontUrl = '//at.alicdn.com/t/font_2648894_n6chhgbi06.js';

const IconFont = createFromIconfontCN({
  scriptUrl: IconFontUrl,
});

export default IconFont;
export {
  IconFontUrl
};