import React from "react";
import request from "@utils/request";
import { Modal, Spin, Alert, Button, Form, Input, Row, Col, Card, Image } from 'antd';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import ImageContainer from '@components/Order/ImageContainer/ImageContainer';
import FallbackImage from '@common/CommonImage';
import './CloneOrder.less';
import commonUtil from "@utils/commonUtil";

class CloneOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            errorMsg: null
        }
    }

    form = React.createRef();

    handleOk = () => {
        const { record } = this.props;
        const { lineItems } = this.state;
        this.form.current.validateFields().then(values => {
            const controller = new AbortController();
            const { signal } = controller;
            this.controller = controller;
            this.setState({ loading: true, errorMsg: null });
            const _this = this;

            const newRecord = {
                ...record,
                orderNumber: values.orderNumber,
                receiverName: values.receiverName,
                receiverCountry: values.receiverCountry,
                secondLine: values.secondLine,
                firstLine: values.firstLine,
                city: values.city,
                state: values.state,
                zip: values.zip,
                phoneNumber: values.phoneNumber,
                email: values.email,
                company: values.company,
                shippingMethod: values.shippingMethod,
                lineItems: []
            };
            values.lineItems.forEach((item, idx) => {
                const mergedItem = { ...lineItems[idx], ...item };
                newRecord.lineItems.push(mergedItem);
            })
            request.post('/Order/CloneOrder', {
                signal,
                data: newRecord
            }).then(function (response) {
                _this.setState({ loading: false });
                if (response && response.success) {
                    _this.setState({ visible: false });
                    newRecord.reachable = response.result;
                    if (_this.props.afterUpdate) {
                        _this.props.afterUpdate(newRecord);
                    }
                }
                if (response && response.success === false) {
                    _this.setState({ errorMsg: response.errorMsg });
                }
            });
        }).catch(errorInfo => { });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleRemoveProduct = (idx) => {
        const newItems = this.state.lineItems.slice();
        newItems.splice(idx, 1);
        const formItems = this.form.current.getFieldsValue().lineItems;
        formItems.splice(idx, 1);
        this.form.current.setFieldsValue({ "lineItems": formItems });
        this.setState({ lineItems: newItems });
    }

    handleOpen = () => {
        if (this.controller) {
            this.controller.abort();
        }
        this.setState({ visible: true, errorMsg: null, lineItems: [...this.props.record.lineItems] }, () => {
            this.form.current.resetFields();
        });
    }

    render() {
        const { record } = this.props;
        const { lineItems } = this.state;
        return (
            <>
                <Button shape="round" type="primary" onClick={this.handleOpen} icon={<CopyOutlined />}>
                    复制订单
                </Button>
                <Modal title="复制订单"
                    wrapClassName="clone-order-modal"
                    okButtonProps={{ loading: this.state.loading }}
                    maskClosable={false}
                    width={800}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Spin spinning={this.state.loading}>
                        {!!this.state.errorMsg && <Alert message={this.state.errorMsg} style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                        <Form ref={this.form} labelAlign="left" labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} initialValues={{
                            receiverName: record.receiverName,
                            receiverCountry: record.receiverCountry,
                            secondLine: record.secondLine,
                            firstLine: record.firstLine,
                            city: record.city,
                            state: record.state,
                            zip: record.zip,
                            phoneNumber: record.phoneNumber,
                            email: record.email,
                            company: record.company,
                            shippingMethod: record.shippingMethod,
                            lineItems: lineItems
                        }}>
                            <Form.Item label="原始单号">
                                <div>{record.orderNumber}</div>
                            </Form.Item>
                            <Form.Item label="新建单号" name="orderNumber" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="收件人" name="receiverName" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="收件人国家" name="receiverCountry" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="门牌号/建筑物" name="secondLine">
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="街道名" name="firstLine" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="城市" name="city" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="省/州" name="state" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="收货邮编" name="zip" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="收件人手机" name="phoneNumber">
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="邮箱" name="email" rules={[{ type: "email" }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="公司名称" name="company">
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="运输方式" name="shippingMethod">
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Row gutter={12}>
                                {
                                    lineItems?.map((lineItem, idx) =>
                                        <Col key={lineItem.id} xs={24} sm={12} md={8}>
                                            <Card className="product-item-card"
                                                size="small"
                                                cover={<Image className="custom-image" src={lineItem.thumbnail} fallback={FallbackImage} preview={false} />}>
                                                <Form.Item label="产品" labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
                                                    <div>{lineItem.title}</div>
                                                </Form.Item>
                                                <Form.Item label="样式" labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
                                                    <div>{lineItem.optionsString}</div>
                                                </Form.Item>
                                                <Form.Item label="定制信息" labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
                                                    <div style={{ padding: '12px' }}>
                                                        <ImageContainer record={lineItem} editable={false} />
                                                    </div>
                                                </Form.Item>
                                                <Form.Item label="数量"
                                                    labelCol={{ span: 7 }}
                                                    wrapperCol={{ span: 17 }}
                                                    name={['lineItems', idx, 'count']}
                                                    rules={[{ required: true }, { validator: (_, value) => commonUtil.greaterThanZero(_, value, "件数") }]}>
                                                    <Input autoComplete="off" />
                                                </Form.Item>
                                                <Button type="primary" ghost danger style={{ width: "100%" }} onClick={() => this.handleRemoveProduct(idx)} icon={<DeleteOutlined />}>移除</Button>
                                            </Card>
                                        </Col>)
                                }
                            </Row>
                        </Form>
                    </Spin>
                </Modal>
            </>
        );
    }
};

export default CloneOrder;