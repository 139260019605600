import { CheckOutlined, WarningOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import IconFont from '@components/Common/IconFont';
import classNames from 'classnames';
import './TrackStatus.less';

const TrackStatus = (props) => {
    const { status = 0, iconSize = 22, height = 40, width = 40, className = '', ...rest } = props;
    let trackIcon = null;
    switch (status) {
        case 1:
            trackIcon = <span className="track-box" style={{ height, width, backgroundColor: '#b6b6b6' }}><IconFont style={{ color: '#fff', fontSize: iconSize }} type="icon-hourglass" /></span>;
            break;
        case 2:
            trackIcon = <span className="track-box" style={{ height, width, backgroundColor: '#1890ff' }}><IconFont style={{ color: '#fff', fontSize: iconSize }} type="icon-flight" /></span>;
            break;
        case 3:
            trackIcon = <span className="track-box" style={{ height, width, backgroundColor: '#52c41a' }}><CheckOutlined style={{ color: '#fff', fontSize: iconSize }} /></span>;
            break;
        case 4:
            trackIcon = <span className="track-box" style={{ height, width, backgroundColor: '#ff4d4f' }}><ExclamationCircleOutlined style={{ color: '#fff', fontSize: iconSize }} /></span>;
            break;
        case 5:
            trackIcon = <span className="track-box" style={{ height, width, backgroundColor: '#faad14' }}><WarningOutlined style={{ color: '#fff', fontSize: iconSize }} /></span>;
            break;
        default:
            trackIcon = <span className="track-box" style={{ height, width, backgroundColor: '#000' }}><IconFont style={{ color: '#fff', fontSize: iconSize }} type="icon-blur" /></span>;
            break;
    }

    return (<span className={classNames("track-status-container", className)} {...rest}>{trackIcon}</span>);
}

export default TrackStatus;