import React from "react";
import { Button, Modal, Spin, Alert, Row, Col, Form, Input, Typography, Checkbox } from "antd";
import { EditOutlined } from '@ant-design/icons';
import request from "@utils/request";
import './ExpressForm.less';

const { TextArea } = Input;
const { Text } = Typography;

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
};

class ExpressForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            loading: false,
            visible: false,
            checked: false
        }
    }

    form = React.createRef();

    handleOpen = () => {
        if (this.controller) {
            this.controller.abort();
        }
        this.setState({ visible: true, hasError: false, checked: false }, () => {
            this.form.current.resetFields();
        });
    }

    handleOk = () => {
        const _this = this;
        this.form.current.validateFields().then(values => {
            this.setState({
                loading: true,
                hasError: false,
            });
            let expressInfo = {
                id: this.props.record.id,
                expressName: values.expressName,
                expressNumber: values.expressNumber,
                comment: values.comment
            };
            request.post('/Order/SaveExpress', {
                data: expressInfo
            }).then(function (response) {
                _this.setState({ loading: false });
                if (response && response.success) {
                    _this.setState({ visible: false });
                    if (_this.props.afterUpdate) {
                        _this.props.afterUpdate(expressInfo);
                    }
                }
                if (response && response.success === false) {
                    _this.setState({ hasError: true });
                }
            })
        }).catch(errorInfo => { });
    }

    handleCancel = () => {
        this.setState({ visible: false });
    }

    handleCheckChange = (e) => {
        this.setState({ checked: e.target.checked });
        this.form.current.setFieldsValue({
            expressName: e.target.checked ? "库存" : "",
            expressNumber: e.target.checked ? "InStock" : "",
        });
    }

    render() {
        const { record } = this.props;
        return <div className="express-form-container" style={{ paddingBottom: this.props.editable ? 0 : '8px' }}>
            <Row wrap={false}>
                <Col flex="45px">名称：</Col>
                <Col flex="auto" className="express-value">{record.expressName}</Col>
            </Row>
            <Row style={{ marginTop: '2px' }} wrap={false}>
                <Col flex="45px">单号：</Col>
                <Col flex="auto" className="express-value">{record.expressNumber}</Col>
            </Row>
            <Row style={{ marginTop: '2px' }} wrap={false}>
                <Col flex="45px">备注：</Col>
                <Col flex="auto" className="express-value"><Text mark={record.comment?.toLowerCase()?.includes('dhl') ? true : false}>{record.comment}</Text></Col>
            </Row>
            {
                this.props.editable && <div className="edit-btn-container">
                    <Button type="link" disabled={this.props.orderStatus > 4 ? true : false} icon={<EditOutlined />} onClick={this.handleOpen}>
                        更新快递
                    </Button>
                </div>
            }
            <Modal title="更新快递"
                className="express-form-modal"
                okButtonProps={{ loading: this.state.loading }}
                maskClosable={false}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
            >
                <Spin spinning={this.state.loading}>
                    {this.state.hasError && <Alert message="更新快递失败！" style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                    <Form {...layout} ref={this.form} initialValues={{
                        expressName: record.expressName,
                        expressNumber: record.expressNumber,
                        comment: record.comment
                    }}>
                        <Form.Item name="expressName" label="名称" rules={[{ required: true }]}>
                            <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item name="expressNumber" label="单号" rules={[{ required: true }]}>
                            <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item name="comment" label="备注">
                            <TextArea rows={4} />
                        </Form.Item>
                        {!!this.props.record.stockName && <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 21, push: 3 }}>
                                <div>
                                    <Checkbox checked={this.state.checked} onChange={this.handleCheckChange}>填入默认快递名称和单号</Checkbox>
                                </div>
                                <Text type="secondary">此商品为存储型商品，将使用库存进行发货（{this.props.record.stockName}当前库存 {this.props.record.stockCount}）</Text>
                            </Col>
                        </Row>
                        }
                    </Form>
                </Spin>
            </Modal>
        </div>;
    }
}

export default ExpressForm;