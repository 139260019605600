import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import { Card, Button, Row, Col, Select, Form, Typography, Tooltip, Modal, InputNumber, message } from "antd";
import request from "@utils/request";
import commonConfig from '@common/commonConfig';
import PageLoading from '@components/Common/PageLoading/PageLoading';
import IconFont from '@components/Common/IconFont';
import TableContainer from "@components/Common/TableContainer/TableContainer";
import { SyncOutlined, ExclamationCircleOutlined, CheckCircleOutlined, RightOutlined, DownOutlined, DollarOutlined } from '@ant-design/icons';
import commonUtil from "@utils/commonUtil";
import './manage-price.less';

const { Option } = Select;
const { Text } = Typography;

const topSiteSelectProps = {
    xs: 24,
    sm: 10,
    md: 10,
    lg: 10,
    xl: 10,
};

const topFilterSelectProps = {
    xs: 24,
    sm: 14,
    md: 14,
    lg: 14,
    xl: 14,
};

class ManagePrice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            sites: null,
            products: [],
            totalCount: 0,
            productPageSize: 250,
            showLoadBtn: true,
            next_page_info: null,
            dataSource: null,
            pageSize: commonConfig.pageSize,
            visible: false,
            batchVisible: false
        }
    }

    form = React.createRef();
    changeForm = React.createRef();
    batchForm = React.createRef();

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/Review/GetShopifySites').then((response) => {
            this.setState({ loading: false });
            if (response && response.success) {
                _this.setState({
                    sites: response.results,
                }, () => {
                    _this.form.current.setFieldsValue({
                        site: response.results?.length > 0 ? response.results[0].id : null
                    });
                    if (response.results?.length > 0) {
                        _this.load_collections();
                    }
                });
            }
        });
    }

    load_collections = () => {
        const _this = this;
        const values = this.form.current.getFieldsValue();
        const { sites } = this.state;
        const siteObj = sites.find(s => s.id === values.site);
        PageLoading.show();
        request.post('/Base/SendShopifyRequest', {
            data: {
                site_id: values.site,
                address: "https://" + siteObj.name + "/admin/api/2021-01/custom_collections.json?limit=250",
                method: 'GET'
            }
        }).then(function (response) {
            if (!!response?.errors) {
                commonUtil.showShopifyError(response);
                PageLoading.hide();
            } else {
                const collections = response.custom_collections;
                collections.splice(0, 0, { id: 0, title: 'All Products' });
                _this.setState({ collections: collections }, () => {
                    _this.form.current.setFieldsValue({
                        collection: collections[0].id
                    });
                    _this.load_count();
                });
            }
        });
    }

    load_count = () => {
        PageLoading.show();
        const _this = this;
        const values = this.form.current.getFieldsValue();
        const { sites } = this.state;
        const siteObj = sites.find(s => s.id === values.site);
        const collectionfilter = values.collection === 0 ? "" : "?collection_id=" + values.collection;
        request.post('/Base/SendShopifyRequest', {
            data: {
                site_id: values.site,
                address: "https://" + siteObj.name + "/admin/api/2021-04/products/count.json" + collectionfilter,
                method: 'GET'
            }
        }).then(function (response) {
            if (!!response?.errors) {
                PageLoading.hide();
                commonUtil.showShopifyError(response);
            } else {
                _this.setState({ totalCount: response.count }, () => _this.load_products());
            }
        });
    }

    load_products = () => {
        PageLoading.show();
        const _this = this;
        const values = this.form.current.getFieldsValue();
        const { products, totalCount, sites, productPageSize } = this.state;
        const siteObj = sites.find(s => s.id === values.site);
        let next_page_info_url = "";
        if (this.next_page_info) {
            next_page_info_url = "&page_info=" + this.next_page_info;
        }
        let collectionfilter = values.collection === 0 ? "" : "&collection_id=" + values.collection;
        let url_query = next_page_info_url ? next_page_info_url : collectionfilter;
        request.post('/Base/SendShopifyRequest', {
            data: {
                site_id: values.site,
                address: "https://" + siteObj.name + "/admin/api/2021-04/products.json?fields=id,title,variants&limit=" + productPageSize + url_query,
                method: 'GET'
            }
        }).then(function (response) {
            PageLoading.hide();
            if (!!response?.errors) {
                commonUtil.showShopifyError(response);
            } else {
                const newPdts = [...products].concat(response.products);
                _this.setState({ products: newPdts, showLoadBtn: totalCount > newPdts.length });
                _this.next_page_info = response.next_page_info;
            }
        });
    }

    handleLoadMore = () => {
        this.load_products();
    }

    handleValuesChange = (values) => {
        if (values.site !== undefined) {
            this.next_page_info = null;
            this.setState({
                collections: null,
                products: [],
                totalCount: 0,
                showLoadBtn: true
            }, () => {
                this.form.current.setFieldsValue({
                    collection: null
                });
                this.load_collections();
            });
        }
        if (values.collection !== undefined) {
            this.next_page_info = null;
            this.setState({
                products: [],
                totalCount: 0,
                showLoadBtn: true
            }, () => {
                this.load_count();
            });
        }
    }

    handleExpand = (pdt) => {
        const newPdt = { ...pdt, expanded: !pdt.expanded };
        const { products } = this.state;
        const newProducts = [...products];
        const idx = products.indexOf(pdt);
        newProducts[idx] = newPdt;
        this.setState({ products: newProducts });
    }

    handleOpen = (pdt) => {
        this.currentPdt = pdt;
        this.setState({ visible: true });
    }

    handleOk = () => {
        this.changeForm.current.validateFields().then(values => {
            this.changePrice(this.currentPdt, values.rate);
        }).catch(errorInfo => { });
    };

    changePrice = (currentPdt, rate, success, thendo) => {
        const { sites, products } = this.state;
        const _this = this;
        this.setState({
            visible: false
        });
        const idx = products.indexOf(currentPdt);
        const newPdt = { ...currentPdt, changeStatus: 1 };
        const newProducts = [...products];
        newProducts[idx] = newPdt;
        this.setState({ products: newProducts });
        const variants = currentPdt.variants.map(v => {
            let vart = {
                id: v.id,
                price: rate * v.price
            }
            if (v.compare_at_price) {
                vart.compare_at_price = rate * v.compare_at_price
            }
            return vart;
        })
        const pdata = {
            product: {
                id: currentPdt.id,
                variants: variants
            }
        }
        const selectValues = this.form.current.getFieldsValue();
        const siteObj = sites.find(s => s.id === selectValues.site);
        request.post('/Base/SendShopifyRequest', {
            data: {
                site_id: siteObj.id,
                address: "https://" + siteObj.name + "/admin/api/2021-04/products/" + currentPdt.id + ".json",
                method: 'PUT',
                data: JSON.stringify(pdata),
                audit: 'changeprice'
            }
        }).then(function (response) {
            const resultPdt = { ...newPdt, changeStatus: 1 };
            const resultPdts = [...newProducts];
            resultPdts[idx] = resultPdt;
            if (!!response?.errors) {
                commonUtil.showShopifyError(response);
                resultPdt.changeStatus = 3;
            } else {
                resultPdt.changeStatus = 2;
                resultPdt.variants = response.product.variants;
                if (success) {
                    success();
                }
            }
            _this.setState({ products: resultPdts });
            if (thendo) {
                thendo();
            }
        });
    }

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleBatchOpen = () => {
        this.setState({ batchVisible: true });
    }

    handleBatchOk = () => {
        this.batchForm.current.validateFields().then(values => {
            this.setState({
                batchVisible: false
            });
            this.finishCount = 0;
            this.asyncChangeAll(0);
        }).catch(errorInfo => { });
    };

    asyncChangeAll(pcount) {
        const _this = this;
        const { products } = this.state;
        const values = this.batchForm.current.getFieldsValue();
        if (pcount < products.length) {
            this.changePrice(products[pcount], values.rate, function () {
                _this.finishCount++;
            }, function () {
                pcount++;
                _this.asyncChangeAll(pcount);
            });
        }
        if (pcount === products.length) {
            if (products.length === _this.finishCount) {
                message.success(`一共 ${products.length} 个产品，全部修改成功！`);
            } else {
                message.error(`一共 ${products.length} 个产品，修改成功 ${_this.finishCount} 个， 其余失败的产品请单独修改！`);
            }

        }
    }

    handleBatchCancel = () => {
        this.setState({ batchVisible: false });
    };

    render() {
        const { products } = this.state;
        return (
            <PageContainer className="manage-price-page">
                <Card loading={this.state.loading}>
                    <Button onClick={this.handleBatchOpen} type="primary" ghost icon={<IconFont type="icon-price-stock" />} >批量修改价格</Button>
                    <Form
                        ref={this.form}
                        style={{ marginTop: 12 }}
                        onValuesChange={this.handleValuesChange}
                    >
                        <Row className="top-select-row" gutter={24}>
                            <Col {...topSiteSelectProps}>
                                <Form.Item name="site" label="平台">
                                    <Select>
                                        {this.state.sites?.map(site => {
                                            return <Option key={site.id} value={site.id}>{site.displayName}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...topFilterSelectProps}>
                                <Form.Item label="类别" style={{ marginBottom: 0 }}>
                                    <Form.Item name="collection" style={{ display: 'inline-block', width: 'calc(100% - 100px)' }}>
                                        <Select showSearch filterOption={(input, option) => {
                                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }}>
                                            {this.state.collections?.map(collection => {
                                                return <Option key={collection.id} value={collection.id}>{collection.title}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item style={{ display: 'inline-block' }}>
                                        <span style={{ margin: '0 4px' }}>{`${this.state.products?.length}/${this.state.totalCount}`}</span>
                                        {this.state.showLoadBtn && <Button type="primary" shape="circle" ghost onClick={this.handleLoadMore} icon={<IconFont type="icon-published_with_changes" />}></Button>}
                                    </Form.Item>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <TableContainer>
                        <table>
                            <colgroup>
                                <col width="24px" />
                                <col width="200" />
                                <col width="80" />
                                <col width="80" />
                                <col width="60" />
                                <col width="60" />
                            </colgroup>
                            <thead className="ant-table-thead">
                                <tr>
                                    <th></th>
                                    <th>商品名</th>
                                    <th>现价</th>
                                    <th>原价</th>
                                    <th>更新状态</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                                {
                                    products?.map(pdt => {
                                        return (<tr key={pdt.id}>
                                            <td>
                                                <div>
                                                    <span style={{ cursor: 'pointer' }} onClick={() => this.handleExpand(pdt)}>
                                                        {!pdt.expanded && <RightOutlined />}
                                                        {!!pdt.expanded && <DownOutlined />}
                                                    </span>
                                                </div>
                                            </td>
                                            <td><Text ellipsis={!pdt.expanded}>{pdt.title}</Text></td>
                                            <td>
                                                <div>
                                                    <ul className="price-list">
                                                        {
                                                            (() => {
                                                                const items = pdt.variants?.map((vat, idx) => <li key={idx}>
                                                                    <span>{vat.price}</span>
                                                                </li>)
                                                                if (!pdt.expanded) {
                                                                    return items.length > 0 ? items[0] : '';
                                                                }
                                                                else {
                                                                    return items;
                                                                }
                                                            })()
                                                        }
                                                    </ul>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <ul className="price-list">
                                                        {
                                                            (() => {
                                                                const items = pdt.variants?.map((vat, idx) => <li key={idx}>
                                                                    <span>{vat.compare_at_price}</span>
                                                                </li>)
                                                                if (!pdt.expanded) {
                                                                    return items.length > 0 ? items[0] : '';
                                                                }
                                                                else {
                                                                    return items;
                                                                }
                                                            })()
                                                        }
                                                    </ul>
                                                </div>
                                            </td>
                                            <td className="status-col">
                                                <div>
                                                    {(() => {
                                                        switch (pdt.changeStatus) {
                                                            case 1:
                                                                return <SyncOutlined spin style={{ fontSize: 18, color: '#1890ff' }} />;
                                                            case 2:
                                                                return <CheckCircleOutlined style={{ fontSize: 18, color: '#52c41a' }} />;
                                                            case 3:
                                                                return <ExclamationCircleOutlined style={{ fontSize: 18, color: '#ff4d4f' }} />;
                                                            default:
                                                                return '';
                                                        }
                                                    })()
                                                    }
                                                </div>
                                            </td>
                                            <td className="action-col">
                                                <div>
                                                    <Tooltip title="修改价格">
                                                        <Button type="primary" onClick={() => this.handleOpen(pdt)} shape="circle" icon={<DollarOutlined />}></Button>
                                                    </Tooltip>
                                                </div>
                                            </td>
                                        </tr>)
                                    })
                                }
                            </tbody>
                        </table>
                    </TableContainer>
                </Card>
                <Modal title="修改价格"
                    wrapClassName="platform-change-price-modal"
                    maskClosable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Form ref={this.changeForm} labelAlign="left" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                        <Form.Item label="调整比例" name="rate" rules={[{ required: true }, { validator: (_, value) => commonUtil.greaterThanZero(_, value, "调整比例") }]}>
                            <InputNumber step={0.1} style={{ width: '100%' }} />
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal title="批量修改价格"
                    wrapClassName="platform-batch-change-price-modal"
                    maskClosable={false}
                    visible={this.state.batchVisible}
                    onOk={this.handleBatchOk}
                    onCancel={this.handleBatchCancel}
                >
                    <Form ref={this.batchForm} labelAlign="left" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                        <Form.Item label="调整比例" name="rate" rules={[{ required: true }, { validator: (_, value) => commonUtil.greaterThanZero(_, value, "调整比例") }]}>
                            <InputNumber step={0.1} style={{ width: '100%' }} />
                        </Form.Item>
                    </Form>
                </Modal>
            </PageContainer>);
    }
}

export default ManagePrice;