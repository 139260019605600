import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import { Button, Card, Space, Image, Row, Col, Pagination, Typography, message, Modal, Tooltip } from 'antd';
import { FileDoneOutlined, FileProtectOutlined } from '@ant-design/icons';
import PageLoading from '@components/Common/PageLoading/PageLoading';
import TableContainer from "@components/Common/TableContainer/TableContainer";
import request from "@utils/request";
import commonConfig from '@common/commonConfig';
import ExpressForm from '@components/Order/ExpressForm/ExpressForm';
import ImageContainer from '@components/Order/ImageContainer/ImageContainer';
import DesignImageContainer from "@components/Order/DesignImageContainer/DesignImageContainer";
import FallbackImage from '@common/CommonImage';
import './declare.less';

const { Text } = Typography;

class OrderDeclare extends React.Component {
    state = {
        loading: false,
        dataSource: null,
        totalCount: 0,
        pageNumber: 1,
        pageSize: commonConfig.pageSize,
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const _this = this;
        PageLoading.show();
        request.get('/Order/GetDeclareOrders', {
            params: {
                pageSize: this.state.pageSize,
                pageNumber: this.state.pageNumber,
                orderStatus: 3
            }
        }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.setState({
                    dataSource: response.result.data,
                    totalCount: response.result.totalRecord
                });
            }
        });
    }

    handlePageChange = (page, pageSize) => {
        this.setState({
            pageNumber: page,
            pageSize: pageSize
        }, this.loadData);
    }

    handleDelcareOrder = (order, isMandatory = false) => {
        if (order.lineItems.some(l => !l.expressName || !l.expressNumber)) {
            Modal.warning({
                title: "请注意",
                content: "请填入快递名称和单号后再进行预报订单！"
            });
            return;
        }
        const url = isMandatory ? "/Order/MandatoryDeclareOrder" : "/Order/DeclareOrder";
        const errorMsg = isMandatory ? "强制预报订单失败！" : "预报订单失败！";
        const successMsg = isMandatory ? "强制预报订单成功！" : "预报订单成功！";
        const _this = this;
        PageLoading.show();
        request.post(url, {
            data: order
        }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                message.success(successMsg);
                _this.loadData();
            }
            if (response && response.success === false) {
                message.error(`${errorMsg}${response.errorMsg}`);
            }
        });
    }

    render() {
        return (<PageContainer className="order-declare-page">
            <Card loading={this.state.loading}>
                <TableContainer className="order-table">
                    <table>
                        <colgroup>
                            <col width="90" />
                            <col width="120" />
                            <col width="120" />
                            <col width="400" />
                            <col width="180" />
                        </colgroup>
                        <thead className="ant-table-thead">
                            <tr>
                                <th>客户单号</th>
                                <th>产品图片</th>
                                <th>定制信息</th>
                                <th>效果图</th>
                                <th>快递单号</th>
                            </tr>
                        </thead>
                        {
                            this.state.dataSource?.map(order => {
                                return (<tbody key={order.id} className="ant-table-tbody">
                                    <tr className="order-extra-row">
                                        <td rowSpan={1 + order.lineItems.length * 2}>
                                            <div className="order-number-container">
                                                <h4 className="h4-info">
                                                    {order.orderNumber}
                                                </h4>
                                            </div>
                                        </td>
                                        <td colSpan={4} className="order-extra-col">
                                            <Row className="order-extra-info">
                                                <Col span={20}>
                                                    <Space direction="vertical" size={4}>
                                                        <Space size={["middle", 4]} wrap={true}>
                                                            <span>运单号：{order.expressId}</span>
                                                            <span>渠道：{order.transportWay}</span>
                                                            <span>收件人：{order.receiverName}</span>
                                                            <span>国家：{order.receiverCountry}</span>
                                                        </Space>
                                                        <div>备注：{order.internalComment}</div>
                                                    </Space>
                                                </Col>
                                                <Col span={4}>
                                                    <Space>
                                                        <Button type="primary" onClick={() => this.handleDelcareOrder(order)} shape="round" icon={<FileDoneOutlined />} >
                                                            预报
                                                        </Button>
                                                        <Tooltip title="强制预报">
                                                            <Button type="primary" onClick={() => this.handleDelcareOrder(order, true)} ghost shape="circle" icon={<FileProtectOutlined />} />
                                                        </Tooltip>
                                                    </Space>
                                                </Col>
                                            </Row>
                                        </td>
                                    </tr>
                                    {order.lineItems?.map((line, lidx) => {
                                        return (<React.Fragment key={line.id}>
                                            <tr className="product-start-row">
                                                <td colSpan={4}>
                                                    <Row gutter={8}>
                                                        <Col span={2}>
                                                            <span>数量：{line.count}</span>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Text title={line.title} ellipsis>产品：{line.title}</Text>
                                                        </Col>
                                                        <Col span={10}>
                                                            <span>样式：{line.optionsString}</span>
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr className="product-end-row" style={{ verticalAlign: 'top' }}>
                                                <td>
                                                    <Image className="custom-image" fallback={FallbackImage} src={line.thumbnail} preview={false} />
                                                </td>
                                                <td className="drag-column">
                                                    <ImageContainer editable={false} record={line} afterUpdate={rd => {
                                                        line.properties = rd.properties;
                                                        this.setState({ dataSource: [...this.state.dataSource] });
                                                    }} />
                                                </td>
                                                <td>
                                                    <DesignImageContainer editable={false} record={line} afterUpdate={rd => {
                                                        line.designImageSmallList = rd.designImageSmallList;
                                                        line.designImageList = rd.designImageList;
                                                        this.setState({ dataSource: [...this.state.dataSource] });
                                                    }} />
                                                </td>
                                                <td>
                                                    <ExpressForm record={line} editable={true} afterUpdate={rd => {
                                                        line.expressName = rd.expressName;
                                                        line.expressNumber = rd.expressNumber;
                                                        line.comment = rd.comment;
                                                        this.setState({ dataSource: [...this.state.dataSource] });
                                                    }} />
                                                </td>
                                            </tr>
                                        </React.Fragment>)
                                    })}
                                </tbody>)
                            })
                        }
                    </table>
                </TableContainer>
                <Pagination size="default" style={{ float: 'right', marginTop: 20 }} onChange={this.handlePageChange} showQuickJumper={true} current={this.state.pageNumber} pageSize={this.state.pageSize} total={this.state.totalCount} />
            </Card>
        </PageContainer>);
    }
}

export default OrderDeclare;