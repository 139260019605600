import React from "react";
import { Button, Image, Badge, Modal, Spin, Alert, Upload, Input, Space, message } from "antd";
import { InboxOutlined, EditOutlined } from '@ant-design/icons';
import request from "@utils/request";
import commonConfig from '@common/commonConfig';
import FallbackImage from '@common/CommonImage';
import classNames from 'classnames';
import './ImageContainer.less';

const { Dragger } = Upload;
const { TextArea } = Input;

class ImageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            previewVisible: false,
            hasError: false,
            loading: false,
            visible: false
        }
    }

    generateFileFromOption = (img) => {
        return {
            name: img.name,
            status: 'done',
            url: img.value,
            optionName: img.name
        }
    }

    handleOpen = () => {
        const { record } = this.props;
        if (this.controller) {
            this.controller.abort();
        }
        const images = record?.properties?.filter(p => p.optionType === 1);
        const messages = record?.properties?.filter(p => p.optionType === 0);
        const fileList = images?.length > 0 ? images.map(img => {
            return this.generateFileFromOption(img);
        }) : [];
        const customMessages = messages?.length > 0 ? messages.map(m => { return { ...m } }) : [{ name: "Custom Content", value: "", optionType: 0 }];
        this.setState({
            visible: true,
            hasError: false,
            fileList: fileList,
            customMessages: customMessages
        });
    }

    handleOk = () => {
        const { record } = this.props;
        const newImages = this.state.fileList.filter(f => f.status === 'done').map(f => {
            return {
                name: f.optionName,
                value: f.url,
                optionType: 1
            }
        });
        const allOptions = newImages?.length > 0 ? this.state.customMessages.concat(newImages) : this.state.customMessages;
        const _this = this;
        this.setState({
            loading: true,
            hasError: false,
        });
        const controller = new AbortController();
        const { signal } = controller;
        this.controller = controller;
        request.post(`/Order/UpdateLineCustomProperty/${record.id}`, {
            signal,
            data: allOptions
        }).then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                _this.setState({ visible: false });
                message.success('编辑定制信息成功！');
                if (_this.props.afterUpdate) {
                    _this.props.afterUpdate({ id: _this.props.record.id, properties: allOptions });
                }
            }
            if (response && response.success === false) {
                _this.setState({ hasError: true });
            }
        })
    }

    handleCancel = () => {
        this.setState({ visible: false });
    }

    handleChange = (info) => {
        const { file, fileList } = info;
        if (file.name === this.dropName) {
            return;
        }
        const newFileList = [...fileList];
        const newFile = newFileList.find(f => f.uid === file.uid);
        switch (file.status) {
            case 'uploading':
                newFile.percent = 30;
                break;
            case 'done':
                newFile.url = file?.response?.result.value;
                newFile.originFileObj = null;
                newFile.optionName = "Custom Image";
                break;
            case 'error':
                newFile.originFileObj = null;
                break;
            case 'removed':
                break;
            default:
                const isLt4M = file.size <= commonConfig.allowUploadSize;
                newFile.status = 'error';
                newFile.originFileObj = null;
                newFile.error = { message: isLt4M ? '文件类型不支持' : '文件大小超过限制' };
                break;
        }
        this.setState({
            fileList: newFileList
        });
    }

    customRequest = (options) => {
        const { onSuccess, onError, file } = options;
        if (file.name === this.dropName) {
            this.dropName = "";
            return;
        }
        const formData = new FormData();
        formData.append('files', file);
        formData.append('lineId', this.props.record.id);
        request('/Order/UploadCustomImage', { method: 'post', data: formData, requestType: 'form', }).then(function (response) {
            if (response && response.success) {
                onSuccess(response, file);
            }
            if (response && response.success === false) {
                onError({ message: response.errorMsg }, response);
            }
        });
    }

    beforeUpload = (file) => {
        const isImage = commonConfig.allowImageType.some(t => t.indexOf(file.type.split('/')[1]) > -1);
        const isLt4M = file.size <= commonConfig.allowUploadSize;
        return isImage && isLt4M;
    }

    handleTextChange = (e, message) => {
        message.value = e.target.value;
        this.setState({ customMessages: [...this.state.customMessages] });
    }

    handleDrop = (e) => {
        if (e.dataTransfer.types.indexOf('text/uri-list') > -1) {
            const imageUrl = e.dataTransfer.getData('text/uri-list');
            var subUrl = imageUrl.split('?')[0];
            var imageReg = /http(s?):\/\/\S*?\.(png|jpg|gif|jpeg|bmp|webp)$/i;
            if (imageReg.test(subUrl)) {
                if (e.dataTransfer.files?.length > 0) {
                    this.dropName = e.dataTransfer.files[0].name;
                }
                const _this = this;
                let fileList = [...this.state.fileList];
                var filename = subUrl.substring(subUrl.lastIndexOf('/') + 1);
                const newFile = {
                    uid: -(new Date().getTime()),
                    name: filename,
                    status: 'uploading',
                    percent: 30,
                    optionName: "Custom Image"
                }
                fileList.push(newFile);
                this.setState({
                    fileList: fileList
                })
                request.post(`/Order/UploadCustomImageUrl/${this.props.record.id}`, {
                    data: {
                        value: imageUrl
                    }
                }).then(function (response) {
                    const idx = _this.state.fileList.findIndex(f => f.uid === newFile.uid);
                    const flist = [..._this.state.fileList];
                    if (response && response.success) {
                        const finishFile = {
                            ...newFile,
                            status: 'done',
                            percent: 100,
                            url: response.result.value
                        };
                        flist[idx] = finishFile;
                        _this.setState({
                            fileList: flist
                        });
                    }
                    if (response && response.success === false) {
                        //onError({ message: response.errorMsg }, response);
                        const finishFile = {
                            ...newFile,
                            status: 'error',
                            percent: 100,
                            error: { message: response.errorMsg }
                        };
                        flist[idx] = finishFile;
                        _this.setState({
                            fileList: flist
                        });
                    }
                })
            }
        }
    }

    render() {
        const images = this.props.record?.properties?.filter(p => p.optionType === 1);
        const messages = this.props.record?.properties?.filter(p => p.optionType === 0);
        return <div className={classNames({
            "image-container-editor": true,
            "image-container-editor-editable": this.props.editable,
            "image-container-editor-only-image": !this.props.editable && images?.length > 0 && !(messages?.length > 0),
            "image-container-editor-empty": !this.props.editable && !(images?.length > 0) && !(messages?.length > 0)
        })}>
            {
                images?.length > 0 && <>
                    <Badge count={images.length > 1 ? images.length : 0} style={{ backgroundColor: '#52c41a' }}>
                        <Image
                            preview={{ visible: false }}
                            src={images[0].value}
                            fallback={FallbackImage}
                            onClick={() => this.setState({ previewVisible: true })}
                        />
                    </Badge>
                    <div style={{ display: 'none' }}>
                        <Image.PreviewGroup preview={{ visible: this.state.previewVisible, onVisibleChange: vis => this.setState({ previewVisible: vis }) }}>
                            {
                                images.map((img, idx) => <Image key={idx} src={img.value.replace("_thumb", "")} />)
                            }
                        </Image.PreviewGroup>
                    </div>
                </>
            }
            {
                messages?.length > 0 && messages.map((msg, idx) => <div className="image-container-message" key={idx}>{msg.value}</div>)
            }
            {
                this.props.editable && <Button type="link" icon={<EditOutlined />} onClick={this.handleOpen}>
                    编辑
                </Button>
            }
            <Modal title="编辑定制信息"
                className="image-container-modal"
                okButtonProps={{ loading: this.state.loading }}
                maskClosable={false}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
            >
                <Spin spinning={this.state.loading}>
                    {this.state.hasError && <Alert message="编辑定制信息失败！" style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                    <Dragger
                        name="file"
                        multiple={true}
                        listType="picture-card"
                        showUploadList={{
                            showPreviewIcon: false,
                            showRemoveIcon: true,
                            showDownloadIcon: false
                        }}
                        onDrop={this.handleDrop}
                        accept={commonConfig.allowImageType.join(',')}
                        fileList={this.state.fileList}
                        onChange={this.handleChange}
                        customRequest={this.customRequest}
                        beforeUpload={this.beforeUpload}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">点击或拖动文件到此区域上传</p>
                        <p className="ant-upload-hint">
                            支持单个或批量上传，单个图片上传大小限制为4M。
                        </p>
                    </Dragger>
                    <Space size="small" direction="vertical" style={{ width: '100%' }}>
                        {
                            this.state.customMessages?.map((m, idx) => <TextArea key={idx} value={m.value} onChange={(e) => { this.handleTextChange(e, m) }} rows={4} />)
                        }
                    </Space>
                </Spin>
            </Modal>
        </div >;
    }
}

export default ImageContainer;