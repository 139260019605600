import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import request from "@utils/request";
import { Card, Table, Badge, message } from "antd";
import './agent-info.less';

class AgentInfo extends React.Component {
    state = {
        loading: false,
        dataSource: null
    }

    columns = [
        {
            title: '平台',
            key: 'displayName',
            dataIndex: 'displayName',
            width: 30
        },
        {
            title: '代理地址',
            key: 'remoteUrl',
            dataIndex: 'remoteUrl',
            width: 30
        },
        {
            title: '状态',
            key: 'isActive',
            dataIndex: 'isActive',
            width: 20,
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.isActive !== nextRecord.isActive,
            render: isActive => (isActive?<Badge status="processing" text="运行中" />:<Badge status="error" text="异常" />),
        }
    ];

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/Home/GetAgentInfo').then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                _this.setState({ dataSource: response.result });
            }
            if (response && response.success === false) {
                message.error("系统异常，获取代理状态失败！");
            }
        });
    }

    render() {
        return (<PageContainer className="agent-info-page">
            <Card loading={this.state.loading}>
                <Table rowKey='name'
                    columns={this.columns}
                    dataSource={this.state.dataSource}
                    tableLayout='fixed'
                    size="middle"
                    scroll={{ x: 950 }}
                    pagination={false} />
            </Card>
        </PageContainer >);
    }
}

export default AgentInfo;