import React from "react";
import { Card, Row, Col, Tooltip } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import request from "@utils/request";
import Field from "./components/Field";
import ReactEcharts from 'echarts-for-react';
import classNames from 'classnames';
import ChartCard from "./components/ChartCard";
import Trend from './components/Trend';
import IconFont from "../../../components/Common/IconFont";
import withBreakpoint from '@components/Common/withBreakpoint';
import './dashboard.less';

const topColResponsiveProps = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 6,
    style: { marginBottom: 24 },
};

const topPieResponsiveProps = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12,
};

const topChartResponsiveProps = {
    xs: 24,
    sm: 24,
    md: 8,
    lg: 8,
    xl: 8,
};

class DashBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            pieData: [],
            data: null,
            totalCount: 0
        }
        this.echartRef = null;
    }
    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/Home/GetDashboardData').then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                const data = response.result;
                let pieData = [];
                let totalCount = 0;
                let otherCountry = {
                    value: 0,
                    name: "其他国家",
                    countryCode: "others",
                    percentage: 100,
                    checked: true
                }
                for (let i = 0; i < data?.countrySales?.length; i++) {
                    let countryData = data.countrySales[i];
                    if (i < 5) {
                        pieData.push({
                            value: countryData.count,
                            name: countryData.countryName,
                            countryCode: countryData.countryCode,
                            percentage: countryData.percentage.toFixed(2),
                            checked: true
                        });
                        otherCountry.percentage -= countryData.percentage;
                    } else {
                        otherCountry.value += countryData.count;
                    }
                    totalCount += countryData.count;
                }
                if (data?.countrySales?.length > 5) {
                    otherCountry.percentage = otherCountry.percentage.toFixed(2);
                    pieData.push(otherCountry);
                }
                _this.setState({ data: data, pieData: pieData, totalCount: totalCount });
            }
        });
    }

    handleSelectLegendChanged = (params) => {
        const name = params.name;
        const checked = params.selected[params.name];
        const pieData = this.state.pieData.slice();
        const row = pieData.find(p => p.name === name);
        row.checked = checked;
        this.setState({ pieData: pieData });
    }

    handleRowClick = (rowData) => {
        rowData.checked = !rowData.checked;
        const echartInstance = this.echartRef.getEchartsInstance();
        echartInstance.dispatchAction({
            type: 'legendToggleSelect',
            name: rowData.name
        })
        this.setState({ pieDatata: [...this.state.pieData] });
    }

    handleMouseEnter = (rowData) => {
        const echartInstance = this.echartRef.getEchartsInstance();
        echartInstance.dispatchAction({
            type: 'highlight',
            name: rowData.name
        })
        this.setState({ pieDatata: [...this.state.pieData] });
    }

    handleMouseLeave = (rowData) => {
        const echartInstance = this.echartRef.getEchartsInstance();
        echartInstance.dispatchAction({
            type: 'downplay',
            name: rowData.name
        })
        this.setState({ pieDatata: [...this.state.pieData] });
    }

    render() {
        const isXS = this.props.breakpoint.xs === true;
        const echartInstance = this.echartRef?.getEchartsInstance();
        const { totalCount } = this.state;
        const option = {
            title: {
                text: '全球销量',
                subtext: totalCount,
                x: 'center',
                y: 'center',
                itemGap: 10,
                textStyle: {
                    fontSize: '18',
                    color: 'black',
                    fontWeight: '400'
                },
                top: '42%',
                subtextStyle: {
                    fontSize: '16',
                    color: 'black',
                    fontWeight: '400'
                }
            },
            tooltip: {
                trigger: 'item'
            },
            legend: {
                show: false,
            },
            // graphic: {
            //     type: "text",
            //     left: "center",
            //     top: "50%",
            //     style: {
            //         text: '全球销量',
            //         textAlign: "center",
            //         fill: "#333",
            //         fontSize: 18,
            //         fontWeight: 500
            //     }
            // },
            series: [
                {
                    name: '销量',
                    type: 'pie',
                    height: 280,
                    top: 'middle',
                    radius: ['50%', '80%'],
                    selectedMode: 'single',
                    label: isXS ? {
                        show: false
                    } : {
                        show: true,
                        alignTo: 'edge',
                        formatter: function (e) {
                            const data = e.data;
                            return `{name|${data.name}}\n{count|${data.value} (${data.percentage}%)}`;
                        },
                        minMargin: 5,
                        edgeDistance: 10,
                        lineHeight: 15,
                        rich: {
                            count: {
                                fontSize: 10,
                                color: '#666'
                            }
                        }
                    },
                    labelLine: isXS ? {
                        show: false
                    } : {
                        show: true,
                        length: 15,
                        length2: 0,
                        maxSurfaceAngle: 80
                    },
                    labelLayout: isXS ? null : function (params) {
                        if (!echartInstance) {
                            return { labelLinePoints: params.labelLinePoints };
                        }
                        const isLeft = params.labelRect.x < echartInstance.getWidth() / 2;
                        const points = params.labelLinePoints;
                        // Update the end point.
                        points[2][0] = isLeft
                            ? params.labelRect.x
                            : params.labelRect.x + params.labelRect.width;
                        return {
                            labelLinePoints: points
                        };
                    },
                    data: this.state.pieData,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 6,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.3)'
                        }
                    }
                }
            ]
        };

        const { dailySales: salesData } = this.state.data || {};

        const salesOption = {
            grid: {
                top: '20px',
                left: '0',
                right: '0',
                bottom: '0',
                containLabel: true
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: {
                type: 'category',
                data: salesData?.map(s => s.dateString),
                axisTick: {
                    alignWithLabel: true
                }
            },
            yAxis: {
                type: 'value',
                minInterval: 1
            },
            series: [
                {
                    data: salesData?.map(s => s.count),
                    type: 'bar',
                    barWidth: 16,
                    // itemStyle: {
                    //     color: '#66bb6a', //#43a047
                    // }
                }
            ]
        };

        const prepareOption = {
            grid: {
                top: '20px',
                left: '0',
                right: '15px',
                bottom: '0',
                containLabel: true
            },
            tooltip: {
                trigger: 'axis'
            },
            xAxis: {
                type: 'category',
                data: this.state.data?.preparedProducts?.map(s => s.dateString),
                boundaryGap: false
            },
            yAxis: {
                type: 'value',
                minInterval: 1
            },
            series: [
                {
                    data: this.state.data?.preparedProducts?.map(s => s.count),
                    type: 'line',
                    symbol: 'circle',
                    symbolSize: 5,
                    itemStyle: {
                        color: '#ec407a', //#d81b60
                    }
                }
            ]
        };

        const packageOption = {
            grid: {
                top: '20px',
                left: '0',
                right: '15px',
                bottom: '0',
                containLabel: true
            },
            tooltip: {
                trigger: 'axis'
            },
            xAxis: {
                type: 'category',
                data: this.state.data?.packages?.map(s => s.dateString),
                boundaryGap: false
            },
            yAxis: {
                type: 'value',
                minInterval: 1
            },
            series: [
                {
                    data: this.state.data?.packages?.map(s => s.count),
                    type: 'line',
                    symbol: 'circle',
                    symbolSize: 5,
                    itemStyle: {
                        color: '#ffa726', //#fb8c00
                    }
                }
            ]
        };

        const deliverOption = {
            grid: {
                top: '20px',
                left: '0',
                right: '15px',
                bottom: '0',
                containLabel: true
            },
            tooltip: {
                trigger: 'axis'
            },
            xAxis: {
                type: 'category',
                data: this.state.data?.deliveries?.map(s => s.dateString),
                boundaryGap: false
            },
            yAxis: {
                type: 'value',
                minInterval: 1
            },
            series: [
                {
                    data: this.state.data?.deliveries?.map(s => s.count),
                    type: 'line',
                    symbol: 'circle',
                    symbolSize: 5,
                    itemStyle: {
                        color: '#66bb6a', //#43a047
                    }
                }
            ]
        };

        const chartsEvents = {
            legendselectchanged: this.handleSelectLegendChanged
        };

        return (
            <div className="dashboard-page">
                <Card style={{ marginBottom: '24px' }} title="全球销量" loading={this.state.loading}>
                    <Row gutter={[24, 24]}>
                        <Col {...topPieResponsiveProps}>
                            <ReactEcharts style={{ height: '280px' }} ref={(e) => { this.echartRef = e; }} option={option} onEvents={chartsEvents}></ReactEcharts>
                        </Col>
                        <Col {...topPieResponsiveProps}>
                            <table className="country-list">
                                <colgroup>
                                    <col width="30" />
                                    <col width="60" />
                                    <col width="60" />
                                    <col width="40" />
                                </colgroup>
                                <tbody>
                                    {this.state.pieData.map(pdata => {
                                        return <tr className={!pdata.checked ? "country-row-unchecked" : ""}
                                            key={pdata.countryCode}
                                            onMouseEnter={() => this.handleMouseEnter(pdata)}
                                            onMouseLeave={() => this.handleMouseLeave(pdata)}
                                            onClick={() => this.handleRowClick(pdata)}>
                                            <td><IconFont className={classNames("flag", { "flag-unchecked": !pdata.checked })} type={`icon-flag-${pdata.countryCode.toLowerCase()}`} /></td>
                                            <td>{pdata.name}</td>
                                            <td>{pdata.value}</td>
                                            <td>{pdata.percentage}%</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </Col>

                    </Row>
                </Card>
                <Row gutter={24}>
                    <Col {...topColResponsiveProps}>
                        <ChartCard
                            bordered={false}
                            title="等待制作"
                            action={
                                <Tooltip
                                    title="正在制作中的未发货订单数"
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            }
                            loading={this.state.loading}
                            total={
                                `${this.state.data?.statistics?.prepareCount || 0} 单`
                            }
                            footer={
                                <Field
                                    label={
                                        `${this.state.data?.statistics?.prepareCount || 0} 单尚未完成制作`
                                    }
                                    value={''}
                                />
                            }
                            contentHeight={46}
                        >
                        </ChartCard>
                    </Col>
                    <Col {...topColResponsiveProps}>
                        <ChartCard
                            bordered={false}
                            loading={this.state.loading}
                            title="等待创建物流"
                            action={
                                <Tooltip
                                    title="产品制作完成，未创建物流的订单数"
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            }
                            total={
                                `${this.state.data?.statistics?.createLogisticsCount || 0} 单`
                            }
                            footer={
                                <Field
                                    label={
                                        `${this.state.data?.statistics?.createLogisticsCount || 0} 单尚未创建物流订单`
                                    }
                                    value={''}
                                />
                            }
                            contentHeight={46}
                        >
                        </ChartCard>
                    </Col>
                    <Col {...topColResponsiveProps}>
                        <ChartCard
                            bordered={false}
                            loading={this.state.loading}
                            title="等待预报"
                            action={
                                <Tooltip
                                    title="物流建单完成，等待在物流系统中预报"
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            }
                            total={
                                `${this.state.data?.statistics?.declareCount || 0} 单`
                            }
                            footer={
                                <Field
                                    label={
                                        `${this.state.data?.statistics?.declareCount || 0} 单尚未在物流系统中预报`
                                    }
                                    value={''}
                                />
                            }
                            contentHeight={46}
                        >
                        </ChartCard>
                    </Col>
                    <Col {...topColResponsiveProps}>
                        <ChartCard
                            bordered={false}
                            loading={this.state.loading}
                            title="等待打包"
                            action={
                                <Tooltip
                                    title="等待仓库打包交接给物流"
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            }
                            total={
                                `${this.state.data?.statistics?.packageCount || 0} 单`
                            }
                            footer={
                                <Field
                                    label={
                                        `${this.state.data?.statistics?.packageCount || 0} 单等待打包交接给物流`
                                    }
                                    value={''}
                                />
                            }
                            contentHeight={46}
                        >
                        </ChartCard>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col {...topChartResponsiveProps}>
                        <ChartCard
                            bordered={false}
                            loading={this.state.loading}
                            title="每日销量"
                            footer={
                                !!salesData && <Trend flag={salesData?.length <= 1 ? "up" : (salesData[salesData.length - 1].count - salesData[salesData.length - 2].count) > 0 ? "up" : "down"}>
                                    较前一日
                                    <span className='trendText'>{salesData.length === 0 ? '0' : salesData.length === 1 ? '100' : salesData[salesData.length - 2].count === 0 && salesData[salesData.length - 1].count > 0 ? 100 : salesData[salesData.length - 2].count === 0 && salesData[salesData.length - 1].count === 0 ? 0 : Math.abs(((salesData[salesData.length - 1].count / salesData[salesData.length - 2].count) - 1) * 100).toFixed(2)}%</span>
                                </Trend>
                            }
                        >
                            <ReactEcharts style={{ height: '200px' }} option={salesOption}></ReactEcharts>
                        </ChartCard>
                    </Col>
                    <Col {...topChartResponsiveProps}>
                        <ChartCard
                            bordered={false}
                            loading={this.state.loading}
                            title="制作完成统计"
                            footer={
                                <Field
                                    label="最近7天制作产品完成的统计"
                                    value={false}
                                />
                            }
                        >
                            <ReactEcharts style={{ height: '200px' }} option={prepareOption}></ReactEcharts>
                        </ChartCard>
                    </Col>
                    <Col {...topChartResponsiveProps}>
                        <ChartCard
                            bordered={false}
                            loading={this.state.loading}
                            title="打包数量统计"
                            footer={
                                <Field
                                    label="最近7天的打包数量的统计"
                                    value={false}
                                />
                            }
                        >
                            <ReactEcharts style={{ height: '200px' }} option={packageOption}></ReactEcharts>
                        </ChartCard>
                    </Col>
                    <Col {...topChartResponsiveProps}>
                        <ChartCard
                            bordered={false}
                            loading={this.state.loading}
                            title="交付统计"
                            footer={
                                <Field
                                    label="最近7天交付订单的统计"
                                    value={false}
                                />
                            }
                        >
                            <ReactEcharts style={{ height: '200px' }} option={deliverOption}></ReactEcharts>
                        </ChartCard>
                    </Col>
                </Row>
            </div >
        );
    }
}

export default withBreakpoint(DashBoard);