import { notification } from 'antd';

const convertExpressNote = (expressNote) => {
    let newNote = expressNote;
    try {
        const noteObj = JSON.parse(expressNote);
        if (!!noteObj.wayName) {
            newNote = `${noteObj.wayName} (${noteObj.expressTypeString})`;
        }
    } catch (e) { }
    return newNote;
}

const filterWaysByCountry = (allWays, country) => {
    const ways = allWays?.filter(w => w.country === country ||
        w.country === "*" || w.country === "All" || w.country === null);
    return ways;
}

const greaterThanZero = (_, value, label) => {
    if (value > 0) {
        return Promise.resolve();
    }
    return Promise.reject(new Error(`${label}必须大于0`));
}

const isPositiveNumber = (_, value, label) => {
    if (value >= 0) {
        return Promise.resolve();
    }
    return Promise.reject(new Error(`${label}必须≥0`));
}

function isObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
}

function isString(str) {
    return typeof str === 'string';
}

const get_response_error = (response) => {
    var errorArray = [];
    if (response.error) {
        errorArray.push(response.error);
    }
    if (isString(response.errors)) {
        errorArray.push(response.errors);
    }
    if (Array.isArray(response.errors) || isObject(response.errors)) {
        for (let key in response.errors) {
            errorArray.push(key + ": " + response.errors[key]);
        }
    }
    return errorArray;
}

const showShopifyError = (response) => {
    var er = [];
    if (Array.isArray(response)) {
        for (var i = 0; i < response.length; i++) {
            er = er.concat(get_response_error(response[i]));
        }
    }
    if (isObject(response)) {
        er = er.concat(get_response_error(response));
    }
    var errors = er.join('\n');
    notification.error({
        message: "请求错误",
        description: errors,
    });
}

const commonUtil = {
    convertExpressNote,
    filterWaysByCountry,
    greaterThanZero,
    isPositiveNumber,
    showShopifyError
}

export default commonUtil;