import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import PageLoading from '@components/Common/PageLoading/PageLoading';
import commonConfig from '@common/commonConfig';
import request from "@utils/request";
import { Card, Table, Input, Tooltip, Space, Row, Col } from "antd";
import DecreaseSpare from "@components/Spare/DecreaseSpare/DecreaseSpare";
import IncreaseSpare from "@components/Spare/IncreaseSpare/IncreaseSpare";
import CreateSpare from "@components/Spare/CreateSpare/CreateSpare";
import EditSpare from "@components/Spare/EditSpare/EditSpare";
import SpareDetailButton from "@components/Spare/SpareDetailButton/SpareDetailButton";
import './spare-manage.less';

const { Search } = Input;

class SpareManage extends React.Component {
    state = {
        loading: false,
        visible: false,
        pageNumber: 1,
        pageSize: commonConfig.pageSize,
        dataSource: null,
        totalCount: 0
    }
    search = React.createRef();

    columns = [
        {
            title: '备品名称',
            key: 'spareName',
            dataIndex: 'spareName',
            width: 23
        },
        {
            title: '描述',
            key: 'description',
            dataIndex: 'description',
            width: 30
        },
        {
            title: '备品数量',
            key: 'spareCount',
            dataIndex: 'spareCount',
            width: 9
        },
        {
            title: '创建时间',
            key: 'createTimeString',
            dataIndex: 'createTimeString',
            width: 18
        },
        {
            title: '操作',
            key: 'action',
            render: (record) => (
                <Space wrap>
                    <EditSpare record={record} afterUpdate={(newRecord) => {
                        const newSource = [...this.state.dataSource];
                        const idx = newSource.findIndex(x => x.id === record.id);
                        newSource[idx] = {
                            ...record,
                            spareName: newRecord.spareName,
                            description: newRecord.description
                        };
                        this.setState({ dataSource: newSource });
                    }} />
                    <IncreaseSpare record={record} afterUpdate={(newRecord) => {
                        const newSource = [...this.state.dataSource];
                        const idx = newSource.findIndex(x => x.id === record.id);
                        newSource[idx] = {
                            ...record,
                            spareCount: newRecord.spareCount
                        };
                        this.setState({ dataSource: newSource });
                    }} />
                    <DecreaseSpare record={record} afterUpdate={(newRecord) => {
                        const newSource = [...this.state.dataSource];
                        const idx = newSource.findIndex(x => x.id === record.id);
                        newSource[idx] = {
                            ...record,
                            spareCount: newRecord.spareCount,
                        };
                        this.setState({ dataSource: newSource });
                    }} />
                    <SpareDetailButton record={record} />
                </Space>
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord !== nextRecord
                || prevRecord.spareName !== nextRecord.spareName
                || prevRecord.description !== nextRecord.description
                || prevRecord.spareCount !== nextRecord.spareCount,
            width: 20
        },
    ];

    componentDidMount() {
        this.loadData();
    }

    handlePageChange = (page, pageSize) => {
        this.setState({
            pageNumber: page,
            pageSize: pageSize
        }, this.loadData);
    }

    loadData = () => {
        const _this = this;
        PageLoading.show();
        request.get('/Spare/GetSpares', {
            params: {
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize,
                name: this.search.current.input.value,
            }
        }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.setState({
                    dataSource: response.result.data,
                    totalCount: response.result.totalRecord
                });
            }
        });
    }

    onSearch = (value) => {
        this.setState({ pageNumber: 1 }, () => { this.loadData(); });
    }

    handleValuesChange = (values) => {
        this.setState({ pageNumber: 1 }, () => { this.loadData(); });
    }

    handleFilterOk = () => {
        this.filterForm.current.validateFields().then(values => {
            this.handleVisibleChange(false);
            this.setState({
                pageNumber: 1,
                filter: { ...values, useOperatorFilter: true }
            }, () => { this.loadData(); });
        }).catch(errorInfo => { });
    }

    handleFilterRemove = () => {
        this.filterForm.current.resetFields();
        this.initFilter();
        this.handleVisibleChange(false);
        this.setState({ pageNumber: 1 }, () => { this.loadData(); });
    }

    handleFilterCancel = () => {
        this.handleVisibleChange(false);
    }

    handleVisibleChange = visible => {
        this.setState({ visible }, () => {
            if (visible === true) {
                this.filterForm.current.setFieldsValue(this.state.filter);
            }
        });
    }

    render() {
        return (<PageContainer className="spare-manage-page">
            <Card loading={this.state.loading}>
                <Row>
                    <Col xs={24} sm={12} md={16}>
                        <CreateSpare style={{ marginBottom: 12 }} afterUpdate={(newRecord) => this.loadData()} />
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Tooltip title="搜索备品名称、备品描述">
                            <Search ref={this.search} placeholder="搜索..." allowClear onSearch={this.onSearch} style={{ marginBottom: 12 }} />
                        </Tooltip>
                    </Col>
                </Row>
                <Table rowKey='id'
                    columns={this.columns}
                    dataSource={this.state.dataSource}
                    tableLayout='fixed'
                    size="middle"
                    scroll={{ x: 950 }}
                    pagination={{
                        pageSize: this.state.pageSize,
                        current: this.state.pageNumber,
                        total: this.state.totalCount,
                        showQuickJumper: true,
                        size: 'default',
                        onChange: this.handlePageChange
                    }} />
            </Card>
        </PageContainer>);
    }
}

export default SpareManage;