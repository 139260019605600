import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import PageLoading from '@components/Common/PageLoading/PageLoading';
import commonUtil from "@utils/commonUtil";
import IconFont from '@components/Common/IconFont';
import TimerHistory from "@components/Settings/TimerHistory/TimerHistory";
import request from "@utils/request";
import { Card, Table, Form, Button, message, InputNumber, Select, Switch, Typography, Tooltip, Space } from "antd";
import './timer-setting.less';

const { Option } = Select;
const { Text } = Typography;

class TimerSetting extends React.Component {
    state = {
        loading: false,
    }
    form = React.createRef();

    columns = [
        {
            title: 'Timer名称',
            key: 'name',
            dataIndex: 'name',
            width: 22
        },
        {
            title: '周期性',
            key: 'runningType',
            dataIndex: 'runningType',
            render: (runningType, record, idx) => (
                <Form.Item name={['timerSettingInfos', idx, 'runningType']} style={{ marginBottom: 0 }} rules={[{ required: true, message: "请输入周期性" }]}>
                    <Select>
                        <Option value={1}>周期运行</Option>
                        <Option value={2}>每天一次</Option>
                    </Select>
                </Form.Item>
            ),
            width: 13,
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.runningType !== nextRecord.runningType,
        },
        {
            title: '运行频率(m)',
            key: 'interval',
            dataIndex: 'interval',
            render: (interval, record, idx) => (
                <Form.Item name={['timerSettingInfos', idx, 'interval']} style={{ marginBottom: 0 }} rules={[{ required: true, message: "请输入运行频率" }, { validator: (_, value) => commonUtil.greaterThanZero(_, value, "运行频率") }]}>
                    <InputNumber style={{ width: '100%' }} />
                </Form.Item>
            ),
            width: 11,
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.interval !== nextRecord.interval,
        },
        {
            title: '下次执行时间(GMT +08:00)',
            key: 'nextRunTime',
            dataIndex: 'nextRunTime',
            render: (nextRunTime, record) => (
                <Text type={nextRunTime === "Running" ? "danger" : ""} >{nextRunTime}</Text>
            ),
            width: 22,
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.nextRunTime !== nextRecord.nextRunTime,
        },
        {
            title: '状态',
            key: 'enable',
            dataIndex: 'enable',
            render: (enable, record, idx) => (
                <Form.Item valuePropName="checked" name={['timerSettingInfos', idx, 'enable']} style={{ marginBottom: 0 }}>
                    <Switch />
                </Form.Item>),
            width: 12,
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.enable !== nextRecord.enable,
        },
        {
            title: '操作',
            key: 'action',
            render: (record) => (
                <Space wrap>
                    <TimerHistory title={record.name} record={record} />
                    <Tooltip title="立刻运行">
                        <Button type="primary" onClick={() => this.handleRunNow(record)} ghost shape="circle" icon={<IconFont type="icon-manual" />}></Button>
                    </Tooltip>
                </Space>
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord !== nextRecord
                || prevRecord.nextRunTime !== nextRecord.nextRunTime
                || prevRecord.runningType !== nextRecord.runningType
                || prevRecord.interval !== nextRecord.interval
                || prevRecord.enable !== nextRecord.enable,
            width: 20
        },
    ];

    componentDidMount() {
        this.loadSetting();
    }

    loadSetting = () => {
        const _this = this;
        PageLoading.show();
        request.get('/Setting/GetTimerSettings').then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.setting = response.result;
                _this.setState({
                    dataSource: response.result.customConfig.timerSettingInfos
                }, () => {
                    _this.form.current.setFieldsValue({
                        timerSettingInfos: response.result.customConfig.timerSettingInfos
                    })
                });
            }
        });
    }

    handleSave = () => {
        this.form.current.validateFields().then(values => {
            PageLoading.show();
            const _this = this;
            const newInfos = _this.setting.customConfig.timerSettingInfos.map((st, idx) => {
                return { ...st, ...values.timerSettingInfos[idx] };
            });
            _this.setting.customConfig.timerSettingInfos = newInfos;
            request.post('/Setting/SaveTimerSettings', {
                data: _this.setting
            }).then(function (response) {
                PageLoading.hide();
                if (response && response.success) {
                    message.success("更新Timer设置成功！");
                    _this.loadSetting();
                }
                if (response && response.success === false) {
                    message.error("系统异常，更新Timer设置失败！");
                }
            });
        }).catch(errorInfo => { });
    }

    handleRunNow = (record) => {
        PageLoading.show();
        request.post('/Setting/RunJobNow', {
            data: record
        }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                message.success("启动成功！");
            }
            if (response && response.success === false) {
                message.error(response.errorMsg);
            }
        });
    }

    render() {
        return (<PageContainer className="timer-setting-page">
            <Card loading={this.state.loading}>
                <Form ref={this.form}>
                    <Table rowKey='timerType'
                        columns={this.columns}
                        dataSource={this.state.dataSource}
                        tableLayout='fixed'
                        size="middle"
                        scroll={{ x: 950 }}
                        pagination={false} />
                </Form>
                <Button type="primary" onClick={this.handleSave} style={{ marginTop: 24, float: 'right' }}>保存</Button>
            </Card>
        </PageContainer >);
    }
}

export default TimerSetting;