import React from "react";
import { Modal, Spin, Button, Card, Typography, Row, Col, Form, Input, Select, Alert, InputNumber, Checkbox, Space, Tag, message, Tooltip } from 'antd';
import request from "@utils/request";
import commonUtil from "@utils/commonUtil";
import { LoadingOutlined, CheckOutlined, WarningOutlined, ExclamationOutlined, SyncOutlined } from '@ant-design/icons';
import './CreateLogistics.less';

const { Option } = Select;
const { Text, Paragraph, Link } = Typography;

class CreateLogistics extends React.Component {
    constructor(props) {
        super(props);
        const { visible = false } = props;
        this.state = {
            loading: false,
            btnLoading: false,
            hasError: false,
            visible: visible,
            validateState: 0, //0: hide, 1:loading, 2: verified, 3: partial verified, 4: not verified
            tipAddress: null
        }
        this.form = React.createRef();
        this.allWays = {};
    }

    componentDidMount() {
        const _this = this;
        _this.setState({ loading: true });
        request.get('/Setting/GetLogisticsSettingConfig').then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                _this.logisticsSetting = response.result?.customConfig?.logisticsSettingInfos;
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.visible !== this.props.visible) {
            this.setState({ visible: this.props.visible, ways: null, errorMsg: null }, () => {
                if (this.props.visible === true) {
                    if (this.controller) {
                        this.controller.abort();
                    }
                    if (this.validateController) {
                        this.validateController.abort();
                    }
                    const { record } = this.props;
                    let tag = null;
                    if (this.logisticsSetting?.length > 0) {
                        const settingWays = this.logisticsSetting.find(s => s.countryCode === record.receiverCountry)?.wayInfos;
                        this.setState({ logiWays: settingWays });
                    }
                    this.form.current.setFieldsValue({
                        ...record,
                        expressType: null,
                        transportWayObj: null,
                        email: '',
                        iossNumber: ''
                    });
                    this.validateAddress();
                    let note = record?.expressNote;
                    if (note) {
                        try {
                            tag = JSON.parse(record.expressNote);
                            if (tag.expressType) {
                                this.handleTagClick(tag);
                            }
                        } catch (e) { }
                    }
                }
            })
        }
    }

    validateAddress = () => {
        const order = this.form.current.getFieldsValue();
        const _this = this;
        const validateController = new AbortController();
        const { signal } = validateController;
        this.validateController = validateController;
        if (order.receiverCountry !== "US") {
            this.setState({ validateState: 0 });
            return;
        }
        this.setState({ validateState: 1 });
        request.post('/Order/ValidateAddress', {
            signal,
            data: order
        }).then(function (response) {
            _this.setState({ loading: false, btnLoading: false, });
            if (response && response.success) {
                const verifiedAddress = response.result;
                const { address, verified } = verifiedAddress;
                let tipAddress = null;
                let validateState = 4;
                if (verified) {
                    let sameState = _this.isSameAddress(address.state, order.state);
                    let sameCity = _this.isSameAddress(address.city, order.city);
                    let sameFirst = _this.isSameAddress(address.firstLine, order.firstLine);
                    let sameSecond = _this.isSameAddress(address.secondLine, order.secondLine);
                    let sameZip = _this.isSameAddress(address.zip, order.zip);
                    let state = <Text key="state" type={sameState ? "success" : "danger"}>{address.state}</Text>;
                    let city = <Text key="city" type={sameCity ? "success" : "danger"}>{address.city}</Text>;
                    let firstLine = <Text key="firstLine" type={sameFirst ? "success" : "danger"}>{address.firstLine}</Text>;
                    let secondLine = <Text key="secondLine" type={sameSecond ? "success" : "danger"}>{address.secondLine}</Text>;
                    let zip = <Text key="zip" type={sameZip ? "success" : "danger"}>{address.zip}</Text>;
                    tipAddress = <Paragraph style={{ marginBottom: 0 }}>{secondLine} {firstLine}, {city}, {state} {zip}, <Text key="country" type="success">{order.receiverCountry}</Text></Paragraph>;
                    validateState = sameState && sameCity && sameFirst && sameSecond && sameZip ? 2 : 3;
                }
                _this.setState({ validateState: validateState, tipAddress: tipAddress });
            }
            else {
                _this.setState({ validateState: 4, tipAddress: null });
            }
        })
    }

    isSameAddress(uspsAddress, orderAddress) {
        let uspsTrim = uspsAddress?.trim();
        let orderTrim = orderAddress?.trim();
        if (!uspsTrim && !orderTrim) {
            return true;
        } else {
            return uspsTrim?.toLowerCase() === orderTrim?.toLowerCase();
        }

    }

    handleTagClick = tag => {
        let expressType = null;
        let transportWayObj = null;
        const promise = new Promise((resolve, reject) => {
            if (tag) {
                expressType = tag.expressType;
                this.handleValuesChange({ expressType }).then(() => {
                    resolve();
                });
            } else {
                resolve();
            }
        });
        promise.then(() => {
            if (tag) {
                const way = this.state.ways?.find(w => w.code === tag.wayCode);
                transportWayObj = way ? { value: way.code, label: way.displayName } : null;
            }
            this.form.current.setFieldsValue({
                expressType: expressType,
                transportWayObj: transportWayObj
            });
        })
    }

    handleCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    handleOk = () => {
        this.form.current.validateFields().then(values => {
            const _this = this;
            const { record } = this.props;
            const newRecord = { ...record, ...values, declareItems: [], transportWay: values.transportWayObj.label, transportWayCode: values.transportWayObj.value };
            values.declareItems.forEach((item, idx) => {
                const mergedItem = { ...record.declareItems[idx], ...item };
                newRecord.declareItems.push(mergedItem);
            })
            const controller = new AbortController();
            const { signal } = controller;
            this.controller = controller;
            this.setState({
                loading: true,
                btnLoading: true,
                errorMsg: null,
            });
            request.post('/Order/CreateExpress', {
                signal,
                data: newRecord
            }).then(function (response) {
                _this.setState({ loading: false, btnLoading: false, });
                if (response && response.success) {
                    if (_this.props.onCancel) {
                        _this.props.onCancel();
                    }
                    message.success(`${response.result.customerOrderNumber}创建物流订单成功！单号为：${response.result.wayBillNumber}`);
                    if (_this.props.afterUpdate) {
                        _this.props.afterUpdate(newRecord);
                    }
                }
                if (response && response.success === false) {
                    _this.setState({ errorMsg: `创建物流订单失败： ${response.errorMsg}` });
                }
            })
        }).catch(errorInfo => { });
    }

    handleValuesChange = (values) => {
        if (values.expressType !== undefined) {
            const _this = this;
            const promise = new Promise((resolve, reject) => {
                this.setState({ ways: null });
                this.form.current.setFieldsValue({
                    transportWayObj: null
                });
                if (!!this.allWays[values.expressType]) {
                    resolve();
                } else {
                    _this.setState({ loading: true });
                    request.get('/Order/GetTransportWays', {
                        params: {
                            expressType: values.expressType
                        }
                    }).then(function (response) {
                        _this.setState({ loading: false });
                        if (response && response.success) {
                            _this.allWays[values.expressType] = response.results;
                        }
                        resolve();
                    })
                }
            });
            promise.then(() => {
                _this.setTransportWays(_this.allWays[values.expressType]);
            });
            return promise;
        }
    }

    setTransportWays = (expressWays) => {
        const { record } = this.props;
        const ways = commonUtil.filterWaysByCountry(expressWays, record.receiverCountry);
        this.setState({ ways: ways });
    }

    render() {
        const { record = {}, venders } = this.props;
        const note = commonUtil.convertExpressNote(record?.expressNote);
        return (<Modal
            title="创建物流单"
            wrapClassName="create-logistics-modal"
            // okButtonProps={{ loading: this.state.btnLoading }}
            maskClosable={false}
            visible={this.state.visible}
            // onOk={this.handleOk}
            width={800}
            footer={[
                this.state.validateState !== 1 && this.state.validateState !== 0 && <Button key="btn" type="link" onClick={this.validateAddress} icon={<SyncOutlined />}></Button>,
                this.state.validateState === 1 && <Spin key="loading" style={{ marginRight: 12 }} indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />,
                this.state.validateState === 2 && <Tooltip key="success" color="white" trigger="click" title={this.state.tipAddress}><Text style={{ marginRight: 12 }} type="success"><CheckOutlined /> USPS Verified</Text></Tooltip>,
                this.state.validateState === 3 && <Tooltip key="warning" color="white" trigger="click" title={this.state.tipAddress}><Text style={{ marginRight: 12 }} type="warning"><WarningOutlined /> USPS Partial Verified</Text></Tooltip>,
                this.state.validateState === 4 && <Text key="danger" style={{ marginRight: 12 }} type="danger"><ExclamationOutlined /> USPS Not Verified</Text>,
                <Button key="cancel" type="default" onClick={this.handleCancel} >取消</Button>,
                <Button key="ok" type="primary" onClick={this.handleOk} loading={this.state.btnLoading} >确定</Button>
            ]}
            onCancel={this.handleCancel}
        >
            <Spin spinning={this.state.loading}>
                {!!this.state.errorMsg && <Alert message={this.state.errorMsg} style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                <Form ref={this.form} labelCol={{ span: 4 }} onValuesChange={(values) => this.handleValuesChange(values)} wrapperCol={{ span: 20 }} labelAlign="left">
                    <Card size="small" title="基本信息">
                        <Form.Item name="orderNumber" label="客户单号" rules={[{ required: true }]}>
                            <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item label="物流标注"
                            extra={
                                this.state.logiWays?.length > 0 && <Space wrap style={{ marginTop: '8px' }}>
                                    {this.state.logiWays?.map(w => <Tag className="logistics-tag" key={w.wayCode} onClick={() => this.handleTagClick(w)} color="processing">{w.wayName} ({w.expressTypeString})</Tag>)}
                                </Space>
                            }>
                            <div>{note}</div>
                        </Form.Item>
                        <Form.Item name="expressType" label="物流商" rules={[{ required: true }]}>
                            <Select>
                                {venders?.map(v => <Option key={v.expressType} value={v.expressType}>{v.displayName}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item name="transportWayObj" label="运输方式" rules={[{ required: true }]}>
                            <Select showSearch labelInValue filterOption={(input, option) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}>
                                {this.state.ways?.map(w => <Option key={w.code} value={w.code}>{w.displayName}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item name="weight" label="预计重量(kg)" rules={[{ required: true }, { validator: (_, value) => commonUtil.greaterThanZero(_, value, "预计重量(kg)") }]}>
                            <InputNumber step={0.1} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item name="packageCount" label="件数" rules={[{ required: true }, { validator: (_, value) => commonUtil.greaterThanZero(_, value, "件数") }]}>
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item name="iossNumber" extra={record.isInEuropeanUnion ? record.iossNumber : ''} label="IOSS号码" tooltip="欧盟国家必填，欧盟成员国包括奥地利，比利时，保加利亚，塞浦路斯，克罗地亚，捷克，丹麦，爱沙尼亚，芬兰，法国，德国，希腊，匈牙利，爱尔兰，意大利，拉脱维亚，立陶宛，卢森堡，马耳他，荷兰，波兰，葡萄牙，罗马尼亚，斯洛伐克，斯洛文尼亚，西班牙，瑞典">
                            <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item name="taxNumber" label="增值税号" tooltip="巴西国家必填 CPF 或 CNPJ，CPF 码格式为 000.000.000-00，CNPJ码格式为 00.000.000/0000-00，其它国家非必填，英国税号格式为：前缀GB+9位纯数字 或者前缀GB+12位纯数字">
                            <Input autoComplete="off" />
                        </Form.Item>
                    </Card>
                    <Card size="small" title="收件人信息" style={{ marginTop: '12px' }}>
                        <Form.Item name="receiverName" label="收件人" rules={[{ required: true }]}>
                            <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item name="receiverCountry" label="收件人国家" rules={[{ required: true }]}>
                            <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item name="secondLine" label="门牌号/建筑物">
                            <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item name="firstLine" label="街道名" rules={[{ required: true }]}>
                            <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item name="city" label="城市" rules={[{ required: true }]}>
                            <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item name="state" label="省/州" rules={[{ required: true }]}>
                            <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item name="zip" label="收货邮编" rules={[{ required: true }]}>
                            <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item name="phoneNumber" label="收件人手机" >
                            <Input autoComplete="off" type="tel" />
                        </Form.Item>
                        <Form.Item name="email" label="邮箱" extra={record?.email} rules={[{ type: "email" }]}>
                            <Input autoComplete="off" type="email" />
                        </Form.Item>
                        <Form.Item name="company" label="公司名称">
                            <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item name="invoiceValue" label="发票金额" rules={[{ required: true }, { validator: (_, value) => commonUtil.greaterThanZero(_, value, "发票金额") }]}>
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item name="invoiceCurrency" label="发票货币" rules={[{ required: true }]}>
                            <Input autoComplete="off" />
                        </Form.Item>
                    </Card>
                    <Row style={{ marginTop: '12px' }} gutter={[12, 12]}>
                        {record?.declareItems?.map((declareItem, idx) => <Col key={idx} xs={24} sm={12}>
                            <Card size="small" title="海关申报信息">
                                <Form.Item labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} name={['declareItems', idx, 'sku']} label="SKU">
                                    <Input autoComplete="off" />
                                </Form.Item>
                                <Form.Item labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} name={['declareItems', idx, 'enName']} label="英文品名" rules={[{ required: true }]}>
                                    <Input autoComplete="off" />
                                </Form.Item>
                                <Form.Item labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} name={['declareItems', idx, 'cnName']} label="中文品名" rules={[{ required: true }]}>
                                    <Input autoComplete="off" />
                                </Form.Item>
                                <Form.Item labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} name={['declareItems', idx, 'netWeight']} label="净重(kg)" rules={[{ required: true }, { validator: (_, value) => commonUtil.greaterThanZero(_, value, "净重(kg)") }]}>
                                    <InputNumber step={0.1} style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} name={['declareItems', idx, 'quantity']} label="申报数量" rules={[{ required: true }, { validator: (_, value) => commonUtil.greaterThanZero(_, value, "申报数量") }]}>
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} name={['declareItems', idx, 'unitPrice']} label="申报单价" rules={[{ required: true }, { validator: (_, value) => commonUtil.greaterThanZero(_, value, "申报单价") }]}>
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} name={['declareItems', idx, 'currencyCode']} label="申报币种" rules={[{ required: true }]}>
                                    <Input autoComplete="off" />
                                </Form.Item>
                                <Form.Item labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} name={['declareItems', idx, 'hscode']} label="海关编码" tooltip={<div>查询链接：<Link target="_blank" href="https://www.censtatd.gov.hk/tc/index_hs_code.html">海关编码查询</Link></div>}>
                                    <Input autoComplete="off" />
                                </Form.Item>
                                <Form.Item labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} name={['declareItems', idx, 'includeBattery']} valuePropName="checked" label="是否带电">
                                    <Checkbox>是</Checkbox>
                                </Form.Item>
                            </Card>
                        </Col>)}
                    </Row>
                </Form>
            </Spin>
        </Modal >);
    }
}

export default CreateLogistics;