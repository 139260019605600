import React from "react";
import request from "@utils/request";
import { Modal, Spin, Alert, Button, Form, Input } from 'antd';
import IconFont from '@components/Common/IconFont';
import './UpdateAddress.less';

class UpdateAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            hasError: false
        }
    }

    form = React.createRef();

    handleOk = () => {
        const { record } = this.props;
        this.form.current.validateFields().then(values => {
            const controller = new AbortController();
            const { signal } = controller;
            this.controller = controller;
            this.setState({ loading: true, hasError: false });
            const _this = this;
            const newRecord = {
                ...record,
                receiverName: values.receiverName,
                receiverCountry: values.receiverCountry,
                secondLine: values.secondLine,
                firstLine: values.firstLine,
                city: values.city,
                state: values.state,
                zip: values.zip,
                phoneNumber: values.phoneNumber,
                email: values.email,
                company: values.company,
                shippingMethod: values.shippingMethod
            };
            request.post('/Order/UpdateAddress', {
                signal,
                data: newRecord
            }).then(function (response) {
                _this.setState({ loading: false });
                if (response && response.success) {
                    _this.setState({ visible: false });
                    newRecord.reachable = response.result;
                    if (_this.props.afterUpdate) {
                        _this.props.afterUpdate(newRecord);
                    }
                }
                if (response && response.success === false) {
                    _this.setState({ hasError: true });
                }
            });
        }).catch(errorInfo => { });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleOpen = () => {
        if (this.controller) {
            this.controller.abort();
        }
        this.setState({ visible: true, hasError: false }, () => {
            this.form.current.resetFields();
        });
    }

    render() {
        const { record } = this.props;
        return (
            <>
                <Button shape="round" type="primary" disabled={record.orderStatus > 2} onClick={this.handleOpen} icon={<IconFont type="icon-address" />}>
                    修改地址
                </Button>
                <Modal title="修改地址"
                    okButtonProps={{ loading: this.state.loading }}
                    maskClosable={false}
                    width={800}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Spin spinning={this.state.loading}>
                        {this.state.hasError && <Alert message="修改地址失败！" style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                        <Form ref={this.form} labelAlign="left" labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} initialValues={{
                            receiverName: record.receiverName,
                            receiverCountry: record.receiverCountry,
                            secondLine: record.secondLine,
                            firstLine: record.firstLine,
                            city: record.city,
                            state: record.state,
                            zip: record.zip,
                            phoneNumber: record.phoneNumber,
                            email: record.email,
                            company: record.company,
                            shippingMethod: record.shippingMethod
                        }}>
                            <Form.Item label="收件人" name="receiverName" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="收件人国家" name="receiverCountry" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="门牌号/建筑物" name="secondLine">
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="街道名" name="firstLine" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="城市" name="city" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="省/州" name="state" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="收货邮编" name="zip" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="收件人手机" name="phoneNumber">
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="邮箱" name="email" rules={[{ type: "email" }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="公司名称" name="company">
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="运输方式" name="shippingMethod">
                                <Input autoComplete="off" />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        );
    }
};

export default UpdateAddress;