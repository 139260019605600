import React from 'react';
import request from "@utils/request";
import commonConfig from '@common/commonConfig';
import { Card, Typography, message } from "antd";
import DateTrackSkeleton from '@components/Common/DateTrackSkeleton/DateTrackSkeleton';
import './SpareDetailList.less';

const { Text } = Typography;

class SpareDetailList extends React.Component {
    state = {
        loading: false,
        pageNumber: 1,
        pageSize: commonConfig.pageSize,
        spare_records: [],
        showMore: true
    }

    form = React.createRef();

    componentDidMount() {
        const { spare } = this.props;
        if (spare) {
            this.loadData();
        }
    }

    componentDidUpdate(preProps) {
        if (preProps.spare !== this.props.spare) {
            this.setState({
                pageNumber: 1,
                showMore: true,
                spare_records: []
            }, () => this.loadData());
        }
    }

    loadData = () => {
        const _this = this;
        const { recordType = 0, spare } = this.props;
        const { spare_records } = this.state;
        const lastMonthYear = spare_records?.length > 0 ? spare_records[spare_records.length - 1].createMonthYear : "";
        if (!spare) {
            return;
        }
        this.setState({ loading: true });
        request.get('/Spare/GetSpareDetails', {
            params: {
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize,
                recordType: recordType,
                spareId: spare,
                lastMonthYear: lastMonthYear
            }
        }).then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                const newSource = [...spare_records].concat(response.result.data)
                const showMore = newSource.length < response.result.totalRecord;
                _this.setState({
                    spare_records: newSource,
                    showMore: showMore
                });
            }
            if (response && response.success === false) {
                message.error("加载库存详情失败！");
            }
        });
    }

    handleLoadMore = () => {
        this.setState((state, props) => ({
            pageNumber: state.pageNumber + 1
        }), () => this.loadData());
    }

    render() {
        const { title = "" } = this.props
        const { spare_records, showMore } = this.state;
        return (<Card className="date-track-action-card" size="small" title={title}>
            <table className="date-track-action-table">
                <colgroup>
                    <col width="80px" />
                    <col width="100%" />
                    <col width="80px" />
                </colgroup>
                <tbody>
                    {spare_records?.map((record, idx) => <React.Fragment key={idx}>
                        {record.showMonthYear && <tr className="date-track-monthrow">
                            <td colSpan="3">
                                <div className="date-track-month">{record.createMonthYear}</div>
                            </td>
                        </tr>
                        }
                        <tr>
                            <td>
                                <div className="date-track-datecontainer">
                                    <div className="date-track-date text-center">{record.createDate}</div>
                                    <div className="date-track-week text-center">{record.createWeek}</div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span>{record.comment}{record.relatedOrder && <span>，相关订单：{record.relatedOrder}</span>}</span>
                                </div>
                                {
                                    record.expressName && record.expressNumber && <div>
                                        <Text>{record.expressName}：{record.expressNumber}</Text>
                                        <Text style={{ marginLeft: 8 }} type={record.deliverStatus === 1 ? "success" : "warning"}>{record.deliverStatusString}</Text>
                                    </div>
                                }
                                <div>
                                    <Text>余量 {record.balance}</Text>
                                </div>
                            </td>
                            <td>
                                <Text type={record.changeType === 0 ? "success" : "danger"} className="date-track-count text-right">{record.changeType === 0 ? '+' : '-'}{record.count}</Text>
                            </td>
                        </tr>
                    </React.Fragment>)}
                </tbody>
            </table>
            <DateTrackSkeleton loading={this.state.loading} showMore={showMore} onMoreClick={this.handleLoadMore} />
        </Card>);
    }
}

export default SpareDetailList;