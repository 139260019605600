import React from 'react';
import { Modal, Spin } from 'antd';
import ReactDOM from 'react-dom';
import './PageLoading.less';

const PageLoading = (props) => {
    const { show = false } = props;
    return (
        <Modal className="pageLoading-modal" visible={show} width={280} closable={false} maskClosable={false}
            zIndex={1009} footer={null} bodyStyle={{ textAlign: 'center' }} centered={true}
            transitionName="" maskTransitionName="">
            <Spin style={{ margin: '10px 0' }} size="large" tip="正在处理中，请稍后..."></Spin>
        </Modal>
    );
}

class PageLoadingContainer extends React.Component {
    state = {
        visible: false,
    }

    open = () => {
        this.setState({ visible: true });
    }

    close = () => {
        this.setState({ visible: false });
    }

    render() {
        return (
            <PageLoading show={this.state.visible} />
        )
    }
}

const loadingModal = React.createElement(PageLoadingContainer, { ref: React.createRef() });
var div = document.createElement('div');
document.body.appendChild(div);
ReactDOM.render(loadingModal, div);

PageLoading.show = () => {
    if (loadingModal?.ref?.current) {
        loadingModal.ref.current.open();
    }
}

PageLoading.hide = () => {
    if (loadingModal?.ref?.current) {
        loadingModal.ref.current.close();
    }
}

export default PageLoading;