import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import { Card, Radio, Select, Form, message } from "antd";
import ReactEcharts from 'echarts-for-react';
import PageLoading from '@components/Common/PageLoading/PageLoading';
import withBreakpoint from '@components/Common/withBreakpoint';
import request from "@utils/request";
import './statistics-sales.less';

const { Option } = Select;

class StatisticsSales extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            value: null,
            chartDataType: 1,
            visible: false
        }
        this.form = React.createRef();
    }

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/Statistics/GetDateSelectInfo').then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                _this.setState({
                    years: response.result.years,
                    months: response.result.months,
                }, () => {
                    const currentDate = new Date();
                    let currentYear = currentDate.getFullYear();
                    let currentMonth = currentDate.getMonth() + 1;
                    if (!response.result.years.some(y => y.value === currentYear)) {
                        currentYear = response.result.years[0].value;
                    }
                    if (!response.result.months.some(m => m.value === currentMonth)) {
                        currentMonth = response.result.months[0].value;
                    }
                    _this.form.current.setFieldsValue({
                        year: currentYear,
                        month: currentMonth
                    });
                    _this.loadCharts();
                });
            }
        });
    }

    loadCharts = () => {
        const _this = this;
        PageLoading.show();
        const values = this.form.current.getFieldsValue();
        request.get('/Statistics/GetSalesChartsData', {
            params: {
                chartDataType: this.state.chartDataType,
                ...values
            }
        }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.setState({ chartData: response.result });
            }
            if (response && response.success === false) {
                message.error("加载销量统计数据失败！");
            }
        });
    }

    handleChange = value => {
        this.loadCharts();
    }

    handleRadioChange = e => {
        this.setState({ chartDataType: e.target.value }, () => this.loadCharts());
    }

    render() {
        const { months, years, chartDataType, chartData = {} } = this.state;
        const option = {
            title: {
                text: '销量统计'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            legend: {
                data: chartData.legend
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    data: chartData.xAxis
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLine: {
                        show: true,
                    },
                    minInterval: 1
                }
            ],
            series: chartData.series?.map(s => {
                return {
                    ...s,
                    type: 'line',
                    stack: '销售总量',
                    areaStyle: {},
                    emphasis: {
                        focus: 'series'
                    }
                }
            })
        };

        return (<PageContainer className="statistics-sales-page">
            <Card loading={this.state.loading}>
                <div className="clearfix">
                    <Radio.Group defaultValue={1} onChange={this.handleRadioChange} name="chartDataType" className="timer-filter">
                        <Radio.Button value={3}>年</Radio.Button>
                        <Radio.Button value={2}>月</Radio.Button>
                        <Radio.Button value={1}>日</Radio.Button>
                    </Radio.Group>
                    <Form ref={this.form} onValuesChange={this.handleChange} layout="inline" style={{ float: this.props.breakpoint.sm === true ? 'right' : 'none', marginBottom: 12 }}>
                        {
                            (chartDataType === 1 || chartDataType === 2) && <Form.Item style={{ width: 100 }} name="year">
                                <Select>
                                    {years?.map(y => <Option key={y.value} value={y.value}>{y.displayText}</Option>)}
                                </Select>
                            </Form.Item>}
                        {
                            chartDataType === 1 && <Form.Item style={{ width: 100 }} name="month">
                                <Select>
                                    {months?.map((m) => <Option key={m.value} value={m.value}>{m.displayText}</Option>)}
                                </Select>
                            </Form.Item>
                        }
                    </Form>
                </div>
                <ReactEcharts style={{ height: 400, marginTop: 12 }} option={option} />
            </Card>
        </PageContainer>);
    }
}

export default withBreakpoint(StatisticsSales);