import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import commonUtil from "@utils/commonUtil";
import request from "@utils/request";
import withBreakpoint from '@components/Common/withBreakpoint';
import TableContainer from "@components/Common/TableContainer/TableContainer";
import { Card, Form, Row, Col, InputNumber } from "antd";
import './system-tool.less';

class SystemTool extends React.Component {
    state = {
        loading: false,
        cost: 0,
        freight: 0,
        profit: 0,
        total: 0,
        totalDollar: 0,
        profitPercent: 30,
        showResult: false
    }

    calculateForm = React.createRef();
    currency = 0.152;

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/Tool/ConvertCurrency').then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                try {
                    let currencyResult = JSON.parse(response.result);
                    if (currencyResult.status === 'success') {
                        _this.currency = (parseFloat(currencyResult?.rates?.USD?.rate) + 0.01).toFixed(4);
                    } else {
                        console.log("系统异常，获取汇率信息失败！");
                    }
                }
                catch {
                    console.log(`转换汇率失败！${response.result}`);
                }
            }
            if (response && response.success === false) {
                console.log("系统异常，获取汇率信息失败！");
            }
        });
    }

    volumeNumber = (_, value, label, max) => {
        if (value === null || value === undefined || value === '' || (value > 0 && value <= max)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(`0cm≤${label}≤${max}cm`));
    }

    weightLimit = (_, value, label) => {
        if (value === null || value === undefined || value === '' || (value > 0 && value <= 2)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(`0kg≤${label}≤2kg`));
    }

    profitLimit = (_, value, label) => {
        if (value > 0 && value < 100) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(`0%<${label}<100%`));
    }

    handleCalculate = () => {
        this.setState({ showResult: false });
        this.calculateForm.current.validateFields().then(values => {
            let { cost = 0, weight = 0, length = 0, width = 0, height = 0, profit } = values;
            cost = cost || 0;
            weight = weight || 0;
            length = length || 0;
            width = width || 0;
            height = height || 0;
            const freight = this.calculateFreight(weight, length, width, height)
            const total = ((cost + freight) / (1 - profit / 100)).toFixed(2);
            this.setState({
                cost: cost,
                freight: freight,
                profit: ((cost + freight) / (1 - profit / 100) * (profit / 100)).toFixed(2),
                total: total,
                totalDollar: (total * this.currency).toFixed(2),
                showResult: true
            });
        }).catch(errorInfo => { });
    }

    handleWeightChange = (value) => {
        this.setState({
            lbWeight: (value * 2.2046226).toFixed(2),
            ozWeight: (value * 35.2739619).toFixed(2)
        });
        this.handleCalculate();
    }

    handleVolumeChange = (value) => {
        this.handleCalculate();
        //cm -> in
        return (value * 0.3937008).toFixed(2);
    }

    calculateFreight = (weight, length, width, height) => {
        if (!weight) {
            return 0;
        }
        //长宽高输入不全按照重量计费
        if (!!length && !!width && !!height) {
            //若包裹（长+宽+高）*2 ≤75cm，按照实际重量计费;
            //若包裹（长+宽+高）*2 >75cm;
            if (length + width + height > 75 / 2) {
                //（体积重量计算方式为：长*高*宽cm/6000=KG);
                let volumeWeight = length * width * height / 6000;
                //包裹体积重量≤1.5倍实际重量，按照实际重量计费
                //包裹体积重量>1.5倍实际重量，按照体积重量计费；
                if (volumeWeight > 1.5 * weight) {
                    weight = volumeWeight;
                }
            }
        }
        const gWeight = 1000 * weight;
        let weightFee = 135;
        let actionFee = 61;
        if (gWeight > 0 && gWeight <= 100) {
            weightFee = 113;
            actionFee = 25;
        }
        if (gWeight > 100 && gWeight <= 200) {
            weightFee = 118;
            actionFee = 25;
        }
        if (gWeight > 200 && gWeight <= 340) {
            weightFee = 135;
            actionFee = 29;
        }
        if (gWeight > 340 && gWeight <= 450) {
            weightFee = 135;
            actionFee = 29;
        }
        if (gWeight > 450 && gWeight <= 2000) {
            weightFee = 135;
            actionFee = 61;
        }
        let totalFee = weightFee * weight + actionFee;
        return totalFee;
    }

    render() {
        return (<PageContainer className="system-tool-page">
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12}>
                    <Card title="售价计算" loading={this.state.loading}>
                        <Form ref={this.calculateForm} initialValues={{
                            profit: this.state.profitPercent
                        }} labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
                            <Form.Item label={<span className="required">重量</span>} style={{ marginBottom: 0 }}>
                                <Row gutter={10} wrap={this.props.breakpoint.sm === false ? true : false}>
                                    <Col span={this.props.breakpoint.sm === false ? 24 : null} flex={this.props.breakpoint.sm === false ? null : "auto"} >
                                        <Form.Item style={{ display: 'inline-block', width: '100%' }} name="weight" rules={[{ required: true, message: "请输入重量" }, { validator: (_, value) => this.weightLimit(_, value, "重量") }]}>
                                            <InputNumber addonAfter="kg" onChange={this.handleWeightChange} style={{ width: '100%' }} placeholder="重量" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={this.props.breakpoint.sm === false ? 12 : null} flex={this.props.breakpoint.sm === false ? null : "none"} >
                                        <Form.Item style={{ display: 'inline-block', marginBottom: 0 }}>
                                            <InputNumber addonAfter="lb" value={this.state.lbWeight} style={{ width: this.props.breakpoint.sm === false ? '100%' : '90px' }} readOnly />
                                        </Form.Item>
                                    </Col>
                                    <Col span={this.props.breakpoint.sm === false ? 12 : null} flex={this.props.breakpoint.sm === false ? null : "none"} >
                                        <Form.Item style={{ display: 'inline-block', marginBottom: 0 }}>
                                            <InputNumber addonAfter="oz" value={this.state.ozWeight} style={{ width: this.props.breakpoint.sm === false ? '100%' : '100px' }} readOnly />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item label="长度" style={{ marginBottom: 0 }}>
                                <div>
                                    <Form.Item name="length" style={{ display: 'inline-block', width: 'calc(100% - 110px)' }} rules={[{ validator: (_, value) => this.volumeNumber(_, value, "长", 60) }]}>
                                        <InputNumber addonAfter="cm" onChange={(value) => this.setState({ inLength: this.handleVolumeChange(value) })} style={{ width: '100%' }} placeholder="长度" />
                                    </Form.Item>
                                    <Form.Item style={{ display: 'inline-block' }}>
                                        <InputNumber addonAfter="in" value={this.state.inLength} style={{ width: '100px', marginLeft: 10 }} readOnly />
                                    </Form.Item>
                                </div>
                            </Form.Item>
                            <Form.Item label="宽度" style={{ marginBottom: 0 }}>
                                <div>
                                    <Form.Item name="width" style={{ display: 'inline-block', width: 'calc(100% - 110px)' }} rules={[{ validator: (_, value) => this.volumeNumber(_, value, "宽", 40) }]}>
                                        <InputNumber addonAfter="cm" onChange={(value) => this.setState({ inWidth: this.handleVolumeChange(value) })} style={{ width: '100%' }} placeholder="宽度" />
                                    </Form.Item>
                                    <Form.Item style={{ display: 'inline-block' }}>
                                        <InputNumber addonAfter="in" value={this.state.inWidth} style={{ width: '100px', marginLeft: 10 }} readOnly />
                                    </Form.Item>
                                </div>
                            </Form.Item>
                            <Form.Item label="高度" style={{ marginBottom: 0 }}>
                                <div>
                                    <Form.Item name="height" style={{ display: 'inline-block', width: 'calc(100% - 110px)' }} rules={[{ validator: (_, value) => this.volumeNumber(_, value, "高", 35) }]}>
                                        <InputNumber addonAfter="cm" onChange={(value) => this.setState({ inHeight: this.handleVolumeChange(value) })} style={{ width: '100%' }} placeholder="高度" />
                                    </Form.Item>
                                    <Form.Item style={{ display: 'inline-block' }}>
                                        <InputNumber addonAfter="in" value={this.state.inHeight} style={{ width: '100px', marginLeft: 10 }} readOnly />
                                    </Form.Item>
                                </div>
                            </Form.Item>
                            <Form.Item name="cost" label="成本" rules={[{ required: true, message: "请输入成本" }, { validator: (_, value) => commonUtil.greaterThanZero(_, value, "成本") }]}>
                                <InputNumber addonAfter="元" onChange={(value) => this.handleCalculate()} style={{ width: '100%' }} placeholder="成本" />
                            </Form.Item>
                            <Form.Item name="profit" label="利润" rules={[{ required: true, message: "请输入利润" }, { validator: (_, value) => this.profitLimit(_, value, "利润") }]}>
                                <InputNumber addonAfter="%" onChange={(value) => this.handleCalculate()} style={{ width: '100%' }} placeholder="利润" />
                            </Form.Item>
                        </Form>
                        {this.state.showResult && <TableContainer className="price-table">
                            <table>
                                <colgroup>
                                    <col width="23" />
                                    <col width="23" />
                                    <col width="23" />
                                    <col width="31" />
                                </colgroup>
                                <thead className="ant-table-thead">
                                    <tr>
                                        <th>成本（元）</th>
                                        <th>运费（元）</th>
                                        <th>利润（元）</th>
                                        <th>合计（元）</th>
                                    </tr>
                                </thead>
                                <tbody className="ant-table-tbody">
                                    <tr>
                                        <td>{this.state.cost}</td>
                                        <td>{this.state.freight}</td>
                                        <td>{this.state.profit}</td>
                                        <td>{this.state.total} (${this.state.totalDollar})</td>
                                    </tr>
                                </tbody>
                            </table>
                        </TableContainer>
                        }
                    </Card>
                </Col>
            </Row>
        </PageContainer >);
    }
}

export default withBreakpoint(SystemTool);