import { LogoutOutlined, SettingOutlined, ClusterOutlined, TeamOutlined } from '@ant-design/icons';
import { Avatar, Menu, Spin, Dropdown } from 'antd';
import React from 'react';
import defaultAvatar from '../../assets/images/avatar_default.png';
import IconFont from '../Common/IconFont';
import { withRouter } from 'react-router-dom';
import { getAccountInfo, isAdmin, cleanAccount } from '../../utils/authority';
import './index.less';

class AvatarDropdown extends React.Component {

    onMenuClick = (event) => {
        const { key } = event;

        if (key === 'logout') {
            cleanAccount();
            this.props.history.push('/auth/login');
            return;
        }
        this.props.history.push(key);
    };

    render() {
        const account = getAccountInfo();
        const {
            currentUser = {
                avatar: account?.wxAvatar ? account?.wxAvatar : defaultAvatar,
                name: account?.displayName,
            },
            menu,
        } = this.props;
        const menuHeaderDropdown = (
            <Menu className='menu' selectedKeys={[]} onClick={this.onMenuClick}>
                {/* {menu && (
                    <Menu.Item key="center">
                        <UserOutlined />
                        个人中心
                    </Menu.Item>
                )} */}
                {menu && isAdmin() && (
                    <>
                        <Menu.Item key="/settings/system">
                            <SettingOutlined />
                            系统设置
                        </Menu.Item>
                        <Menu.Item key="/settings/site">
                            <ClusterOutlined />
                            店铺设置
                        </Menu.Item>
                        <Menu.Item key="/settings/logistics">
                            <IconFont style={{ fontSize: '16px' }} type="icon-logistics-settings" />
                            物流设置
                        </Menu.Item>
                        <Menu.Item key="/settings/timer">
                            <IconFont type="icon-time-range" />
                            Timer设置
                        </Menu.Item>
                        <Menu.Item key="/settings/user">
                            <TeamOutlined />
                            用户管理
                        </Menu.Item>
                        <Menu.Divider />
                    </>
                )}

                <Menu.Item key="logout">
                    <LogoutOutlined />
                    退出登录
                </Menu.Item>
            </Menu>
        );
        return currentUser && currentUser.name ? (
            <Dropdown overlayClassName="header-avatar-dropdown" overlay={menuHeaderDropdown}>
                <span className='action account'>
                    <Avatar size="small" className='avatar' src={currentUser.avatar} alt="avatar" />
                    <span className='name anticon'>{currentUser.name}</span>
                </span>
            </Dropdown>
        ) : (
            <span className='action account'>
                <Spin
                    size="small"
                    style={{
                        marginLeft: 8,
                        marginRight: 8,
                    }}
                />
            </span>
        );
    }
}

export default withRouter(AvatarDropdown);
