import React from "react";
import { Result } from 'antd';
import { Redirect } from "react-router-dom";
import isLogedIn, { getAccountInfo } from "../../utils/authority";
import routerConfig from "../../layouts/routerConfig";

const checkAuthorized = (route) => {
    if (!route.authority) {
        return true;
    }
    const accountInfo = getAccountInfo();
    const role = accountInfo?.roles;
    if (role) {
        const roleArray = role.split(',');
        return roleArray.some((item) => route.authority.includes(item))
    }
    return false;
}

const Authorized = (props) => {
    const {
        path,
        location,
        noMatch = (
            <Result
                status="403"
                title="403"
                subTitle="对不起，您没有权限访问此页！"
            />
        ) } = props;

    const isAuthorized = () => {
        const matchRoute = getMatchRoute([routerConfig.route]);
        return !matchRoute || checkAuthorized(matchRoute);
    }

    const getMatchRoute = (routes) => {
        for (let i = 0; i < routes.length; i++) {
            const route = routes[i];
            if (path === route.path) {
                return route;
            }
            if (route.routes) {
                const matchRoute = getMatchRoute(route.routes);
                if (matchRoute) {
                    return matchRoute;
                }
            }
        }
    }
    return !isLogedIn() ? <Redirect to={`/auth/login?returnUrl=${encodeURIComponent(location.pathname + location.search)}`} /> : !isAuthorized() ? noMatch : props.children;
}

export default Authorized;

export {
    checkAuthorized
};