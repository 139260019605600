import React, { Component } from 'react';
import { Badge, Popover, Tabs } from 'antd';
import classNames from 'classnames';
import { BellOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import noficationIcon from '../../assets/images/notification.svg';
import './index.less';

const { TabPane } = Tabs;

class NoticeIconView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        }
    }
    getOverlay = () => {
        return <Tabs className='tabs'>
            <TabPane tab="通知" key='notification'>
                <div className="notice-list-container">
                    <div className='notFound'>
                        <img
                            src={noficationIcon}
                            alt="not found"
                        />
                        <div>你已查看所有通知</div>
                    </div>
                </div>
            </TabPane>
            <TabPane tab="消息" key='message'>
                <div className="notice-list-container">
                    <div className='notFound'>
                        <img
                            src={noficationIcon}
                            alt="not found"
                        />
                        <div>您已读完所有消息</div>
                    </div>
                </div>
            </TabPane>
            <TabPane tab="待办" key='event'>
                <div className="notice-list-container">
                    <div className='notFound'>
                        <img
                            src={noficationIcon}
                            alt="not found"
                        />
                        <div>你已完成所有待办</div>
                    </div>
                </div>
            </TabPane>
        </Tabs>
    }
    render() {
        const overlay = this.getOverlay();
        return (
            <Popover
                visible={this.state.visible}
                onVisibleChange={(visible) => { this.setState({ visible: visible }) }}
                content={overlay}
                placement='bottomRight'
                trigger={['click']}
                overlayClassName='header-popover-container'>
                <span className={classNames('header-notice-icon action', { opened: this.state.visible })}>
                    <Badge count={0} style={{ boxShadow: 'none' }} className='badge'>
                        <BellOutlined className='icon' />
                    </Badge>
                </span>
            </Popover>
        );
    }
}

export default withRouter(NoticeIconView);
