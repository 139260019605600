import React from "react";
import request from "@utils/request";
import { Modal, Spin, Alert, Input, Button, Form, message, Rate, Tooltip, Upload } from 'antd';
import { FormOutlined, InboxOutlined } from '@ant-design/icons';
import commonConfig from '@common/commonConfig';
import './EditReview.less';

const { TextArea } = Input;
const { Dragger } = Upload;

class EditReview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            errorMsg: null,
            fileList: null
        }
    }

    form = React.createRef();

    handleOk = () => {
        this.form.current.validateFields().then(values => {
            const { record } = this.props;
            const newImages = this.state.fileList.filter(f => f.status === 'done');
            const _this = this;
            this.setState({
                loading: true,
                hasError: false,
            });
            const controller = new AbortController();
            const { signal } = controller;
            this.controller = controller;
            const newRecord = {
                ...record,
                thumbs: newImages.map(f => f.thumbUrl),
                images: newImages.map(f => f.url),
                ...values,
                first_name: values.name,
                last_name: null
            };
            request.post('/Review/UpdateReview', {
                signal,
                data: newRecord
            }).then(function (response) {
                _this.setState({ loading: false });
                if (response && response.success) {
                    _this.setState({ visible: false });
                    message.success("编辑评价成功！");
                    if (_this.props.afterUpdate) {
                        _this.props.afterUpdate(newRecord);
                    }
                }
                if (response && response.success === false) {
                    _this.setState({ hasError: true });
                }
            })
        }).catch(errorInfo => { });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleOpen = () => {
        const { record } = this.props;
        if (this.controller) {
            this.controller.abort();
        }
        const fileList = this.generateFileFromRecord(record);
        this.setState({
            visible: true, errorMsg: null,
            fileList: fileList
        }, () => {
            this.form.current.resetFields();
        });
    }

    generateFileFromRecord = (record) => {
        const { thumbs, images } = record;
        return thumbs?.length > 0 ? thumbs?.map((d, idx) => {
            return {
                name: 'Review Image',
                status: 'done',
                thumbUrl: d,
                url: images[idx]
            }
        }) : [];
    }

    handleChange = (info) => {
        const { file, fileList } = info;
        const newFileList = [...fileList];
        const newFile = newFileList.find(f => f.uid === file.uid);
        switch (file.status) {
            case 'uploading':
                newFile.percent = 30;
                break;
            case 'done':
                newFile.thumbUrl = file.response.result.thumbnailPath;
                newFile.url = file.response.result.path;
                newFile.originFileObj = null;
                newFile.optionName = "Review Image";
                break;
            case 'error':
                newFile.originFileObj = null;
                break;
            case 'removed':
                break;
            default:
                const isLt4M = file.size <= commonConfig.allowUploadSize;
                newFile.status = 'error';
                newFile.originFileObj = null;
                newFile.error = { message: isLt4M ? '文件类型不支持' : '文件大小超过限制' };
                break;
        }
        this.setState({
            fileList: newFileList
        });
    }

    customRequest = (options) => {
        const { onSuccess, onError, file } = options;
        const formData = new FormData();
        formData.append('files', file);
        formData.append('reviewId', this.props.record.id);
        request('/Review/UploadImage', { method: 'post', data: formData, requestType: 'form', }).then(function (response) {
            if (response && response.success) {
                onSuccess(response, file);
            }
            if (response && response.success === false) {
                onError({ message: response.errorMsg }, response);
            }
        });
    }

    beforeUpload = (file) => {
        const isImage = commonConfig.allowImageType.some(t => t.indexOf(file.type.split('/')[1]) > -1);
        const isLt4M = file.size <= commonConfig.allowUploadSize;
        return isImage && isLt4M;
    }

    render() {
        const { record } = this.props;
        return (
            <>
                <Tooltip title="编辑评价">
                    <Button type="primary" shape="circle" onClick={this.handleOpen} icon={<FormOutlined />} />
                </Tooltip>
                <Modal title="编辑评价"
                    okButtonProps={{ loading: this.state.loading }}
                    wrapClassName="edit-review-modal"
                    maskClosable={false}
                    width={800}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Spin spinning={this.state.loading}>
                        {!!this.state.errorMsg && <Alert message={this.state.errorMsg} style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                        <Form ref={this.form} labelAlign="left" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} initialValues={{
                            rate: record?.rate,
                            name: record.last_name ? `${record.first_name} ${record.last_name}` : record.first_name,
                            email: record?.email,
                            comment: record?.comment
                        }}>
                            <Form.Item label="评分" name="rate" rules={[{ required: true }]}>
                                <Rate allowClear={false} />
                            </Form.Item>
                            <Form.Item label="姓名" name="name" rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="邮箱" name="email" rules={[{ type: "email" }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="图片" >
                                <Dragger
                                    name="file"
                                    multiple={true}
                                    listType="picture-card"
                                    showUploadList={{
                                        showPreviewIcon: false,
                                        showRemoveIcon: true,
                                        showDownloadIcon: true
                                    }}
                                    accept={commonConfig.allowImageType.join(',')}
                                    fileList={this.state.fileList}
                                    onChange={this.handleChange}
                                    customRequest={this.customRequest}
                                    beforeUpload={this.beforeUpload}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">点击或拖动文件到此区域上传</p>
                                    <p className="ant-upload-hint">
                                        支持单个或批量上传，单个图片上传大小限制为4M。
                                    </p>
                                </Dragger>
                            </Form.Item>
                            <Form.Item label="评价" name="comment" rules={[{ required: true }]}>
                                <TextArea rows={4} />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        );
    }
};

export default EditReview;