import React from "react";
import request from "@utils/request";
import { Modal, Spin, Alert, Select, Tooltip, Button, Form } from 'antd';
import IconFont from '@components/Common/IconFont';
import { isAdmin } from "@utils/authority";

const { Option } = Select;

class EditOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            hasError: false
        }
    }

    form = React.createRef();

    handleOk = () => {
        const controller = new AbortController();
        const { signal } = controller;
        this.controller = controller;
        this.setState({ loading: true, hasError: false });
        const _this = this;
        const values = this.form.current.getFieldsValue();
        const newRecord = {
            ...this.props.record,
            orderStatus: values.orderStatus.value,
            orderStatusString: values.orderStatus.label
        };
        request.post('/Order/EditOrder', {
            signal,
            data: newRecord
        }).then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                _this.setState({ visible: false });
                if (_this.props.afterUpdate) {
                    _this.props.afterUpdate(newRecord);
                }
            }
            if (response && response.success === false) {
                _this.setState({ hasError: true });
            }
        });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    handleOpen = () => {
        if (this.controller) {
            this.controller.abort();
        }
        this.setState({ visible: true, hasError: false }, () => {
            this.form.current.resetFields();
        });
    }

    render() {
        const { record, orderStatuses } = this.props;
        return (isAdmin() && !!record) ? (
            <>
                <Tooltip title="编辑订单">
                    <Button type="primary" onClick={this.handleOpen} shape="round" icon={<IconFont type="icon-edit-info" />} >编辑订单</Button>
                </Tooltip>
                <Modal title="编辑订单"
                    okButtonProps={{ loading: this.state.loading }}
                    maskClosable={false}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Spin spinning={this.state.loading}>
                        {this.state.hasError && <Alert message="编辑订单失败！" style={{ marginBottom: '12px' }} type="error" showIcon closable />}
                        <Form ref={this.form}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 20 }}
                            initialValues={{
                                orderStatus: {
                                    label: record.orderStatusString,
                                    value: record.orderStatus
                                }
                            }}>
                            <Form.Item label="客户单号">
                                <div>{record.orderNumber}</div>
                            </Form.Item>
                            <Form.Item name="orderStatus" label="订单状态" rules={[{ required: true }]}>
                                <Select labelInValue={true}>
                                    {
                                        orderStatuses?.map(status =>
                                            <Option key={status.value} value={status.value}>{status.displayText}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Modal>
            </>
        ) : '';
    }
};

export default EditOrder;