import React from "react";
import request from "@utils/request";
import PageLoading from '@components/Common/PageLoading/PageLoading';
import { Modal, Button, message } from 'antd';
import IconFont from '@components/Common/IconFont';

class SignInStockExpress extends React.Component {

    handleOpen = () => {
        Modal.confirm({
            title: '请确认',
            content: '确定此快递已经签收？',
            onOk: this.handleOk
        });
    }

    handleOk = () => {
        const { record } = this.props;
        const _this = this;
        PageLoading.show();
        request.post('/Stock/SignExpress', {
            data: { id: record.id }
        }).then(function (response) {
            PageLoading.hide();
            _this.setState({ loading: false });
            if (response && response.success) {
                _this.setState({ visible: false });
                message.success(`${record.stockName}快递签收成功！`);
                if (_this.props.afterUpdate) {
                    _this.props.afterUpdate({ ...record });
                }
            }
            if (response && response.success === false) {
                message.error(`${record.stockName}快递签收失败！`);
            }
        });
    };

    render() {
        return (
            <>
                <Button type="primary" onClick={this.handleOpen} shape="round" icon={<IconFont type="icon-delivery" />} >签收</Button>
            </>
        );
    }
};

export default SignInStockExpress;