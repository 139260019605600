import React from "react";
import { PageContainer } from "@ant-design/pro-layout";
import PageLoading from '@components/Common/PageLoading/PageLoading';
import commonConfig from '@common/commonConfig';
import request from "@utils/request";
import { DownOutlined } from '@ant-design/icons';
import { Card, Table, Input, Tooltip, Row, Col, Form, Select, Popover, Button, InputNumber, Space } from "antd";
import AdjustStock from "@components/Stock/AdjustStock/AdjustStock";
import BuyInStock from "@components/Stock/BuyInStock/BuyInStock";
import CreateStock from "@components/Stock/CreateStock/CreateStock";
import EditStock from "@components/Stock/EditStock/EditStock";
import StockDetailButton from "@components/Stock/StockDetailButton/StockDetailButton";
import './stock-manage.less';

const { Search } = Input;
const { Option } = Select;
const Operators = ["=", ">", "<"];

const topSiteSelectProps = {
    xs: 24,
    sm: 24,
    md: 14,
    lg: 14,
    xl: 12,
};

const topSearchSelectProps = {
    xs: 24,
    sm: { span: 12, push: 12 },
    md: { span: 8, push: 2 },
    lg: { span: 8, push: 2 },
    xl: { span: 8, push: 4 }
};

class StockManage extends React.Component {
    state = {
        loading: false,
        visible: false,
        pageNumber: 1,
        pageSize: commonConfig.pageSize,
        dataSource: null,
        totalCount: 0
    }
    search = React.createRef();
    form = React.createRef();
    filterForm = React.createRef();

    columns = [
        {
            title: '库存产品',
            key: 'stockName',
            dataIndex: 'stockName',
            width: 16
        },
        {
            title: '进货库存',
            key: 'stockCount',
            dataIndex: 'stockCount',
            width: 8
        },
        {
            title: '出货库存',
            key: 'shipmentCount',
            dataIndex: 'shipmentCount',
            width: 8
        },
        {
            title: '类型',
            key: 'stockTypeString',
            dataIndex: 'stockTypeString',
            width: 8
        },
        {
            title: '描述',
            key: 'description',
            dataIndex: 'description',
            width: 24
        },
        {
            title: '创建时间',
            key: 'createTimeString',
            dataIndex: 'createTimeString',
            width: 16
        },
        {
            title: '操作',
            key: 'action',
            render: (record) => (
                <Space wrap>
                    <EditStock record={record} stockTypes={this.state.stockTypes} afterUpdate={(newRecord) => {
                        const newSource = [...this.state.dataSource];
                        const idx = newSource.findIndex(x => x.id === record.id);
                        newSource[idx] = {
                            ...record,
                            stockName: newRecord.stockName,
                            description: newRecord.description,
                            stockType: newRecord.stockType,
                            stockTypeString: newRecord.stockTypeString
                        };
                        this.setState({ dataSource: newSource });
                    }} />
                    <BuyInStock record={record} afterUpdate={(newRecord) => {
                        const newSource = [...this.state.dataSource];
                        const idx = newSource.findIndex(x => x.id === record.id);
                        newSource[idx] = {
                            ...record,
                            stockCount: newRecord.stockCount
                        };
                        this.setState({ dataSource: newSource });
                    }} />
                    <AdjustStock record={record} afterUpdate={(newRecord) => {
                        const newSource = [...this.state.dataSource];
                        const idx = newSource.findIndex(x => x.id === record.id);
                        newSource[idx] = {
                            ...record,
                            stockCount: newRecord.stockCount,
                            shipmentCount: newRecord.shipmentCount
                        };
                        this.setState({ dataSource: newSource });
                    }} />
                    <StockDetailButton record={record} />
                </Space>
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord !== nextRecord
                || prevRecord.stockName !== nextRecord.stockName
                || prevRecord.description !== nextRecord.description
                || prevRecord.stockCount !== nextRecord.stockCount
                || prevRecord.shipmentCount !== nextRecord.shipmentCount
                || prevRecord.stockTypeString !== nextRecord.stockTypeString,
            width: 20
        },
    ];

    componentDidMount() {
        const _this = this;
        this.setState({ loading: true });
        request.get('/Stock/GetStockSelectData').then(function (response) {
            _this.setState({ loading: false });
            if (response && response.success) {
                _this.setState({
                    stockTypes: response.result.stockTypes,
                    allStockTypes: response.result.allStockTypes,
                    operatorTypes: response.result.operatorTypes
                }, () => {
                    _this.form.current.setFieldsValue({
                        stockType: response.result.allStockTypes?.length > 0 ? response.result.allStockTypes[0].value : null
                    });
                    _this.initFilter();
                    _this.loadData();
                });
            }
        });
    }

    initFilter = () => {
        const { operatorTypes } = this.state;
        this.setState({
            filter: {
                stockOperator: operatorTypes?.length > 0 ? operatorTypes[0].value : null,
                shipmentOperator: operatorTypes?.length > 0 ? operatorTypes[0].value : null,
                stockCount: 0,
                shipmentCount: 0,
                useOperatorFilter: false
            }
        });
    }

    handlePageChange = (page, pageSize) => {
        this.setState({
            pageNumber: page,
            pageSize: pageSize
        }, this.loadData);
    }

    loadData = () => {
        const _this = this;
        PageLoading.show();
        const formData = this.form.current.getFieldsValue();
        request.get('/Stock/GetPagedStocks', {
            params: {
                pageNumber: this.state.pageNumber,
                pageSize: this.state.pageSize,
                stockType: formData.stockType,
                name: this.search.current.input.value,
                ...this.state.filter
            }
        }).then(function (response) {
            PageLoading.hide();
            if (response && response.success) {
                _this.setState({
                    dataSource: response.result.data,
                    totalCount: response.result.totalRecord
                });
            }
        });
    }

    onSearch = (value) => {
        this.setState({ pageNumber: 1 }, () => { this.loadData(); });
    }

    handleValuesChange = (values) => {
        this.setState({ pageNumber: 1 }, () => { this.loadData(); });
    }

    handleFilterOk = () => {
        this.filterForm.current.validateFields().then(values => {
            this.handleVisibleChange(false);
            this.setState({
                pageNumber: 1,
                filter: { ...values, useOperatorFilter: true }
            }, () => { this.loadData(); });
        }).catch(errorInfo => { });
    }

    handleFilterRemove = () => {
        this.filterForm.current.resetFields();
        this.initFilter();
        this.handleVisibleChange(false);
        this.setState({ pageNumber: 1 }, () => { this.loadData(); });
    }

    handleFilterCancel = () => {
        this.handleVisibleChange(false);
    }

    handleVisibleChange = visible => {
        this.setState({ visible }, () => {
            if (visible === true) {
                this.filterForm.current.setFieldsValue(this.state.filter);
            }
        });
    }

    render() {
        return (<PageContainer className="stock-manage-page">
            <Card loading={this.state.loading}>
                <CreateStock stockTypes={this.state.stockTypes} afterUpdate={(newRecord) => this.loadData()} />
                <Form
                    style={{ marginTop: '12px' }}
                    ref={this.form}
                    onValuesChange={this.handleValuesChange}
                >
                    <Row className="top-select-row" gutter={24}>
                        <Col {...topSiteSelectProps}>
                            <Form.Item label="库存类型" style={{ marginBottom: 0 }}>
                                <Form.Item name="stockType" style={{ display: 'inline-block', width: 'calc(50% - 4px)' }}>
                                    <Select>
                                        {this.state.allStockTypes?.map(stockType => {
                                            return <Option key={stockType.value} value={stockType.value}>{stockType.text}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 4px)', margin: '0 0 0 8px' }}>
                                    <Popover placement="bottomLeft" trigger="click" visible={this.state.visible} onVisibleChange={this.handleVisibleChange} content={
                                        <div className="clearfix">
                                            <Form ref={this.filterForm}>
                                                <Form.Item label="进货库存" style={{ marginBottom: 0 }}>
                                                    <Form.Item name="stockOperator" style={{ display: 'inline-block', width: '60px' }} rules={[{ required: true, message: "请选择条件" }]}>
                                                        <Select>
                                                            {this.state.operatorTypes?.map(tp => <Option key={tp.value} value={tp.value}>{tp.text}</Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="stockCount" style={{ display: 'inline-block', marginLeft: '8px' }} rules={[{ required: true, message: "请输入库存" }]}>
                                                        <InputNumber style={{ width: 90 }} />
                                                    </Form.Item>
                                                </Form.Item>
                                                <Form.Item label="出货库存" style={{ marginBottom: 0 }}>
                                                    <Form.Item name="shipmentOperator" style={{ display: 'inline-block', width: '60px' }} rules={[{ required: true, message: "请选择条件" }]}>
                                                        <Select>
                                                            {this.state.operatorTypes?.map(tp => <Option key={tp.value} value={tp.value}>{tp.text}</Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name="shipmentCount" style={{ display: 'inline-block', marginLeft: '8px' }} rules={[{ required: true, message: "请输入库存" }]}>
                                                        <InputNumber style={{ width: 90 }} />
                                                    </Form.Item>
                                                </Form.Item>
                                            </Form>
                                            <Space style={{ float: "right" }}>
                                                <Button type="default" onClick={this.handleFilterCancel}>取消</Button>
                                                <Button type="primary" danger onClick={this.handleFilterRemove}>清空</Button>
                                                <Button type="primary" onClick={this.handleFilterOk}>确定</Button>
                                            </Space>
                                        </div>
                                    }>
                                        <Button style={{ width: '100%', textAlign: "left" }}>
                                            {this.state.filter?.useOperatorFilter ? `进货${Operators[this.state.filter.stockOperator]}${this.state.filter.stockCount}，出货${Operators[this.state.filter.shipmentOperator]}${this.state.filter.shipmentCount}` : "库存过滤"}<DownOutlined className="filter-arrow" />
                                        </Button>
                                    </Popover>
                                </Form.Item>
                            </Form.Item>
                        </Col>
                        <Col {...topSearchSelectProps}>
                            <Form.Item>
                                <Tooltip title="搜索商品名称、ProductId">
                                    <Search ref={this.search} placeholder="搜索..." allowClear onSearch={this.onSearch} />
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Table rowKey='id'
                    columns={this.columns}
                    dataSource={this.state.dataSource}
                    tableLayout='fixed'
                    size="middle"
                    scroll={{ x: 950 }}
                    pagination={{
                        pageSize: this.state.pageSize,
                        current: this.state.pageNumber,
                        total: this.state.totalCount,
                        showQuickJumper: true,
                        size: 'default',
                        onChange: this.handlePageChange
                    }} />
            </Card>
        </PageContainer>);
    }
}

export default StockManage;